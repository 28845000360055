import React, { useState } from "react";
import FAQItem from "../components/FAQItem";
import ContactUsForm from "../components/Contact";
import {BsFillTelephonePlusFill} from 'react-icons/bs'

const FAQPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            id: 1,
            question: "What is Harvest Alert?",
            answer: "Harvest Alert is a web-based platform that connects oil palm farmers with mills and aggregators to facilitate the scheduling of harvests to ensure timely evacuation of fresh fruit bunches (FFB).",
        },
        {
            id:2,
            question: "How does Harvest Alert work?",
            answer: "Harvest Alert works by enabling mills and aggregators to send harvest requests to farmers through the platform. Farmers can then respond to these requests, schedule pick-up appointments, and receive evacuation alerts through a USSD technology."
        },

        {
            id:3,
            question:"How does Harvest Alert benefit farmers?",
            answer:" Harvest Alert helps farmers to increase their income by ensuring timely evacuation of FFB and reducing weight loss"
            

        },

        {
            id:4,
            question:"How does Harvest Alert benefit mills and aggregators?",
            answer: "Harvest Alert helps mills and aggregators to manage the supply chain more efficiently, reducing transportation costs and improving traceability",

        },
        {
            id:5,
            question:"What technology do I need to use Harvest Alert?",
            answer: "To use Harvest Alert, Mill and Aggregators need a device with internet connectivity while Farmers need a USSD-enabled feature phone phone to send and respond to alerts"

        },
        {
            id:6,
            question:"How do Mills & Aggregator's sign up for Harvest Alert?",
            answer:" To sign up for Harvest Alert, you need to visit the platform's website(harvestalert.solidaridadswestafrica.org) and create an account."



        },
        {
            id:7,
            question:"How do Farmer's sign up for Harvest Alert?",
            answer:"Farmers can sign up for Harvest Alert by contacting their local mill or aggregator and requesting access to the platform"
        },
        {
            id:8,
            question:"Is my data secure on Harvest Alert?",
            answer:"Yes, Harvest Alert uses industry-standard security protocols to protect user data and ensure privacy."

        },
        {
            id:9,
            question:"Can I use Harvest Alert if I am not an oil palm farmer?",
            answer:" No, At the moment Harvest Alert is specifically designed for oil palm farmers and mills/aggregators in the oil palm industry."

        },
        {
            id:10,
            question:"Can I access Harvest Alert from my mobile device?",
            answer:" Yes, Harvest Alert is accessible on both desktop and mobile devices."

        },
        {
            id:11,
            question:"How do I report a problem with Harvest Alert?",
            answer:"User can report problems with Harvest Alert by sending an email to the Harvest Alert support team using the form below."
        }


    


    ];

    return (
        <div className="flex   h-full flex-col mt-4 items-center">
            <h1 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions</h1>
            {faqs.map((faq, index) => (
                <FAQItem
                    title={faq.question}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                >
                    {faq.answer}
                </FAQItem>
            ))}


            <div className="mt-8 lg:w-[70%] md:w-[70%] items-center w-[90%]">
            <h1 className="text-3xl font-bold mb-8 text-center">Contact Us</h1>
            <ContactUsForm />
            </div>

            <div className="mt-20">
                <p className="text-3xl font-bold flex justify-center items-center"><BsFillTelephonePlusFill size={20} className='mr-2'/> Customer support lines </p>
                <div className="flex space-x-1 mt-2 justify-center items-center ml-4">
                    <p className="text-sm text-gray-500">030-00001000</p>  /
                    <p className="text-sm text-gray-500">030-00001000</p>

                </div>
            </div>
        </div>
    );
};

export default FAQPage;
