import React from "react";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import NotificationModal from "../components/NotificationModal";
import { formatDateFull } from "../utils/Formatter";

const Notification = ({
  showNotification,
  setShowNotification,
  messages,
  deleteSingleNotification,
  deleteallNotifications,
  updateNotification,
}) => {
  const toggleDrawer = () => {
    setShowNotification(!showNotification);
  };
  const [showModal, setModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState();
  console.log(messages?.length)
  return (
    <>
      <NotificationModal
        showModal={showModal}
        setModal={setModal}
        selectedMessage={selectedMessage}
        deleteSingleNotification={deleteSingleNotification}
      />
      <Drawer open={showNotification} onClose={toggleDrawer} direction="right">
        <div className="px-3">
          <div className="flex items-end justify-end pt-4">
            <button onClick={() => setShowNotification(!showNotification)}>
              <AiOutlineCloseCircle size={35} />
            </button>
          </div>
          <div className="">
            {messages?.length > 0 && (
              <div className="flex items-end justify-end py-2">
                <button
                  onClick={() => deleteallNotifications()}
                  className="outline-none text-sm"
                >
                  <span>Clear notifications</span>
                </button>
              </div>
            )}

            {messages?.length > 0 ? <>
              {messages?.map((message) => (
                <div className="mt-3">
                  <div
                    onClick={() => {
                      updateNotification(message.notificationID);
                      setSelectedMessage(message);
                      setModal(true);
                    }}
                    className="w-full border-2 border-gray-100 rounded p-3 mb-2 hover:cursor-pointer hover:shadow hover:transition hover:delay-150 hover:duration-300 hover:ease-in-out"
                  >
                    <h3 className="text-sm font-bold">{message.title}</h3>
                    <div className="my-1">
                      <p className="text-gray-600 text-xs">
                        {message.message.substring(0, 100)}
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-xs">
                        {formatDateFull(message.datecreated)}
                      </p>
                      <div className="bg-green-600 rounded text-white px-2 py-1">
                        <p className="text-xs">
                          {message.read === true ? "Read" : "Unread"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}</> : <div className="flex h-screen justify-center items-center">
              <p className="text-lg">No messages yet</p>

            </div>}


          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Notification;
