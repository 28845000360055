import React, { useState, useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaWarehouse } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import MapComponent from "../../components/MapComponent";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function FarmDetail(props) {
  const history = useHistory();
  const request = props.location.state;

  // const location = {
  //   address: farmerDetail.state.farmer.community,
  //   lat: parseFloat(farmerDetail.state.farmer.latitude),
  //   lng: parseFloat(farmerDetail.state.farmer.longitude),
  // };

  const location = {
    address: request?.state?.miller?.district,
    lat: parseFloat(request?.state?.miller?.latitude),
    lng: parseFloat(request?.state?.miller?.longitude),
  };

  


  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>
        <div className="bg-white capitalize px-4 py-2 mt-5 sm:rounded-sm border-2 border-gray-300 shadow-md">
          <div className="py-2 border-b border-gray-100s">
            <h2 className="text-base font-bold capitalize">mill details</h2>
          </div>
          <div className="py-4 mx-0 lg:mx-10">
            <div className="my-2 grid lg:grid-cols-2 sm:grid-cols-2">
              <div>
                <div className="flex my-2">
                  <FaWarehouse size={20} />
                  <div className="ml-2">
                    <p className="text-base font-bold">Mill</p>
                    <div className="mt-2">
                      <p className="text-sm">{request.state.miller.name}</p>
                      <p className="text-sm">{request.state.miller.email}</p>
                      <p className="text-sm">{request.state.miller.phone}</p>
                    </div>
                  </div>
                </div>

                <div className="flex  mt-4">
                  <MdLocationOn size={20} />
                  <div className="ml-2">
                    <p className="text-base font-bold">Location</p>
                    <div className="mt-2">
                      <p className="text-sm">{request.state.miller.district}</p>
                      <p className="text-sm">{request.state.miller.community}</p>
                      <p className="text-sm">{request.state.miller.region}</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="mt-10 lg:mt-0 sm:mt-0">
                <p className="text-base font-bold mb-2">Map location</p>
                <MapComponent location={location} zoomLevel={13} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
