import React, { Component, useState, useEffect } from "react";
import {} from "chart.js/auto";

import { Pie, Doughnut } from "react-chartjs-2";

const PieChart = ({ data }) => {
  const config = {
    chartData: {
      labels: [
        "Incoming Harvest",
        "Outgoing Harvest",
        "Prospective harvest weight loss",
      ],
      datasets: [
        {
          label: "",
          data: [
            data?.incoming_harvest,
            data?.outgoing_harvest,
            data?.prospective_harvest_weight_loss,
          ],
          backgroundColor: ["blue", "yellow", "black"],
        },
      ],
    },
  };

  return (
    <div className="chart">
      {data && (
        <div style={{ width: "50%", height: "50%", margin: "auto" }}>
          <Pie
            data={config.chartData}
            options={{
              title: {
                display: false,
                text: "Chart.js Bar Chart",
              },
              legend: {
                display: true,
                position: "right",
              },
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                xAxes: [
                  {
                    barThickness: 2,
                  },
                ],
              },
              plugins: {
                legend: {
                  display: false,
                  position: "left",
                  rtl: true,
                  labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    padding: 20,
                    align: "center",
                  },
                  align: "center",
                  fullWidth: true,
                  reverse: true,
                },
              },
              // center chart
              layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PieChart;
