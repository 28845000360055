import React, { useState, useEffect, useMemo } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { GiBarn, GiTreeFace } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import axios from "axios";
import MapComponent from "../../components/MapComponent";

export default function AggregatorFarmerDetail(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const farmerDetail = props.location.state;

 

  const location = {
    address: farmerDetail.state.farmer.community,
    lat: parseFloat(farmerDetail.state.farmer.latitude),
    lng: parseFloat(farmerDetail.state.farmer.longitude),
  };

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="bg-white capitalize px-4 py-2 mt-5 sm:rounded-sm border-2 border-gray-300 shadow-md">
            <div className="py-2 border-b border-gray-100s">
              <h2 className="text-base font-bold capitalize">farmer details</h2>
            </div>
            <div className="py-4 mx-0 lg:mx-10">
              <div className="my-2 grid lg:grid-cols-2 sm:grid-cols-2">
              <div className="flex gap-4 lg:flex-col md:flex-col">
                  <div className="flex my-2">
                    <GiBarn size={20} />

                    <div className="ml-2">
                      <p className="text-base font-bold">Farmer</p>
                      <div className="mt-2">
                        <p className="text-sm">
                          {" "}
                          {`${farmerDetail?.state?.farmer.firstname} ${farmerDetail?.state?.farmer.lastname}`}
                        </p>
                        <p className="text-sm">
                          {farmerDetail?.state?.farmer?.email}
                        </p>
                        <p className="text-sm">
                          {farmerDetail?.state?.farmer?.phone}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-2  lg:mt-4  md:mt-4">
                    <MdLocationOn size={20} />
                    <div className="ml-2">
                      <p className="text-base font-bold">Location</p>
                      <div className="mt-2">
                        <p className="text-sm">
                          {farmerDetail?.state?.farmer.district}
                        </p>
                        <p className="text-sm">
                          {farmerDetail?.state?.farmer.community}
                        </p>
                        <p className="text-sm">
                          {farmerDetail?.state?.farmer.region}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 lg:mt-0 sm:mt-0">
                  <p className="text-base font-bold mb-2">Map location</p>

                  <MapComponent location={location} zoomLevel={13} />
                </div>
              </div>
            </div>

            <div className="my-10">
              <div class="overflow-x-auto relative">
                <table class="w-full text-sm text-left ">
                  <thead class="text-xs text-gray-700 capitalize bg-slate-100">
                    <tr>
                      <th scope="col" class="py-3 px-6">
                        Farm name
                      </th>

                      <th scope="col" class="py-3 px-6">
                        commodity
                      </th>

                      <th scope="col" class="py-3 px-6">
                        region
                      </th>
                      <th scope="col" class="py-3 px-6">
                        land size(acres)
                      </th>
                      <th scope="col" class="py-3 px-6">
                        community
                      </th>
                      <th scope="col" class="py-3 px-6">
                        district
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {farmerDetail?.state?.farms?.map((farm) => (
                      <tr class="bg-white">
                        <th
                          key={farm.id}
                          scope="row"
                          class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {farm.name}
                        </th>
                        <td class="py-4 px-6">{farm?.crop?.name}</td>
                        <td class="py-4 px-6">{farm?.region}</td>
                        <td class="py-4 px-6">{farm?.acres}</td>
                        <td class="py-4 px-6">{farm?.community}</td>
                        <td class="py-4 px-6">{farm?.district}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
