import React, { useState, useEffect } from "react";
import { BsPerson } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillEye } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import { formatDateFull } from "../../utils/Formatter";

export default function AggregatorDetail(props) {
  const history = useHistory();
  const request = props.location.state;
  const [loading, setLoading] = useState(true);
  const [harvests, setharvests] = useState();

  const [details, setDetails] = useState();
  useEffect(() => {
    AggregatorDetail();
  }, []);

  const AggregatorDetail = () => {
    setLoading(true);
    var axios = require("axios");
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-aggregator-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setDetails(response.data.aggregator.aggregator_user);
        setharvests(response.data.aggregator.harvests);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="w-full lg:px-20 px-5 h-full py-10">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      {loading ? (
         <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="bg-white sm:rounded-sm border-2 border-gray-300">
            <div className="flex items-center px-5 justify-between">
              <p className="font-bold py-5  text-base capitalize mb-1">
              ID: {request.state.id}
              </p>
              <span className="bg-green-400 p-1 rounded-md font-semibold text-sm">
                {request.state.status}
              </span>
            </div>
            <div className="h-0.5 w-full bg-gray-500" />
            <div className="px-5">
              <p className="font-bold py-5  text-sm capitalize mb-1">
              ID: {request.state.id}
              </p>
              <div className="flex flex-col lg:flex-row md:flex-row">
                <div className="flex flex-col lg:flex-row md:flex-row my-2">
                  <BsPerson size={22} />
                  <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                    <p className="font-bold text-sm capitalize mb-2">
                      aggregator
                    </p>
                    <p className="font-light text-sm capitalize">
                      {details?.firstname + " " + details?.lastname}
                    </p>
                    <p className="font-light text-sm ">{details?.email}</p>
                    <p className="font-light text-sm capitalize">
                      {details?.phone}
                    </p>
                  </div>
                </div>
                <div className="mx-10" />

                <div className="flex flex-col lg:flex-row md:flex-row my-2">
                  <HiLocationMarker size={22} />
                  <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                    <p className="font-bold text-sm capitalize mb-2">
                      location
                    </p>
                    <p className="font-light text-sm capitalize">
                      {details?.community}
                    </p>
                    <p className="font-light text-sm capitalize">
                      {details?.district}
                    </p>
                    <p className="font-light text-sm capitalize">
                      {details?.region}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-10">
            <div className=" bg-white overflow-x-auto relative shadow-md sm:rounded-lg">
              <div className="capitalize px-5 py-2 text-black font-semibold">
                <p>harvest</p>
              </div>
              <div className="w-full h-0.5 bg-gray-100" />
              <table className="w-full text-sm text-left ">
                <thead className="text-xs text-gray-700 uppercase bg-white">
                  <tr>
                    <th scope="col" className="py-3 px-6">
                      Id
                    </th>
                    {/* <th scope="col" className="py-3 px-6">
                      value
                    </th> */}
                    <th scope="col" className="py-3 px-6">
                      weight/ton
                    </th>
                    <th scope="col" className="py-3 px-6">
                      quantity
                    </th>
                    <th scope="col" className="py-3 px-6">
                      date
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Status
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {harvests?.length > 0 ? harvests?.map((data, index) => (
                    <tr key={index} className="bg-white  dark:border-gray-700">
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {data.id}
                      </th>
                      {/* <td className="py-4 px-6">{data.value}</td> */}
                      <td className="py-4 px-6">{data.weight}</td>
                      <td className="py-4 px-6">{data.quantity}</td>
                      <td className="py-4 px-6">{formatDateFull(data.datecreated)}</td>
                      <td className="py-4 px-6">
                        <span
                          className={`${
                            data.status === "pending"
                              ? "bg-yellow-300"
                              : "bg-green-400"
                          } p-1 rounded-md font-semibold text-sm`}
                        >
                          {data.status}
                        </span>
                      </td>
                      <td className="py-4 px-6">
                        <button
                          onClick={() =>
                            history.push("/admin/harvestDetails", {
                              state: data,
                            })
                          }
                        >
                          <AiFillEye />
                        </button>
                      </td>
                    </tr>
                  )) : <tr className="col-span-6">
                     <th
                        scope="row"
                        className="py-4 px-6 font-medium  text-gray-900 whitespace-nowrap"
                      >
                        No harvest yet
                      </th>
                  </tr>
                  
                   }
                
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
