import React from "react";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import useAuth from "../../context/userContext";

const PasswordReset = () => {
  const {forgotPassword,passwordloading}=useAuth()
  return (
    <div className="w-screen  h-screen grid content-center justify-center background">
      <div className="p-5 rounded-md">
        <div className="flex content-center justify-center mb-4 ">
          <img src={logo} alt="" className="" />
        </div>
        <h1 className="text-center font-semibold text-3xl mb-3 capitalize from-neutral-900">
          reset your password
        </h1>
        <div className="lg:w-2/4 md:w-2/4 mx-auto">
          <p className="text-slate-400 text-base">
            Fear not. We'll email you instructions to reset your password. If
            you don't have access to your email anymore, you can{" "}
            <NavLink to="/" className="text-primary font-semibold underline" >
              contact admininstrator
            </NavLink>
          </p>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values) => forgotPassword(values)}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is invalid")
                .required("Email is required"),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <div className="my-6">
                  <label className="font-semibold text-base capitalize">
                    Email
                  </label>
                  <input
                    className="mt-3 w-full rounded-md border-2 border-slate-400 p-1 outline-none"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="username@website.com"
                  />
                  {touched.email && errors.email && (
                    <div className="text-red-500 text-sm">{errors.email}</div>
                  )}
                </div>

                <div className="my-6 flex lg:flex-row md:flex-row flex-col">
                  <button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    className="bg-yellowcolor capitalize hover:bg-primary  text-white font-medium py-2 px-10 rounded-md"
                  >
                    <p>{passwordloading ? "Sending...": "Send email"} </p>
                  </button>
                  <div className="my-1 lg:my-0 md:my-0 lg:mx-2 md:mx-2" />
                  <NavLink
                    to="/"
                    className=" capitalize text-primary underline  font-medium py-2"
                  >
                    <p>return to login</p>
                  </NavLink>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
