import React, { useState, useEffect } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { createSms } from "../utils/sendSms";


const OTP = ({ phone_number, VerifySms }) => {

  const [OTPCode, setOTPCODE] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = () => {
    setMinutes(2);
    setSeconds(59);
  };


  const resendOTP = () => {
    setMinutes(0);
    setSeconds(59);
    createSms()
  };


  return (
    <div className="w-full grid content-center justify-center background">
      <div className="rounded-md">
        <div className="mx-auto">
          <p className="text-center text-slate-400 text-base">
            {`A one-Time Pin has been sent to +233-(${phone_number}}`}
          </p>
          <div className="my-6">
            <div className="mx-auto items-center justify-center flex">
              <OTPInput
                value={OTPCode}
                onChange={setOTPCODE}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                // secure
                className="mx-10 border-none"
                inputStyles={{
                  borderBottomWidth: 1,
                  borderBottomColor: "gray",
                  padding: 10,
                  margin: 5,
                  // border: 0,
                }}
              />
            </div>
          </div>
          <div className="my-6">
            <div className="justify-center flex flex-col items-center">
              <button onClick={(e) => { e.preventDefault(); VerifySms(OTPCode) }} className="mb-5  bg-yellow-300 capitalize hover:bg-yellow-300 text-white font-medium py-3 px-16 rounded-md">
                <p>Verify</p>
              </button>
              {/* <ResendOTP
                renderButton={renderButton}
                remainingTime={90}
                maxTime={60}
                renderTime={renderTime}
              /> */}

              {seconds > 0 || minutes > 0 ? (
                <p>
                  Please wait for: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p className="text-sm" >Didn't recieve code?</p>
              )}

              <button
                disabled={seconds > 0 || minutes > 0}
                style={{
                  color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630"
                }}
                onClick={resendOTP}
              >
                <p className="text-md">Resend One-Time Password</p>
              </button>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

// const renderTime = () => React.Fragment;

// const renderButton = (buttonProps) => {
//   return (
//     <button
//       {...buttonProps}
//       onClick={() => }
//       className="font-semibold text-base text-gray-400 hover:text-yellow-300"
//     >
//       {buttonProps.remainingTime !== 0
//         ? `Please wait for ${buttonProps.remainingTime} sec`
//         : "Resend One-Time Password"}
//     </button>
//   );
// };
export default OTP;
