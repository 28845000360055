import React, { useEffect, useState } from "react";
import BarChart from "./Chart";
import PieChart from "./PieChart";
import { CustomModal } from "../../components";
import useAuth from "../../context/userContext";
import { OTP } from "../../components";
import axios from "axios";
import { BASE_URL } from "../../utils/api";
import { AiFillEye, AiFillDelete } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { formatDateFull } from "../../utils/Formatter";
import { FiArrowUpRight, FiArrowUpLeft } from "react-icons/fi";
import DeleteModal from "../../components/DeleteModal";
import { RiArrowLeftDownLine } from "react-icons/ri";
import order from "../../assets/order.svg";

const Dashboard = () => {
  const { user, createSms, VerifySms } = useAuth();
  const [analyticData, setAnalyticData] = useState();
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const getDashboardAnalytics = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/dashboardAnalytics/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: "",
    };

    axios(config)
      .then(function (response) {
        setAnalyticData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      id: id,
      item: "harvest",
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/deleteItem`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  console.log(analyticData)

  useEffect(() => {
    getDashboardAnalytics();
  }, []);

  return (
    <>
      <DeleteModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        title="harvest"
        handleDelete={() => handleDelete(selected)}
      />
      <div className="w-full h-full  px-2">
        {user && user?.isfirstTime ? (
          <CustomModal
            title={"Please enter the One-Time OTP to verify your phone number"}
            body={
              <OTP
                phone_number={user?.phone}
                createSms={createSms}
                VerifySms={VerifySms}
              />
            }
            onClose={true}
          />
        ) : null}
        <div className="grid grid-cols-1 md:grid-cols-2 justify-between lg:grid-cols-4 pt-2 gap-2">
          <Cards title={"total farmers"} count={analyticData?.total_farmers} />
          <Cards title={"total millers"} count={analyticData?.total_milllers} />
          <Cards title={"total requests"} count={analyticData?.total_harvests} />
          <Cards title={"total sales"} count={analyticData?.orders_count} />
        </div>
        {/* chats */}
        <div className="my-10 flex flex-col lg:flex-row gap-1 w-full">
          <div className="bg-white py-3 rounded-md border border-gray-300 h-fit w-full">
            <div className="py-1 px-5 mb-3 flex flex-row items-center justify-between">
              <p className="font-bold text-lg capitalize ">orders</p>
              <div className="flex flex-row items-center">
                <p className="text-sm text-[#6A707E] capitalize">Show:</p>
                <select className="outline-none border-none text-[#6A707E] text-sm capitalize">
                  <option>this month</option>
                </select>
              </div>
            </div>
            <div className="h-0.5 w-full bg-gray-300" />
            <div className="py-1 px-10">
              <BarChart data={analyticData && analyticData?.orderChartData} />
            </div>
          </div>
          <div className="bg-white py-3 rounded-md border border-gray-300 w-full">
            <div className="py-1 px-5 mb-3 flex flex-row items-center justify-between">
              <p className="font-bold text-lg capitalize ">Harvest</p>
              <div className="flex flex-row items-center">
                <p className="text-sm text-[#6A707E] capitalize">Show:</p>
                <select className="outline-none border-none text-[#6A707E] text-sm capitalize">
                  <option>this month</option>
                </select>
              </div>
            </div>
            <div className="h-0.5 w-full bg-gray-300" />
            {analyticData?.harvestChartData?.incoming_harvest>0 || analyticData?.harvestChartData?.outgoing_harvest>0 || analyticData?.harvestChartData?.prospective_harvest_weight_loss>0 ? (
              <>
                <div className="py-1 px-10">
                  <PieChart data={analyticData?.harvestChartData} />
                </div>
                <div className="flex flex-row items-center justify-between px-5 mx-auto gap-3 mt-4">
                  <div className="flex flex-col items-center justify-center text-center">
                    <div className="flex flex-row items-center ">
                      <div className="w-2 h-2 rounded-full bg-blue-500" />
                      <div className="w-1" />
                      <h3 className="text-xs text-[#6A707E] capitalize">
                        incoming harvest
                      </h3>
                    </div>
                    <h3 className="text-base capitalize">
                      {analyticData?.harvestChartData?.incoming_harvest}
                    </h3>
                  </div>

                  <div className="flex flex-col items-center justify-center text-center">
                    <div className="flex flex-row  items-center">
                      <div className="w-2 h-2 rounded-full bg-yellow-500" />
                      <div className="w-1" />
                      <h3 className="text-xs text-[#6A707E] capitalize">
                        outgoing harvest
                      </h3>
                    </div>
                    <h3 className="text-base  capitalize">
                      {analyticData?.harvestChartData?.outgoing_harvest}
                    </h3>
                  </div>

                  <div className="flex flex-col items-center justify-center text-center">
                    <div className="flex flex-row items-center ">
                      <div className="w-2 h-2 rounded-full bg-black" />
                      <div className="w-1" />
                      <h3 className="text-xs text-[#6A707E] capitalize">
                        prospective harvest weight loss
                      </h3>
                    </div>
                    <h3 className="text-base capitalize">
                      {
                        analyticData?.harvestChartData
                          ?.prospective_harvest_weight_loss
                      }
                    </h3>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center h-full grid content-center justify-center mt-2">
                <div className="">
                  <img src={order} alt="" className="w-24 object-contain" />
                  <h1 className="my-5 font-semibold text-base  lg:block md:block">
                    No harvest found
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* orders */}
        <div className="">
          <div className="my-10">
            <div class="overflow-x-auto relative bg-white shadow-md sm:rounded-lg">
              <p className="font-bold py-5 px-10 text-sm capitalize mb-3">
                recent harvest
              </p>
              <div className="h-0.5 bg-gray-300" />
              <table class="w-full text-xs text-center bg-white">
                <thead class="text-xs text-gray-700 uppercase bg-white">
                  <tr>
                    <th scope="col" class="py-3 px-3">
                      request id
                    </th>
                    <th scope="col" class="py-3 px-3">
                      created by
                    </th>
                    <th scope="col" class="py-3 px-3">
                      quantity
                    </th>
                    <th scope="col" class="py-3 px-3">
                      weight/ton
                    </th>
                    {/* <th scope="col" class="py-3 px-3">
                    value
                  </th> */}
                    <th scope="col" class="py-3 px-3">
                      community
                    </th>

                    <th scope="col" class="py-3 px-3">
                      request type
                    </th>

                    <th scope="col" class="py-3 px-3">
                      created on
                    </th>
                    <th scope="col" class="py-3 px-3">
                      status
                    </th>
                    {/* <th scope="col" class="py-3 px-3">
                      actions
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {analyticData?.recent_harvests.length > 0 ? (
                    analyticData?.recent_harvests.map((data) => (
                      <tr class="bg-white border-b dark:border-gray-700 capitalize">
                        <th
                          scope="row"
                          class="py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {data.harvest_id}
                        </th>
                        <td class="py-4 px-3">
                          {/* {data.harvestuser?.firstname +
                        " " +
                        data.harvestuser?.lastname} */}
                          {data.created_by}
                        </td>
                        <td class="py-4 px-3">{data.quantity}</td>
                        <td class="py-4 px-3">{data.weight}</td>
                        {/* <td class="py-4 px-3">{data.value}</td> */}
                        <td class="py-4 px-3">{data.harvestuser?.community}</td>
                        <td className="py-4 px-3 flex  item-center font-bold justify-center">
                          {data?.harvest_type === "Outgoing" ? (
                            <FiArrowUpRight
                              color="#00FF00"
                              size="22"
                              style={{ fontWeight: 900, marginRight: 2 }}
                            />
                          ) : (
                            <RiArrowLeftDownLine
                              color="#0000FF"
                              size="22"
                              style={{ fontWeight: 900, marginRight: 2 }}
                            />
                          )}{" "}
                          {data.harvest_type}
                        </td>
                        <td class="py-4 px-3">
                          {formatDateFull(data.datecreated)}
                        </td>
                        <td class="py-4 px-3">
                          <span
                            className={`
                      ${
                        data.status.toLowerCase() === "received"
                          ? "bg-green-300"
                          : data.status.toLowerCase() === "published"
                          ? "bg-yellow-300"
                          : "bg-blue-300"
                      }
                      p-1 rounded-md font-semibold text-sm
                      `}
                          >
                            {data.status}
                          </span>
                        </td>
                        <td class="py-4 px-3">
                          <div className="flex items-center gap-4 justify-center">
                            {/* <button>
                              <BsFillPlayFill size={15} />
                            </button> */}
                            {/* <button>
                              <AiFillEye size={15} />
                            </button>
                            <button
                              onClick={() => {
                                setSelected(data.id);
                                setShowConfirmModal(true);
                              }}
                            >
                              <AiFillDelete size={15} color="red" />
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="9"
                        className="py-4 px-3  border-t dark:border-gray-700 text-lg text-center text-gray-700"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Cards = ({ title, count }) => {
  return (
    <div className="bg-white my-2 lg:mb-0 p-4 w-full lg:w-48 border border-gray-300 rounded-md justify-center text-center shadow-md">
      <p className="font-bold text-sm capitalize mb-3">{title}</p>
      <p className="font-bold text-base capitalize ">{count}</p>
    </div>
  );
};

export default Dashboard;
