import axios from "axios";
import { SuccessNotification, ErrorNotification } from "./Notification";

export const createSms = () => {
  let token = localStorage.getItem("token");

  var config = {
    method: "post",
    url: `http://127.0.0.1:8000/api/v1/auth/sendotp/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: "",
  };

  axios(config)
    .then(function (response) {
      SuccessNotification("Otp has been sent to your phone number");
    })
    .catch(function (error) {
      ErrorNotification("There was an error in sending otp");
    });
};

