import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import api from "../utils/api";
import { ErrorNotification, SuccessNotification } from "../utils";
import axios from "axios";
import { BASE_URL } from "../utils/api";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const router = useHistory();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [isfirstTime, setFirstTime] = useState(false);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [allusers, setUsers] = useState();
  const [analyticData, setAnalyticData] = useState();
  const [userType, setUserType] = useState("");
  const [passwordloading, setPasswordLoading] = useState(false);
  const [resetpasswordloading, setResetPasswordLoading] = useState(false);

  async function loadUserFromCookies() {
    const token = Cookies.get("token");
    const user = window.localStorage.getItem("user");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      if (user) {
        const data = JSON.parse(user);
        setUser(data);
        setRedirectToReferrer(true);
        setisAuthenticated(true);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadUserFromCookies();
  }, []);

  const signin = async (params) => {
    try {
      var appdata = JSON.stringify({
        password: params.password,
        email: params.email,
      });

      const { data } = await api.post("auth/login/", appdata);
      if (data.token) {
        window.localStorage.setItem("email", data.user.email);

        if (data.user.is_email_verified) {
          Cookies.set("token", data.token, { expires: 60 });
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          const user = data.user;
          window.localStorage.setItem("user", JSON.stringify(user));
          window.localStorage.setItem("token", data.token);
          setUser(data.user);
          setFirstTime(data.user.isfirstTime);
          setUserType(data.user.user_type);
          setRedirectToReferrer(true);
          setisAuthenticated(true);
          router.push("/admin/dashboard");
        } else {
          window.location.href = "/confirmation";
        }
      } else {
        ErrorNotification("Wrong email and password");
      }
    } catch (error) {
      console.log(error.response.data.error)
      throw new Error(error.response.data.error);
    }
  };

  const signup = async (params) => {
    try {
      const { data } = await api.post("auth/register/", params);

      if (data.token) {
        window.localStorage.setItem("email", data.user.email);
        SuccessNotification("User account created successfully");
        window.location.href = "/confirmation";
      }
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  };

  const forgotPassword = async (payload) => {
    try {
      setPasswordLoading(true);
      const { data } = await api.post("auth/requestpasswordreset/", payload);
      SuccessNotification(data.success);
      setPasswordLoading(false);
      // window.location.href="/reset-password"
    } catch (error) {
      setPasswordLoading(false);
      ErrorNotification(error.response.data.error);
    }
  };

  const updatePassword = async (payload) => {
    setLoading(true);
    try {
      const resp = await api.post(
        "auth/updatepassword/",
        JSON.stringify(payload)
      );
      localStorage.setItem("token", resp.new_token);
      setLoading(false);
      SuccessNotification("Password has been updated successfully");
      setTimeout(() => {
        logout();
      }, 1500);
    } catch (error) {
      ErrorNotification("There was an error updating password");
      setLoading(false);
      console.log(error);
    }
  };

  const resetPassword = async (payload) => {
    setResetPasswordLoading(true);
    try {
      const resp = await api.post(
        "auth/resetpassword/",
        JSON.stringify(payload)
      );
      localStorage.setItem("token", resp.new_token);
      setResetPasswordLoading(false);
      SuccessNotification("Password has been updated successfully");
    } catch (error) {
      ErrorNotification("There was an error updating password");
      setResetPasswordLoading(false);
      console.log(error);
    }
  };

  const resendCode = async (payload) => {
    try {
      return await api.post("auth/requestpasswordcode/", payload);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getUserDetails = async (id) => {
    try {
      const user = await api.get(`auth/getUserDetails/${id}/`);
      console.log(user);
      return user;
    } catch (error) {
      console.log(error.message);
    }
  };

  const createSms = async () => {
    let token = localStorage.getItem("token");
    try {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await api.post("auth/sendotp/");
      console.log(response);
      SuccessNotification("Otp has been sent to your phone number");
    } catch (error) {
      console.log(error.message);
      ErrorNotification("There was an error in sending otp");
    }
  };

  const VerifySms = async (otp) => {
    var data = JSON.stringify({
      otp: otp,
    });
    try {
      const response = await api.post("auth/verifyphonenumber/", data);
      const modifiedndstrigifiedForStorage = JSON.stringify(response.data.user);
      localStorage.setItem("user", modifiedndstrigifiedForStorage);
      setUser(response.data.user);
      setRedirectToReferrer(true);
      setisAuthenticated(true);

      router.push("/admin/dashboard");
      SuccessNotification("Phone number has been verified successfully");
    } catch (error) {
      console.log(error.message);
      ErrorNotification("There was an error verifying phone number");
    }
  };

  const logout = () => {
    Cookies.remove("token");
    setUser(null);
    delete api.defaults.headers.Authorization;
    setRedirectToReferrer(false);
    window.location.pathname = "/";
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isfirstTime,
        signin,
        analyticData,
        loading,
        logout,
        signup,
        forgotPassword,
        userType,
        updatePassword,
        redirectToReferrer,
        isAuthenticated,
        createSms,
        resendCode,
        VerifySms,
        getUserDetails,
        forgotPassword,
        passwordloading,
        resetpasswordloading,
        resetPassword,
        loadUserFromCookies,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
