import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { IoMdAddCircleOutline, IoMdEye } from "react-icons/io";
import { BiFilter } from "react-icons/bi";
import SearchFIlter from "../../components/SearchFIlter";
import noharvest from "../../assets/noharvest.png";
import UpdateModal from "../../components/UpdateModal";

const Index = () => {
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const history = useHistory();
  const [aggregator, setAggregators] = useState([
    {
      id: 1,
      name: "Yaa Aseidu",
      phone: "024 123 4567",
      region: "Ashanti",
      district: "Kumasi",
      community: "aflao",
      date_of_birth: "2021-01-01",
      email: "yaa@gmail.com",
      crop: "palm oil",
      business_type: "indepedent",
    },
    {
      id: 2,
      name: "Kofi Boakye",
      phone: "024 123 4567",
      region: "Ashanti",
      district: "Kumasi",
      community: "aflao",
      date_of_birth: "2021-01-01",
      email: "kofi@gmail.com",
      crop: "palm oil",
      business_type: "miller",
    },
    {
      id: 2,
      name: "Samuel Doe",
      phone: "024 123 4567",
      region: "Ashanti",
      district: "Kumasi",
      community: "aflao",
      date_of_birth: "2021-01-01",
      email: "samuel@gmail.com",
      crop: "palm oil",
      business_type: "miller",
    },
  ]);

  const [loading, setLoading] = useState(false);

  const handleNewGroup = () => {
    history.push("/admin/newAggregator");
  };

  return (
    <>
      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />

      {aggregator.length < 1 ? (
        <EmptyAggregator />
      ) : (
        <div className="w-full lg:px-6 px-2 h-full py-10">
          <div className="w-full">
            <div>
              <div className="flex justify-end px-2">
                <button
                  onClick={() => handleNewGroup()}
                  className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
                >
                  <IoMdAddCircleOutline size={19} />
                  add new aggregator
                </button>
              </div>
            </div>
            <div className="flex items-center mb-1">
              <p className="font-bold text-base">FIlter: </p>
              <BiFilter size={30} />
            </div>
            <div className="">
              <Filter />
            </div>

            <div className="my-5">
              {loading ? (
                <Spinner />
              ) : (
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left bg-white">
                    <thead className=" text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          email
                        </th>
                        <th scope="col" className="py-3 px-6">
                          phone number
                        </th>
                        <th scope="col" className="py-3 px-6">
                          business type
                        </th>
                        <th scope="col" className="py-3 px-6">
                          commmunity
                        </th>
                        <th scope="col" className="py-3 px-6">
                          region
                        </th>
                        <th scope="col" className="py-3 px-6">
                          action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {aggregator.map((data, index) => (
                        <tr
                          key={data.id}
                          className={`${
                            index % 2 === 0
                              ? "bg-white border-b dark:border-gray-200"
                              : "bg-slate-100 border-b"
                          } hover:bg-gray-200`}
                        >
                          <th
                            scope="row"
                            className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <input
                              type={"checkbox"}
                              className="capitalize border-2 mr-5 border-gray-700"
                            />
                            {data.name}
                          </th>
                          <td className="capitalize py-4 px-6">{data.email}</td>
                          <td className="capitalize py-4 px-6">{data.phone}</td>
                          <td className="capitalize py-4 px-6">
                            {data.business_type}
                          </td>
                          <td className="capitalize py-4 px-6">
                            {data.community}
                          </td>
                          <td className="capitalize py-4 px-6">
                            {data.region}
                          </td>
                          <td className="capitalize py-4 px-3">
                            <div className="flex gap-2 justify-center">
                              <button
                                onClick={() => setShowUpdateModal(true)}
                                className="outline-none"
                              >
                                <MdModeEdit size={16} />
                              </button>
                              <button className="outline-none">
                                <AiFillDelete size={16} color="red" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Filter = () => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row ">
        <div className="mb-2 lg:mb-0 md:mb-0 lg:w-64 w-full">
          <input
            type={"text"}
            placeholder="search"
            value={""}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <select className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none">
            <option>region</option>
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <select className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none">
            <option>community</option>
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <select className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none">
            <option>business type</option>
          </select>
        </div>
      </div>
    </div>
  );
};

const EmptyAggregator = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noharvest} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No aggregator record found</h1>
      </div>
    </div>
  );
};

export default Index;
