import React from 'react';
import Logo from "../../assets/logo.png"

function LogoComponent() {
    return (
        <div className='flex justify-center mx-auto mt-3'>
            <img src={Logo}  alt={"logo"} className="h-16 object-contain"/>
        </div>
    );
}

export default LogoComponent;
