import React, { useState } from "react";
import LogoComponent from "./logocomponent";
import SidebarItem from "./sidebaritem";
import Dashboard from "../../assets/dashboard.png";
import farmer from "../../assets/farmer.png";
import Analytics from "../../assets/analytics.png";
import Harvest from "../../assets/harvest.png";
import Orders from "../../assets/orders.png";
import Settings from "../../assets/settings.png";
import Aggregator from "../../assets/farmer.png";
import News from "../../assets/news.png";
import Fields from "../../assets/field.png";
import Milling from "../../assets/milling.png";
import group from "../../assets/group.png";
import Sms from "../../assets/email.png";
import Mail from "../../assets/mail.png";
import Send from "../../assets/send.png";
import Time from "../../assets/time.png";
import Drawer from "react-modern-drawer";
import { IoLogOutOutline } from "react-icons/io5";
import {FiSettings} from 'react-icons/fi'
import { useHistory } from "react-router-dom";

import useAuth from "../../context/userContext";
import { VscThreeBars } from "react-icons/vsc";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const items = [
  {
    href: "/admin/dashboard",
    icon: Dashboard,
    title: "Dashboard",
  },
  {
    href: "/admin/aggregator",
    icon: Aggregator,
    title: "Aggregator",
  },
  {
    href: "/admin/harvest",
    icon: Harvest,
    title: "Harvest request",
  },
  {
    href: "/admin/orders",
    icon: Orders,
    title: "Orders",
  },

  {
    href: "/admin/farmers",
    icon: Aggregator,
    title: "Farmers",
  },
  {
    href: "/admin/farms",
    icon: Fields,
    title: "farms",
  },

  {
    href: "/admin/analytics",
    icon: Analytics,
    title: "Reports",
  },

  {
    href: "/admin/news",
    icon: News,
    title: "News",
  },

  {
    href: "/admin/groups",
    icon: group,
    title: "Groups",
  },
  {
    href: "#", //show sent messages with this url
    icon: Sms,
    title: "Campaign",
    submenu: true,
    submenuItems: [
      { href: "/admin/create-sms", icon: Send, title: "New Campaign" },
      { href: "/admin/schedule-sms", icon: Time, title: "Schedule Campaign" },
      { href: "/admin/sent-sms", icon: Mail, title: "Sent Campaign" }, //show sent messages with this url
    ],
  },

  {
    href: "/admin/settings",
    icon: Settings,
    title: "Settings",
  },
];

const aggregatorItems = [
  {
    href: "/admin/dashboard",
    icon: Dashboard,
    title: "Dashboard",
  },
  {
    href: "/admin/farmers",
    icon: farmer,
    title: "farmers",
  },
  {
    href: "/admin/farms",
    icon: Fields,
    title: "farms",
  },
  {
    href: "/admin/mills",
    icon: Milling,
    title: "mills",
  },
  // {
  //   href: "/admin/aggretator",
  //   icon: garage,
  //   title: "aggregator",
  // },
  {
    href: "/admin/harvest",
    icon: Harvest,
    title: "harvest request",
  },
  // {
  //   href: "/admin/harvest-request",
  //   icon: Harvest,
  //   title: "harvest request",
  // },
  {
    href: "/admin/orders",
    icon: Orders,
    title: "Orders",
  },
  {
    href: "/admin/analytics",
    icon: Analytics,
    title: "Reports",
  },



  {
    href: "#", //show sent messages with this url
    icon: Sms,
    title: "Campaign",
    submenu: true,
    submenuItems: [
      { href: "/admin/sms", icon: Send, title: "New Campaign" },
      { href: "/admin/schedule-sms", icon: Time, title: "Schedule Campaign" },
      { href: "/admin/sent-sms", icon: Mail, title: "Sent Campaign" }, //show sent messages with this url
    ],
  },
  {
    href: "/admin/groups",
    icon: group,
    title: "Groups",
  },
  {
    href: "/admin/settings",
    icon: Settings,
    title: "Settings",
  },
];

const SideBarComponent = ({
  showSidebar,
  setSidebar,
  selectedItem,
  setLink,
}) => {
  const { logout } = useAuth();
  const [openSubmenu, setOpenSubMenu] = useState(false);
  const obj = JSON.parse(localStorage.getItem("user"));
  const userType = obj.user_type;
  const history=useHistory()

  const toggleDrawer = () => {
    setSidebar(!showSidebar);
  };

  const onClose = () => {
    setSidebar(false);
  };
  
  return (
    <>
      <div
        className={`${
          showSidebar ? "w-[239px] " : "w-0 z-0 shadow-none drop-shadow-none"
        } h-screen bg-white absolute drop-shadow-2xl z-50 duration-300  lg:relative lg:top-0 lg:bottom-0 flex flex-col overflow-auto`}
      >
        <div className="flex flex-row items-center">
          <LogoComponent />
          <button onClick={toggleDrawer} className="outline-none mr-3">
            <BsToggleOn size={24} color="#f71515" />
          </button>
        </div>

        <div className="flex flex-col my-5">
          {userType === "miller" ? (
            <>
              {items.map((item, key) => (
                <SidebarItem
                  key={key}
                  title={item.title}
                  index={key}
                  url={item.href}
                  icon={item.icon}
                  submenu={item.submenu}
                  submenuItems={item.submenuItems}
                  openSubmenu={openSubmenu}
                  setOpenSubMenu={setOpenSubMenu}
                  toggleDrawer={onClose}
                />
              ))}
            </>
          ) : (
            <>
              {aggregatorItems.map((item, key) => (
                <SidebarItem
                  key={key}
                  title={item.title}
                  index={key}
                  url={item.href}
                  icon={item.icon}
                  submenu={item.submenu}
                  submenuItems={item.submenuItems}
                  openSubmenu={openSubmenu}
                  setOpenSubMenu={setOpenSubMenu}
                  toggleDrawer={onClose}

                />
              ))}
            </>
          )}
        </div>

        <div className="flex w-full flex-col space-y-2 items-end justify-end h-full">
        <button

          onClick={()=>history.push("/admin/support")}
          
            className="flex w-full text-sm font-bold py-4 px-6 space-x-2 text-slate-400"
          >
            <FiSettings size={24} />
            <span>Support & FAQS</span>
          </button>

          <button
            onClick={logout}
            className="flex w-full text-sm font-bold py-4 px-6 space-x-2 text-slate-400"
          >
            <IoLogOutOutline size={24} />
            <span> Logout</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SideBarComponent;
