import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BASE_URL } from "../utils/api";
import axios from "axios";
import { SuccessNotification } from "../utils";
import CustomSelect from "./CustomSelect";
import { CustomSelect2 } from "./CustomSelect";
import { CustomSelect3 } from "./CustomSelect";
import api from "../utils/api";

const UpdateModal = ({
  showUpdateModal,
  setShowUpdateModal,
  handleUpdate,
  data,
  item,
  setLoading,
  modalData,
  payload,
}) => {
  const onOpenModal = () => setShowUpdateModal(true);
  const onCloseModal = () => setShowUpdateModal(false);

  const [refreshing, setRefreshing] = useState(false);
  const [uploadloading, setUpLoading] = useState(false);
  const [districts, setDistrict] = useState();
  const [$community, setCommunity] = useState();

  const getDistrict = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));



      setDistrict(data.data.districts);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  const getCommunity = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );


      setCommunity(data.data.communities);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  const regions = [
    { id: 1, name: "Western North Region" },
    { id: 2, name: "Savannah Region" },
    { id: 3, name: "Oti Region", },
    { id: 4, name: "Western Region" },
    { id: 5, name: "Upper West Region" },
    { id: 6, name: "Upper East Region" },
    { id: 7, name: "Northern Region" },
    { id: 8, name: "Greater Accra Region" },
    { id: 9, name: "Eastern Region" },
    { id: 10, name: "Central Region" },
    { id: 11, name: "Volta Region" },
    { id: 12, name: "North East Region", },
    { id: 13, name: "Ahafo Region" },
    { id: 14, name: "Brong-Ahafo Region" },
    { id: 15, name: "Ashanti Region" },
    { id: 16, name: "Bono East Region" },


  ];






  const [state, setState] = React.useState(null);


  const handleChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
    console.log(state)
  };


  function handleChange2(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });

    console.log(state)
  }


  const handleSubmit = async () => {
    let token = localStorage.getItem("token")
    var data = JSON.stringify({
      ...payload,
      ...state,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/updateItem/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setLoading();
        setState(null);
        SuccessNotification("Uploaded successfully")
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal open={showUpdateModal} onClose={onCloseModal} center>
      <div className="w-full">
        <div className="w-fit flex justify-between items-center">
          <h1 className="text-lg font-semibold ">{data?.title}</h1>
        </div>
        <div className="w-full mt-4 grid lg:grid-cols-2 lg:items-center md:grid-cols-2 md:items-center gap-2">
          {data?.dataset?.map((item, index) => (
            <>
              <div
                key={index}
                className="flex justify-between items-center gap-1 mb-2"
              >
                <label className="text-base font-semibold capitalize flex-auto w-32">
                  {item?.label}:
                </label>




                {item?.inputType === "radio" ?
                  <div className="flex  flex-auto w-full  gap-3">
                    {item?.values.map((i, index) => {
                      return (
                        <div class="flex items-center">
                          <input
                            id="default-radio-1"
                            type="radio"
                            name={item?.label}
                            defaultChecked={modalData?.[item?.label] === i.name}

                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            value={i.name}
                            onChange={(e) => handleChange(item?.label)(e)}
                          />
                          <label
                            for="default-radio-1"
                            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {i.name}
                          </label>
                        </div>
                      )

                    })

                    }
                  </div>

                  : item?.inputType === "select" ?
                    <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
                      <select
                        name={item?.label}
                        onChange={(e) => handleChange(item?.label)(e)}

                        className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
                      >
                        {item?.values.map((i, index) => {
                          return (
                            <option key={index} value={i?.name}>
                              {i?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    : item?.inputType === "Custom1" ?
                      <div className="flex-initial w-full ">
                        <CustomSelect
                          selectedItem={modalData?.region}
                          value={regions}
                          getDistrict={getDistrict}
                          handleChange={handleChange2}
                          options={regions}
                          name="region"
                        />

                      </div> : item?.inputType === "Custom2" ?
                        <div className="flex-initial w-full">
                          {districts?.length === 0 ? (
                            <h4 className="text-center">Loading...</h4>
                          ) : (
                            <CustomSelect2
                              selectedItem={modalData?.district}
                              getCommunity={getCommunity}
                              value={state?.district}
                              handleChange={handleChange2}
                              options={districts}
                              name="district"
                            />

                          )}
                        </div> : item?.inputType === "Custom3" ? <div className="flex-initial w-full">
                          {$community?.length === 0 ? (
                            <h4 className="text-center">Loading...</h4>
                          ) : (
                            <CustomSelect3
                              selectedItem={modalData?.community}
                              value={state?.community}
                              handleChange={handleChange2}
                              options={$community}
                              name="community"
                            />
                          )}
                        </div> : item?.inputType === "textarea" ? <>
                          <textarea
                            rows={"3"}


                            className={`
                          ${"flex w-full"}
                  
                  `}
                            name={item?.label}
                            defaultValue={modalData?.[item?.label.toLowerCase()]}
                            onChange={(e) => handleChange(item?.label.toLowerCase())(e)}
                          />

                        </> :


                          <>
                            <input
                              type={item?.inputType}
                              className={`
                  ${item?.inputType !== "radio" ? "flex-auto w-full" : null}
                  
                  `}
                              name={item?.label}
                              defaultValue={modalData?.[item?.label]}
                              onChange={(e) => handleChange(item?.label)(e)}
                            />

                          </>


                }






              </div>
            </>
          ))}
        </div>
        <div class="my-4">
          <div className="flex flex-row items-center gap-4">
            <button
              onClick={() => {
                handleSubmit();
                onCloseModal();
              }}
              className="capitalize bg-yellowcolor text-white w-full px-4 py-2 rounded-md"
            >
              Update
            </button>
            <button
              onClick={() => {
                onCloseModal();
              }}
              className="capitalize bg-[#e41d21] text-white w-full px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
