import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function AddAgregator() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>
        <div className="bg-white px-4 py-2 mt-2 rounded-sm shadow-md">
          <div className="py-2 border-b border-gray-100s">
            <h2 className="text-base font-bold capitalize">
              Add a new aggregator
            </h2>
          </div>
          <div className="py-4">
            <h2 className="text-sm font-bold capitalize mb-5 ">
              personal information
            </h2>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                defaultValue={""}
                name="firstname"
                onChange={() => {}}
                label="First name"
              />
              <RenderInput
                name="lastname"
                onChange={() => {}}
                label="Last name"
                defaultValue={""}
              />
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                defaultValue={""}
                name="email"
                onChange={() => {}}
                label="email"
                type="email"
              />
              <div class="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
                <div class="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  Phone number:
                </div>
                <div class="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <div className="flex-auto">
                    <div className="flex border border-slate-400 rounded-md">
                      <span className="inline-flex items-center px-3 text-sm text-slate-500 bg-white border border-slate-400">
                        +233
                      </span>
                      <input
                        type="text"
                        name="phone"
                        onChange={() => {}}
                        defaultValue={""}
                        className="w-full py-1.5 px-3 outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  region:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="region"
                    id="region"
                  >
                    <option value="select region">select region</option>
                  </select>
                </div>
              </div>
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  community:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="community"
                    id="community"
                  >
                    <option value="select district">select community</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="grid gap-2 md:px-10 lg:px-10 lg:flex md:flex lg:flex-row lg:justify-end items-center mt-10">
            <button className="bg-red-600 px-16 py-1 capitalize text-black rounded-md shadow-md">
              <p>cancel</p>
            </button>
            <div className="mx-4" />
            <button
              onClick={(e) => {}}
              className="bg-amber-400 px-16 py-1 capitalize text-black rounded-md shadow-md"
            >
              <p>{loading ? "Loading..." : "Save"}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const RenderInput = ({
  value,
  placeholder,
  onChange,
  name,
  label,
  defaultValue,
  disabled,
  type,
}) => {
  return (
    <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
      <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
        {`${label}:`}
      </label>
      <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
        <input
          name={name}
          // value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          className="w-full rounded-md border border-slate-400 p-1 outline-none"
        />
      </div>
    </div>
  );
};
