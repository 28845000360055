import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import api, { BASE_URL } from "../../utils/api";
import { IoMdAddCircleOutline, IoMdEye } from "react-icons/io";
import { BiFilter } from "react-icons/bi";
import SearchFIlter from "../../components/SearchFIlter";
import noharvest from "../../assets/noharvest.png";
import { BsFillPlayFill } from "react-icons/bs";
import { GrPowerReset } from "react-icons/gr";
import moment from "moment";
import { FiArrowUpRight, FiArrowUpLeft } from "react-icons/fi";
import { formatDateFull } from "../../utils/Formatter";
import { RiArrowLeftDownLine } from "react-icons/ri";
import DeleteModal from "../../components/DeleteModal";
import { SuccessNotification } from "../../utils";
import { Redirect, useLocation } from "react-router-dom";
import { BsCheck } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { FaRegTimesCircle } from 'react-icons/fa'

const Index = () => {
  const history = useHistory();
  const [harvests, setHarvests] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [district, setDistrict] = useState();
  const [community, setCommunity] = useState();
  const [count, setCount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isFiltered, setIsfiltered] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [harvestObj, setHarvestObj] = useState();
  const [q, setQ] = useState("");

  const initialState = {
    status: "",
    request_type: ""
  };

  const [states, setState] = useState(initialState);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];



  const searchHarvests = () => {
    let query = q.toLowerCase();

    const filteredData = harvests.filter(
      (el) =>
        el.created_by
          .toLowerCase().includes(query) ||
        el.harvest_initiator.community
          .toLowerCase().includes(query) ||
        el.harvest_id == query ||
        el.weight == query || el.quantity == query

    )
    setIsfiltered(true)


    setFilteredResults(filteredData);

  }

  const filterHarvests = () => {
    console.log(states)
    const filteredData = harvests.filter(
      (el) =>
        el.harvest_type
          .toLowerCase() === states.request_type.toLowerCase() && el.status
            .toLowerCase() === states.status.toLowerCase()

    );

    setIsfiltered(true)
    setFilteredResults(filteredData);
  };

  const getAllHarvest = (pageNumber) => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/get-all-harvests/?page=${pageNumber}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setHarvestObj(response.data)
        setHarvests(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleNext = () => {
    console.log(harvests)
    if (harvestObj.next) {
      const page = currentPage + 1
      setCurrentPage(page);
      getAllHarvest(page);
    }
  };

  const handlePrev = () => {
    if (harvestObj.previous) {
      const page = currentPage - 1
      setCurrentPage(page);

      getAllHarvest(page);
    }
  };

  useEffect(() => {
    getAllHarvest(1);
  }, []);

  const handleRowClick = (row) => {
    if (row.harvest_type === "Outgoing") {
      history.push("/admin/harvest-request-detail", { state: row });
    } else {
      history.push("/admin/aggregatorharvestDetails", { state: row });
    }
  };

  const handleNewGroup = () => {
    history.push("/admin/add-harvest");
  };

  const reset = () => {
    setQ("");
    setIsfiltered(false)
    setFilteredResults([]);
    getAllHarvest(1);
  };

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      id: id,
      item: "harvest",
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/deleteItem/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        SuccessNotification("Item deleted successfully");
        getAllHarvest(1);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <>
      <DeleteModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        title="harvest"
        handleDelete={() => handleDelete(selected)}
      />
      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div>
            <div className="flex justify-end px-2">
              <button
                onClick={() => handleNewGroup()}
                className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
              >
                <IoMdAddCircleOutline size={19} />
                create harvest request
              </button>
            </div>
          </div>
          <div className="flex items-center mb-1">
            <p className="font-bold text-base">FIlter: </p>
            <BiFilter size={30} />
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2">
            <div className="lg:mb-0 mb-2">
              <SearchFilter
                setQuery={setQ}
                searchHarvests={searchHarvests}
                value={q}
                reset={reset}
              />
            </div>
            <Filter
              handleChange={handleChange}
              filterHarvests={filterHarvests}
              reset={reset}
            />
          </div>
          {harvests?.length < 1 ? (
            <EmptyHarvest />
          ) : (
            <>
              <div className="my-5">
                {loading ? (
                  <div className="h-96 grid items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table class="w-full text-xs text-center bg-white">
                      <thead class="text-xs  text-gray-700 uppercase bg-white">
                        <tr>
                          <th scope="col" class="py-3 px-3">
                            request id
                          </th>
                          <th scope="col" class="py-3 px-3">
                            created by
                          </th>
                          <th scope="col" class="py-3 px-3">
                            quantity
                          </th>
                          <th scope="col" class="py-3 px-3">
                            weight/ton
                          </th>
                          {/* <th scope="col" class="py-3 px-3">
                            value
                          </th> */}
                          <th scope="col" class="py-3 px-3">
                            community
                          </th>
                          <th scope="col" class="py-3 px-3">
                            request type
                          </th>
                          <th scope="col" class="py-3 px-3">
                            created on
                          </th>
                          <th scope="col" class="py-3 px-3">
                            responses
                          </th>
                          <th scope="col" class="py-3 px-3">
                            Scheduled date
                          </th>
                          <th scope="col" class="py-3 px-3">
                            Delivered
                          </th>
                          <th scope="col" class="py-3 px-3">
                            status
                          </th>
                          <th scope="col" class="py-3 px-3">
                            actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredResults.length > 0
                          ? filteredResults &&
                          filteredResults?.map((data, index) => (
                            <>
                              <tr
                                key={index}
                                className={`${index % 2 === 0
                                  ? "bg-white border-b dark:border-gray-200"
                                  : "bg-slate-100 border-b"
                                  } hover:bg-gray-100 capitalize`}
                              >
                                <th
                                  scope="row"
                                  class="py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {data.harvest_id}
                                </th>
                                <td class="py-4 px-3">{data?.initiator_type}</td>
                                <td class="py-4 px-3">{data?.quantity}</td>
                                <td class="py-4 px-3">{data?.weight}</td>

                                {/* <td class="py-4 px-3">{data?.value}</td> */}
                                <td class="py-4 px-3">
                                  {data?.harvest_initiator?.community}
                                </td>
                                <td className="py-4 px-3 flex  item-center font-bold justify-center">
                                  {data?.harvest_type === "Outgoing" ? (
                                    <FiArrowUpRight
                                      color="#00FF00"
                                      size="22"
                                      style={{
                                        fontWeight: 900,
                                        marginRight: 2,
                                      }}
                                    />
                                  ) : (
                                    <RiArrowLeftDownLine
                                      color="#0000FF"
                                      size="22"
                                      style={{
                                        fontWeight: 900,
                                        marginRight: 2,
                                      }}
                                    />
                                  )}{" "}
                                  {data.harvest_type}
                                </td>
                                <td class="py-4 px-3">
                                  {formatDateFull(data?.datecreated)}
                                </td>
                                <td class="py-4 px-3">
                                  {data?.total_responses}
                                </td>
                                <td class="py-4 px-3">
                                  {data?.harvest_type === "Incoming" ? formatDateFull(data?.harvest_date) : data.harvest_date === null ? "Daily" : data?.harvest_type === "Outgoing" && data?.schedule_type === null ? formatDateFull(data?.harvest_date) : data?.harvest_date}
                                </td>


                                <td className="py-4 px-3">
                                  {data.delivered === false ? (
                                    <div className="flex items-center justify-center">
                                      <p className="text-green text-center">
                                        <FaRegTimesCircle className="text-red-800" size={20} />
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-center">
                                      <p className="text-green text-center">
                                        <AiOutlineCheckCircle className="" style={{ color: "00FF00" }} size={20} />
                                      </p>
                                    </div>
                                  )}
                                </td>
                                <td class="py-4 px-3">
                                  <span
                                    className={`
                      ${data.status.toLowerCase() === "responded"
                                        ? "bg-green-300"
                                        : data.status.toLowerCase() === "pending"
                                          ? "bg-blue-300"
                                          : "bg-blue-300"
                                      }
                      p-1 rounded-md font-semibold text-sm
                      `}
                                  >
                                    {data.status}
                                  </span>
                                </td>


                                <td class="py-4 px-3">
                                  <div className="flex items-center gap-4 justify-center">
                                    {/* <button>
                                    <BsFillPlayFill size={15} />
                                  </button> */}
                                    <button
                                      onClick={() => handleRowClick(data)}
                                    >
                                      <AiFillEye size={15} />
                                    </button>
                                    {data?.harvest_type === "Outgoing" ? <button
                                      onClick={() => {
                                        setSelected(data.id);
                                        setShowConfirmModal(true);
                                      }}
                                    >
                                      <AiFillDelete size={15} color="red" />
                                    </button> : null}
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))

                          : isFiltered === true && filteredResults.length < 1 ?
                            <tr>
                              <td
                                colSpan="9"
                                className="py-4 px-3  border-t dark:border-gray-700 text-lg text-center text-gray-700"
                              >
                                No data available
                              </td>
                            </tr> :

                            harvests &&
                            harvests?.map((data, index) => (
                              <tr
                                key={index}
                                className={`${index % 2 === 0
                                  ? "bg-white border-b dark:border-gray-200"
                                  : "bg-slate-100 border-b"
                                  } hover:bg-gray-100 capitalize`}
                              >
                                <th
                                  scope="row"
                                  class="py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {data.harvest_id}
                                </th>
                                <td class="py-4 px-3">{data?.initiator_type}</td>
                                <td class="py-4 px-3">{data?.quantity}</td>
                                <td class="py-4 px-3">{data?.weight}</td>

                                {/* <td class="py-4 px-3">{data?.value}</td> */}
                                <td class="py-4 px-3">
                                  {data?.harvest_initiator?.community}
                                </td>
                                <td className="py-4 px-3 flex  item-center font-bold justify-center">
                                  {data?.harvest_type === "Outgoing" ? (
                                    <FiArrowUpRight
                                      color="#00FF00"
                                      size="22"
                                      style={{
                                        fontWeight: 900,
                                        marginRight: 2,
                                      }}
                                    />
                                  ) : (
                                    <RiArrowLeftDownLine
                                      color="#0000FF"
                                      size="22"
                                      style={{
                                        fontWeight: 900,
                                        marginRight: 2,
                                      }}
                                    />
                                  )}{" "}
                                  {data.harvest_type}
                                </td>
                                <td class="py-4 px-3">
                                  {formatDateFull(data?.datecreated)}
                                </td>
                                <td class="py-4 px-3">
                                  {data?.total_responses}
                                </td>
                                <td class="py-4 px-3">
                                  {data?.harvest_type === "Incoming" ? formatDateFull(data?.harvest_date) : data.harvest_date === null ? "Daily" : data?.harvest_type === "Outgoing" && data?.schedule_type === null ? formatDateFull(data?.harvest_date) : data?.harvest_date}
                                </td>


                                <td className="py-4 px-3">
                                  {data.delivered === false ? (
                                    <div className="flex items-center justify-center">
                                      <p className="text-green text-center">
                                        <FaRegTimesCircle className="text-red-800" size={20} />
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-center">
                                      <p className="text-green text-center">
                                        <AiOutlineCheckCircle className="text-green-400" size={20} />
                                      </p>
                                    </div>
                                  )}
                                </td>
                                <td class="py-4 px-3">
                                  <span
                                    className={`
                      ${data.status.toLowerCase() === "responded"
                                        ? "bg-green-300"
                                        : data.status.toLowerCase() === "pending"
                                          ? "bg-blue-300"
                                          : "bg-blue-300"
                                      }
                      p-1 rounded-md font-semibold text-sm
                      `}
                                  >
                                    {data.status}
                                  </span>
                                </td>


                                <td class="py-4 px-3">
                                  <div className="flex items-center gap-4 justify-center">
                                    {/* <button>
                                    <BsFillPlayFill size={15} />
                                  </button> */}
                                    <button
                                      onClick={() => handleRowClick(data)}
                                    >
                                      <AiFillEye size={15} />
                                    </button>
                                    {data?.harvest_type === "Outgoing" ? <button
                                      onClick={() => {
                                        setSelected(data.id);
                                        setShowConfirmModal(true);
                                      }}
                                    >
                                      <AiFillDelete size={15} color="red" />
                                    </button> : null}
                                  </div>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              {filteredResults.length > 0 && isFiltered === true ? <div className="mt-5">
                <div class="flex flex-col items-center">
                  <span class="text-sm text-gray-700 dark:text-gray-400">
                    Showing{" "}
                    <span class="font-semibold text-gray-900 dark:text-white">
                      {filteredResults.length > 0
                        ? filteredResults.length
                        : harvests?.length}
                    </span>{" "}
                    to{" "}
                    <span class="font-semibold text-gray-900 dark:text-white">
                      {filteredResults.length > 0
                        ? filteredResults.length
                        : harvests?.length}
                    </span>{" "}
                    of{" "}
                    <span class="font-semibold text-gray-900 dark:text-white">
                      {filteredResults.length > 0
                        ? filteredResults.length
                        : count}
                    </span>{" "}
                    Entries
                  </span>
                  <div class="inline-flex mt-2 xs:mt-0">
                    <button
                      onClick={() => handlePrev()}
                      class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        class="mr-2 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      Prev
                    </button>
                    <button
                      onClick={() => handleNext()}
                      class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Next
                      <svg
                        aria-hidden="true"
                        class="ml-2 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>

              </div> :
                filteredResults.length < 1 && isFiltered === true ?
                  null :
                  harvests?.length > 0 && (
                    <div className="mt-5">
                      <div class="flex flex-col items-center">
                        <span class="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span class="font-semibold text-gray-900 dark:text-white">
                            {filteredResults.length > 0
                              ? filteredResults.length
                              : harvests?.length}
                          </span>{" "}
                          to{" "}
                          <span class="font-semibold text-gray-900 dark:text-white">
                            {filteredResults.length > 0
                              ? filteredResults.length
                              : harvests?.length}
                          </span>{" "}
                          of{" "}
                          <span class="font-semibold text-gray-900 dark:text-white">
                            {filteredResults.length > 0
                              ? filteredResults.length
                              : count}
                          </span>{" "}
                          Entries
                        </span>
                        <div class="inline-flex mt-2 xs:mt-0">
                          <button
                            onClick={() => handlePrev()}
                            class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            <svg
                              aria-hidden="true"
                              class="mr-2 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                            Prev
                          </button>
                          <button
                            onClick={() => handleNext()}
                            class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          >
                            Next
                            <svg
                              aria-hidden="true"
                              class="ml-2 w-5 h-5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const SearchFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchHarvests,
  reset,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by id, weight, createdby, quantity..."
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searchHarvests()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            reset();
          }}
          size={20}
        />
      </div>
    </div>
  );
};

const Filter = ({
  handleChange,
  filterHarvests,
  reset,
}) => {

  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="status"
            onChange={handleChange}

            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option key="1" value="Choose an option">Choose a status type </option>
            <option key="2" value="Responded">Responded</option>
            <option key="3" value="Pending">Pending</option>


          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="request_type"
            onChange={handleChange}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option key="1" value="Choose an option">Choose a request type</option>
            <option key="2" value="Outgoing">Outgoing</option>
            <option key="3" value="Incoming">Incoming</option>
          </select>
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filterHarvests()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => reset()}
          size={20}
        />
      </div>
    </div>
  );
};

const EmptyHarvest = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noharvest} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No harvest record found</h1>
      </div>
    </div>
  );
};

export default Index;