import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import GoogleMapReact from "google-map-react";
import "./map.css";

const MapComponent = ({ location, zoomLevel }) => (
  <div className="map">
    <div className="google-map" style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCAAdM-WGYkgKZ7_x4vGEHC5zZ3P-TQ6eU" }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
);

const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon height={35} icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
);

export default MapComponent;
