import React, { useState } from "react";
import logo from "./logo.png";
import svgImage from "../../assets/Lock.png";
import useAuth from "../../context/userContext";
import { BsWindowSidebar } from "react-icons/bs";

const EmailConfirmation = () => {
  const email=localStorage.getItem("email")
  return (
    <div className="container">
      <div className="w-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen">
        <div className="bg-[url('/src/background.png')] w-full bg-amber-400 hidden md:block lg:block">
          <div className="bg-[url('/src/Rectangle.png')] h-screen bg-opacity-0 w-full bg-amber-400">
            <h1 className="px-20 pb-6 font-extrabold text-5xl leading-tight text-white opacity-100 absolute inset-x-0 bottom-0">
              Welcome <br />
              to Solidaridad <br />
              Harvest Alert
            </h1>
          </div>
        </div>
        <div className="w-full bg-white grid content-center">
          <div className="text-center grid content-center ">
            <div className="flex content-center justify-center">
              <img src={logo} alt="" className="" />
            </div>
          </div>
          <div className="w-9/12 my-10  grid content-center items-center justify-center mx-auto ">
            <div className="w-full text-center items-center justify-center">
              <div className="text-center grid content-center mb-5 ">
                <div className="flex content-center justify-center">
                  <img src={svgImage} alt="" className="" />
                </div>
              </div>

              <h1 className="font-bold text-2xl capitalize text-black">
                Thank you!
              </h1>
              <p className="text-sm text-gray-400 mt-2">
                We have sent email to {email}
                <br />
                Check confirmation link in the email to verify your acount
              </p>
              <AppButton />
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

const AppButton = () => {
  return (
    <button onClick={()=>window.location.href="/"} className="w-96 bg-amber-400 outline-hidden hover:bg-amber-500 mt-5 text-white font-bold py-2 px-4 rounded z-50">
      Go to Home
    </button>
  );
};

export default EmailConfirmation;
