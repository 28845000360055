import React from "react";

import { BrowserRouter, Routes } from "react-router-dom";

import { Content } from "./content";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/userContext";
import SessionTimeout from "./components/SessionTimeout";
import PWAPrompt from 'react-ios-pwa-prompt'



function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastContainer />
        <PWAPrompt copyTitle="Add Harvest Alert to Home Screen" copyBody=" website has app functionality. Add it to your home screen to use it in fullscreen and while offline." copyClosePrompt="Close" permanentlyHideOnDismiss={false}  />
       
         <Content />

        <SessionTimeout />
      </AuthProvider>

    </BrowserRouter>

  );
}

export default App;
