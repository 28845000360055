import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { CustomModal } from "../../components";
import FormSelect from "../../components/FormSelect";
import axios from "axios";
import { BsFileArrowUp, BsWindowSidebar } from "react-icons/bs";
import useAuth from "../../context/userContext";
import { ErrorNotification, SuccessNotification } from "../../utils";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import AyncSelectForm from "../../components/AsyncSelect";

export default function GroupDetail(props) {
  const history = useHistory();
  const request = props.location.state;
  const [show, setShow] = React.useState(false);
  const [group, setGroup] = useState();
  const [groupMembers, setGroupMembers] = useState();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();


  const obj = JSON.parse(localStorage.getItem("user"));
  const userType = obj.user_type;

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };



  const getGroupDetail = () => {
    setLoading(true);
    var axios = require("axios");
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url:
        userType === "miller"
          ? `${BASE_URL}miller/get-group-details/${request.state.id}/`
          : `${BASE_URL}aggregator/get-group-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setGroup(response.data);
        setGroupMembers(response.data.participants);
        console.log("members", response.data.participants)
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const removeMember = (id) => {
    const token = localStorage.getItem("token");
    var data = JSON.stringify({
      id: id.toString(),
    });
    var config = {
      method: "post",
      url:
        userType === "miller"
          ? `${BASE_URL}miller/remove-group-member/${request.state.id}/`
          : `${BASE_URL}aggregator/remove-group-member/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        SuccessNotification("You have succesessfully removed a member");
        getGroupDetail();
      })
      .catch(function (error) {
        ErrorNotification("An error occurred while removing member");
      });
  };

  useEffect(() => {
    getGroupDetail();
  }, []);

  return (
    <>
      {show && (
        <CustomModal
          body={
            <RenderAddMember
              members={groupMembers}
              id={request.state.id}
              setShow={setShow}
              setGroupMembers={setGroupMembers}
              getGroupDetail={getGroupDetail}
              usertype={request.state.usertype}
            />
          }
          title="Add new member"
          onClose={true}
          show={show}
          handleClose={handleClose}
        />
      )}
      <div className="w-full lg:px-20 px-5 h-full py-10">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
          <button
            onClick={() => setShow(true)}
            className="rounded-lg bg-yellowcolor p-2 flex px-6  items-center capitalize"
          >
            add member
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="bg-white px-4 py-2 mt-5 sm:rounded-sm border-2 border-gray-300 shadow-md">
            <div className="py-2 border-b border-gray-100s">
              <h2 className="text-base font-bold capitalize">{`${group && group.groupname
                } `}</h2>
            </div>
            <div className="py-4">
              <h2 className="text-base font-bold capitalize">description</h2>
              <p className="text-base text-gray-600 py-4">
                {group && group.description}
              </p>
              <div className="my-5">
                <div className="overflow-x-auto relative ">
                  <table className="w-full text-sm text-left bg-white">
                    <thead className="text-xs text-gray-700 capitalize bg-slate-200 ">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          ID
                        </th>
                        <th scope="col" className="py-3 px-6">
                          name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          phone
                        </th>
                        <th scope="col" className="py-3 px-6">
                          actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {group &&
                        group.participants.map((data, index) => (
                          <tr
                            key={index}
                            className={`${index % 2 === 0
                              ? "bg-white  dark:border-gray-700 "
                              : "bg-slate-100  "
                              }`}
                          >
                            <th
                              scope="row"
                              className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                className="capitalize border-2 mr-5 border-gray-700"
                              />
                              {data.id}
                            </th>
                            <td className="capitalize py-4 px-6">
                              {data.firstname + " " + data.lastname}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data.phone}
                            </td>
                            <td className="capitalize py-4 px-6 items-center grid grid-cols-5 gap-2">
                              <button
                                onClick={() => removeMember(data.id)}
                                className="outline-none"
                              >
                                <AiFillDelete size={16} color="red" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const RenderAddMember = ({
  onClick,
  members,
  id,
  getGroupDetail,
  usertype,
  setShow,
  setGroupMembers,

}) => {
  const [options, setOptions] = useState();
  const [allusers, setallusers] = useState();
  const [values, setValues] = React.useState([]);
 
  const [loadusers, setloadusers] = useState(false);
  const [searchText, setSearchText] = useState("");









  const addNewMember = () => {
    const token = localStorage.getItem("token");
    let pArray = [];

    for (var i = 0; i < values.length; i++) {
      pArray.push(values[i].value.toString());
    }

    var data = JSON.stringify({
      new_users: pArray.toString(),
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/add-new-member/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setShow(false)
        SuccessNotification("You have succesessfully added new members");
        getGroupDetail();
      })
      .catch(function (error) {
        ErrorNotification("An error occurred while adding new members");
      });
  };

  const getUsers = () => {
    const token = localStorage.getItem("token");

    var data = JSON.stringify({
      usertype: usertype,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/getallusers/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        var res = response.data.users.filter((n) => !members.some((n2) => n.user.id === n2.id));


        let users = [];
        for (var i = 0; i < res.length; i++) {
          let u = {
            value: res[i].user.id,
            label: res[i].user.firstname + " " + res[i].user.lastname,
          };
          users.push(u);
        }

        console.log(users)

        setOptions(users);
      })
      .catch(function (error) {
        ErrorNotification("An error occurred while adding new members");
      });
  };

  const getallfarmers = (text) => {
    return new Promise((resolve, reject) => {

      var token = localStorage.getItem("token");
      setloadusers(true);
      if (text == undefined) {
        text = ""
      }

      var config = {
        method: "get",
        url: `${BASE_URL}aggregator/getallaggregators/?search_item=${text}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      setTimeout(() => {
        axios(config)
          .then(function (response) {
            console.log(response.data)

            let users = [];
            var res = response.data.users.filter((n) => !members.some((n2) => n.user.id === n2.id));
            for (var i = 0; i < res.length; i++) {
              let u = {
                value: res[i].user.id,
                label:
                  res[i].user.firstname +
                  " " +
                  res[i].user.lastname,
              };
              users.push(u);
            }
            setloadusers(false);
            setOptions(users);
            resolve(users);
          })
          .catch(function (error) {
            console.log(error);
            setloadusers(false);
            reject(error);
          });
      }, 2000); // 5 seconds timeout
    });
  };



  const getallmillers = (usertype) => {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("token");
      setloadusers(true);
      let c = {
        usertype: usertype,
      };
      var config = {
        method: "get",
        url: `${BASE_URL}aggregator/getallmillers/?search_item=${searchText}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: c,
      };

      setTimeout(() => {
        axios(config)
          .then(function (response) {
            console.log(response.data);
            let users = [];
            var res = response.data.users.filter((n) => !members.some((n2) => n.user.id === n2.id));

            for (var i = 0; i < res.length; i++) {
              let u = {
                value: res[i].miller,
                label:
                  res[i].user.firstname +
                  " " +
                  res[i].user.lastname,
              };
              users.push(u);
            }
            setloadusers(false);
            setOptions(users);
            resolve(users);
          })
          .catch(function (error) {
            console.log(error);
            setloadusers(false);
            reject(error);
          });
      }, 2000); // 5 seconds timeout
    });
  };

  useEffect(() => {

    if (localStorage.getItem("usertype") === "farmers") {
      getallfarmers()
    } else{
      getallmillers()
    }

  }, [])




  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div className="">
      <div className="flex gap-2 flex-col">
       

        <div>
          {localStorage.getItem("usertype") === "farmers" ? <div className="flex lg:flex-row flex-col mt-4 ">
            <p className=" lg:w-1/3 capitalize text-base relative mt-4  ">
              add participants:
            </p>
            <div className="w-2/3">
              {loadusers && (
                <p className="text-xs mb-1 text-red-400">
                  Loading users...
                </p>
              )}
              <div className="w-full space-y-2 border rounded-md">


                <AyncSelectForm
                  setValues={setValues}
                  defaultOptions={options}
                  loadoptions={getallfarmers}

                />


              </div>

            </div>

          </div> : <div className="flex lg:flex-row flex-col mt-4">
            <p className=" lg:w-1/3 capitalize text-base mt-4 ">
              add participants:
            </p>
            <div className="w-2/3">
              {loadusers && (
                <p className="text-xs mb-1 text-red-400">
                  Loading users...
                </p>
              )}
              <div className=" border rounded-md">

                <AyncSelectForm
                  setValues={setValues}
                  defaultOptions={options}
                  loadoptions={getallmillers}

                />


              </div>
            </div>
          </div>

          }
        </div>





        <div className="mt-5 justify-end flex">
          <button
            onClick={(e) => {
              e.preventDefault();
              addNewMember();
            }}
            className="rounded-md text-black text-base bg-yellowcolor  py-1 flex px-4  items-center capitalize"
          >
            add
          </button>
        </div>
      </div>

    </div>
  );
};
