import React, { useState, useEffect } from "react";
import { FormInput } from "../../components";
import FormOptions from "../../components/FormOptions";
import { BiTimeFive } from "react-icons/bi";
import { Form } from "react-bootstrap";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "../../utils";
import { useHistory } from "react-router-dom";
import {
  createID,
  formatMydate,
  formatdateForSchedule,
} from "../../utils/Formatter";
import { BASE_URL } from "../../utils/api";
import { BsArrowLeft } from "react-icons/bs";

export default function Index() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [messageType, setMessageType] = useState("daily");
  const [groups, setGroups] = useState();
  const [Loading, setLoading] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [mydate, setdate] = useState();
  const [selectedWeek, setSelectedWeek] = useState();
  const history = useHistory();
  const [disableSelect,setdisableSelect]=useState(false)

  useEffect(() => {
    getAllgroups();
  }, []);

  const getAllgroups = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",

      url: `${BASE_URL}aggregator/get-all-group/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.groups);
        setGroups(response.data.groups);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const [state, setState] = useState({
    weight: "",
    quantity: "",
    harvest_type: "Outgoing",
    harvest_date: null,
    price: "",
    created_by: "me",
    sent_to: "",
    group_type: "",
    schedule_type:"",
    scheduled_time:null


  });

  function handleChange(evt) {
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const ScheduleHarvest = (id) => {
    var data = JSON.stringify({
      "harvest_id":id
    });

    var config = {
      method: "post",
      url: `${BASE_URL}sms/aggregator-harvest-sms/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        SuccessNotification("You have successfully created a harvest request");
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const createRequest = () => {
    console.log(state)
    
    if (
      state.weight === "" ||
      state.quantity === "" ||
      state.harvest_type === "" ||
      state.price === "" ||
      state.created_by === "" ||
      state.scheduled_time===null ||
      (state.sent_to === "" && state.group_type === "")
    ) {
      ErrorNotification("All fields are required");
      return;
    }
    let token = localStorage.getItem("token");

    let c = "";
    if (state.harvest_date !== null && state.schedule_type === "once") {
      c = formatdateForSchedule(state.harvest_date);
    } else if (
      state.harvest_date !== null &&
      state.schedule_type === "monthly"
    ) {
      c = formatdateForSchedule(state.harvest_date);
    } else {
      c = state.scheduled_date;
    }

    var payload = {
      harvest_id: createID(5),
      weight: state.weight,
      quantity: state.quantity,
      harvest_type: state.harvest_type,
      harvest_date: state.harvest_date,
      price: state.price,
      created_by: state.created_by,
      sent_group: state.sent_to,
      group_type: state.group_type,
      schedule_type:messageType,
      scheduled_time:state.scheduled_time,
      start_time:state.harvest_date+" "+state.scheduled_time,
      instant:false

    };

    setLoading(true);
    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/create-harvest/`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        ScheduleHarvest(response.data.harvest_id)
       
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };


  const createInstantRequest = () => {
  
    const date = new Date();
    
    if (
      state.weight === "" ||
      state.quantity === "" ||
      state.harvest_type === "" ||
      state.price === "" ||
      state.created_by === "" ||
      (state.sent_to === "" && state.group_type === "")
    ) {
      ErrorNotification("All fields are required");
      return;
    }
    let token = localStorage.getItem("token");

    var payload = {
      harvest_id: createID(5),
      weight: state.weight,
      quantity: state.quantity,
      harvest_date: date,
      price: state.price,
      created_by: state.created_by,
      sent_group: state.sent_to,
      group_type: state.group_type,
      delivered:true,
      instant:true,
      harvest_type:"Outgoing"

    };

  
    setLoading(true);
    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/create-harvest/`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
       SuccessNotification("Harvest Request created successfully")
       
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  function setGroupType(evt) {
    const value = evt.target.value;
    console.log(value);
    if (value === "allusers") {
      setState({
        ...state,
        ["group_type"]: value,
      });
    } else {
      setState({
        ...state,
        [evt.target.name]: value,
      });
    }
  }

  const msgTypes = [
    { id: "daily", type: "daily" },
    { id: "once", type: "once" },
    { id: "day_of_week", type: "day of week" },
    { id: "monthly", type: "monthly" },
  ];

  const dayofWeek = [
    { id: 1, type: "Monday" },
    { id: 2, type: "Tuesday" },
    { id: 3, type: "Wednesday" },
    { id: 4, type: "Thursday" },
    { id: 5, type: "Friday" },
    { id: 6, type: "Saturday" },
    { id: 7, type: "Sunday" },
  ];

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex lg:flex-row flex-col gap-2">
            <div className="mt-8">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
                className="rounded-sm shadow-sm text-black font-semibold text-sm bg-lightblue  py-2 flex px-4 items-center capitalize "
              >
                <BsArrowLeft className="mr-2" /> Go back
              </button>
            </div>
          </div>
          <div className="bg-white p-3 shadow-md rounded-md">
            <div className="mt-5 lg:gap-9 md:gap-4 flex flex-col lg:flex-row md:flex-row">
              <div className="lg:flex-auto lg:w-96 md:flex-auto md:w-96 w-full">
                <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
                  <p className=" lg:basis-1/4 capitalize text-base ">
                    send to:
                  </p>
                  <div className="lg:basis-10/12 flex flex-col space-y-2">
                    <div className="w-full">
                      <Form.Control
                        required
                        as="select"
                        type="select"
                        name="sent_to"
                        disabled={disableSelect}
                        onChange={handleChange}
                        // onInput={setGroupType}
                        style={{
                          backgroundColor: "white",
                          color: "#37517e",
                          border: "solid",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <option value="">
                          Choose a group to send harvest alert to
                        </option>
                        {groups?.map((m) => (
                          <option value={m.id}>{m.groupname}</option>
                        ))}
                      </Form.Control>
                      <p className="flex text-center justify-center font-bold space-y-2">
                        OR
                      </p>
                    </div>
                    <div className="w-full flex items-center space-x-2 ml-1">
                      <input
                        type="checkbox"
                        value="allusers"
                        name="group_type"
                        onInput={()=>setdisableSelect(!disableSelect)}
                        onChange={handleChange}
                        className=""
                      />
                      <label>Select all farmers</label>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
                  <p className=" lg:basis-1/4 capitalize text-base ">Weight:</p>
                  <div className="lg:basis-10/12 flex lg:items-center md:items-center lg:flex-row md:flex-row gap-0.5">
                    <div className="w-full">
                      <input
                        type="number"
                        name="weight"
                        onChange={handleChange}
                        className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                      />
                    </div>
                    <div className="w-40">
                      <Form.Control
                        required
                        name=""
                        as="select"
                        disabled="true"
                        type="select"
                        onChange={handleChange}
                        onInput={(e) => {}}
                        style={{
                          backgroundColor: "white",
                          color: "#37517e",
                          border: "solid",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        // className="capitalize"
                      >
                        <option value={"tonnes"}>ton</option>
                      </Form.Control>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
                  <p className=" lg:basis-1/4 capitalize text-base ">
                    price offer:
                  </p>
                  <div className="lg:basis-10/12 flex lg:items-center md:items-center lg:flex-row md:flex-row gap-4">
                    <div className="w-full">
                      <input
                        type="number"
                        name="price"
                        onChange={handleChange}
                        className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                      />
                    </div>
                    <div className="w-44 flex items-center gap-1">
                      <p className="capitalize text-base ">per</p>
                      <Form.Control
                        required
                        name="symbol"
                        as="select"
                        type="select"
                        disabled="true"
                        onChange={handleChange}
                        onInput={(e) => {}}
                        style={{
                          backgroundColor: "white",
                          color: "#37517e",
                          border: "solid",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        // className="capitalize"
                      >
                        <option value={"tonnes"}>ton</option>
                      </Form.Control>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
                  <p className=" lg:basis-1/4 capitalize text-base ">
                    Est. number of bunches:
                  </p>
                  <div className="lg:basis-10/12 flex lg:items-center md:items-center lg:flex-row md:flex-row gap-4">
                    <div className="w-full">
                      <input
                        type="number"
                        name="quantity"
                        onChange={handleChange}
                        className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
                <p className=" lg:basis-1/4 capitalize text-base ">
                  value offer:
                </p>
                <div className="lg:basis-10/12 flex lg:items-center md:items-center lg:flex-row md:flex-row gap-4">
                  <div className="w-full">
                    <input
                      type="number"
                      name="value"
                      onChange={handleChange}
                      className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                    />
                  </div>
                </div>
              </div> */}

                {/* <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
                <p className=" lg:basis-1/4 capitalize text-base ">Date:</p>
                <div className="lg:basis-10/12">
                  <div className="w-full">
                    <input
                      type="date"
                      name="harvest_date"
                      onChange={handleChange}
                      className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                    />
                  </div>
                </div>
              </div> */}

                {/* <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
        <p className=" lg:basis-1/4 capitalize text-base ">Repeat:</p>
        <div className="lg:basis-10/12">
          <div className="w-full">
            <Form.Control
              required
              name="message_type"
              as="select"
              type="select"
              onChange={handleChange}
              onInput={(e) => setMessageType(e.target.value)}
              style={{
                backgroundColor: "white",
                color: "#37517e",
                border: "solid",
                borderRadius: "5px",
                width: "100%",
              }}
              className="capitalize"
            >
              {msgTypes?.map((m) => (
                <option value={m.id}>{m.type}</option>
              ))}
            </Form.Control>
          </div>
        </div>
      </div> */}
              </div>
              {showDropdown ? (
                <div className="lg:flex-auto lg:w-32 md:flex-auto md:w-32 w-full ">
                  <div className="flex flex-col gap-2">
                    <p className=" lg:basis-1/4 capitalize text-base ">
                      Repeat
                    </p>
                    <div className="flex lg:flex-row flex-col gap-2">
                      {messageType === "once" && (
                        <div className="w-full">
                          <input
                            type="date"
                            name="harvest_date"
                            onChange={handleChange}
                            className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                          />
                        </div>
                      )}
                      {messageType === "day_of_week" && (
                        <div className="w-full">
                          <Form.Control
                            required
                            as="select"
                            type="select"
                            name="harvest_date"
                            value={selectedWeek}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #94a3b8",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          >
                            {dayofWeek?.map((m) => (
                              <option value={m.type}>{m.type}</option>
                            ))}
                          </Form.Control>
                        </div>
                      )}
                      {messageType === "monthly" && (
                        <div className="w-full">
                          <Form.Control
                            required
                            type="date"
                            name="harvest_date"
                            value={selectedWeek}
                            onChange={handleChange}
                            style={{
                              backgroundColor: "white",
                              border: "1px solid #94a3b8",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          ></Form.Control>
                        </div>
                      )}
                      <div className="w-full">
                        <Form.Control
                          required
                          name="schedule_type"
                          as="select"
                          type="select"
                          onChange={handleChange}
                          onInput={(e) => setMessageType(e.target.value)}
                          style={{
                            backgroundColor: "white",
                            border: "1px solid #94a3b8",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                          className="capitalize"
                        >
                          {msgTypes?.map((m) => (
                            <option value={m.id}>{m.type}</option>
                          ))}
                        </Form.Control>
                      </div>
                    </div>
                  </div>
                  {messageType === "daily" || messageType === "once" ? (
                    <div className="flex flex-col gap-2 mt-4">
                      <div className="w-full">
                        <input
                          type="time"
                          name="scheduled_time"
                          onChange={handleChange}
                          className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                        />
                      </div>
                    </div>
                  ) : messageType === "day_of_week" ? (
                    <div className="flex flex-col gap-2 mt-4">
                      <div className="w-full">
                        <input
                          type="time"
                          name="scheduled_time"
                          onChange={handleChange}
                          className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                        />
                      </div>
                    </div>
                  ) : messageType === "monthly" ? (
                    <div className="flex flex-col gap-2 mt-4">
                      <div className="w-full">
                        <input
                          type="time"
                          name="scheduled_time"
                          onChange={handleChange}
                          className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="lg:flex-auto lg:w-32 md:flex-auto md:w-32 w-full"></div>
              )}
            </div>
            <div className="flex lg:flex-row flex-col gap-2 mt-8">
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="text-black bg-gray-50 font-bold text-sm py-1 flex px-2  items-center capitalize"
              >
                <BiTimeFive size={18} color="#FBBC05" />
                <p className="text-sm ml-2 text-yellowcolor">
                  {showDropdown ? "Cancel scheduling" : "schedule Harvest"}
                </p>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  {
                    showDropdown ? createRequest() : createInstantRequest();
                  }
                }}
                className="rounded-sm shadow-sm text-black font-semibold text-sm bg-yellowcolor  py-2 flex px-4  items-center capitalize"
              >
                {Loading
                      ? "Creating"
                      : showDropdown
                      ? "Schedule Harvest Request"
                      : "Send Harvest Request"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
