import React from "react";
import { GrPowerReset } from "react-icons/gr";

const SearchFIlter = ({
  onChange,
  value,
  onClick,
  setQuery,
  SearchAggregator,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by email, firstname, lastname"
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => SearchAggregator()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun("run")}
          size={20}
        />
      </div>
    </div>
  );
};

export default SearchFIlter;
