import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { IoMdAddCircleOutline, IoMdEye } from "react-icons/io";
import { BiFilter } from "react-icons/bi";
import SearchFIlter from "../../components/SearchFIlter";
import noharvest from "../../assets/noharvest.png";
import { BsFillPlayFill } from "react-icons/bs";

import { GrPowerReset } from "react-icons/gr";
import moment from "moment";

const Index = () => {
  const history = useHistory();
  const [harvests, setHarvests] = useState([
    {
      id: 1,
      receiver: "John Doe",
      commodity: "Oil Palm",
      weight: "1000 tons",
      response: "8/21",
      date: new Date(),
    },
    {
      id: 1,
      receiver: "John Doe",
      commodity: "Oil Palm",
      weight: "1000 tons",
      response: "8/21",
      date: new Date(),
    },
    {
      id: 1,
      receiver: "John Doe",
      commodity: "Oil Palm",
      weight: "1000 tons",
      response: "8/21",
      date: new Date(),
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getAllHarvestRequest = () => {
    let token = localStorage.getItem("token");
  };

  useEffect(() => {
    getAllHarvestRequest();
  }, []);

  const handleNext = () => {
    if (harvests.next) {
      setCurrentPage(currentPage + 1);
      getAllHarvestRequest();
    }
  };

  const handlePrev = () => {
    if (harvests.previous) {
      setCurrentPage(currentPage - 1);
      getAllHarvestRequest();
    }
  };

  const handleRowClick = (row) => {
    history.push("/admin/harvest-request-detail", { state: row });
  };

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex items-center mb-1">
            <p className="font-bold text-base">FIlter: </p>
            <BiFilter size={30} />
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2">
            <div className="lg:mb-0 mb-2">
              <SearchFilter />
            </div>
            <Filter />
          </div>
          {harvests?.length < 1 ? (
            <EmptyHarvest />
          ) : (
            <div className="my-5">
              {loading ? (
                <div className="h-96 grid items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <table class="w-full text-xs text-center bg-white">
                    <thead class="text-xs  text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" class="py-3 px-3">
                          ID
                        </th>
                        <th scope="col" class="py-3 px-3">
                          to
                        </th>
                        <th scope="col" class="py-3 px-3">
                          commodity
                        </th>
                        <th scope="col" class="py-3 px-3">
                          weight requested
                        </th>
                        <th scope="col" class="py-3 px-3">
                          responses
                        </th>
                        <th scope="col" class="py-3 px-3">
                          requested date
                        </th>
                        <th scope="col" class="py-3 px-3">
                          actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {harvests &&
                        harvests?.map((data, index) => (
                          <tr
                            key={index}
                            className={`${
                              index % 2 === 0
                                ? "bg-white border-b dark:border-gray-200"
                                : "bg-slate-100 border-b"
                            } hover:bg-gray-100 capitalize`}
                          >
                            <th
                              scope="row"
                              class="py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {data.id}
                            </th>
                            <td class="py-4 px-3">{data?.receiver}</td>
                            <td class="py-4 px-3">{data?.commodity}</td>
                            <td class="py-4 px-3">{data?.weight}</td>

                            <td class="py-4 px-3">{data?.response}</td>

                            <td class="py-4 px-3">
                              {moment(data?.date).format("MMM Do YY")}
                            </td>

                            <td class="py-4 px-3">
                              <div className="flex items-center gap-4 justify-center">
                                <button onClick={() => handleRowClick(data)}>
                                  <AiFillEye size={15} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          {harvests?.length > 1 && (
            <div className="mt-5">
              <div class="flex flex-col items-center">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                  Showing{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {harvests?.length}
                  </span>{" "}
                  to{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {harvests?.length}
                  </span>{" "}
                  of{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {count}
                  </span>{" "}
                  Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button
                    onClick={() => handlePrev()}
                    disabled={harvests?.previous === null}
                    class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      class="mr-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Prev
                  </button>
                  <button
                    onClick={() => handleNext()}
                    disabled={harvests?.next === null}
                    class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                    <svg
                      aria-hidden="true"
                      class="ml-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const SearchFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searcFarmers,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by name, commodity, weight..."
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searcFarmers()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            setQuery("");
            setRun("run");
          }}
          size={20}
        />
      </div>
    </div>
  );
};

const Filter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filterharvest,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <select
            name="community"
            onChange={handleChange}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {community?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filterharvest()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun("run")}
          size={20}
        />
      </div>
    </div>
  );
};

const EmptyHarvest = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noharvest} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No record found</h1>
      </div>
    </div>
  );
};

export default Index;
