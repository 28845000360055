import React, { useState, useEffect, useMemo } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { GiBarn, GiTreeFace } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import MapComponent from "../../components/MapComponent";

export default function AggregatorFarmDetail(props) {
  const history = useHistory();
  const farmDetail = props.location.state;
  const [loading, setLoading] = useState(false);
  

  const location = {
    address: farmDetail.state.community,
    lat: parseFloat(farmDetail.state.latitude),
    lng: parseFloat(farmDetail.state.longitude),
  };

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>

        <div className="bg-white capitalize px-4 py-2 mt-5 sm:rounded-sm border-2 border-gray-300 shadow-md">
          <div className="py-2 border-b border-gray-100s">
            <h2 className="text-base font-bold capitalize">farm details</h2>
          </div>
          <div className="py-4 mx-0 lg:mx-10">
            <div className="my-2 grid lg:grid-cols-2 sm:grid-cols-2">
              <div className="flex gap-4 lg:flex-col md:flex-col">
                <div className="flex my-2">
                  <GiBarn size={20} />

                  <div className="ml-2">
                    <p className="text-base font-bold">Farm</p>
                    <div className="mt-2">
                      <p className="text-sm">
                        {" "}
                        {`${farmDetail?.state.firstname} ${farmDetail?.state.lastname}`}
                      </p>
                      <p className="text-sm">{farmDetail?.state.email}</p>
                      <p className="text-sm">{farmDetail?.state.phone}</p>
                    </div>
                  </div>
                </div>

                <div className="flex mt-2  lg:mt-4  md:mt-4">
                  <MdLocationOn size={20} />
                  <div className="ml-2">
                    <p className="text-base font-bold">Location</p>
                    <div className="mt-2">
                      <p className="text-sm">{farmDetail?.state.district}</p>
                      <p className="text-sm">{farmDetail?.state.community}</p>
                      <p className="text-sm">{farmDetail?.state.region}</p>
                    </div>
                  </div>
                </div>

                <div className="flex mt-2 lg:mt-4  md:mt-4">
                  <GiTreeFace size={20} />
                  <div className="ml-2">
                    <p className="text-base font-bold">Crop</p>
                    <div className="mt-2">
                      <p className="text-sm">{farmDetail?.state.crop.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 lg:mt-0 sm:mt-0">
                <p className="text-base font-bold mb-2">Map location</p>
             
                <MapComponent location={location} zoomLevel={13} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
