import React from "react";
import { BiFilter } from "react-icons/bi";
import { GrDocumentPdf } from "react-icons/gr";
import { VscFilePdf } from "react-icons/vsc";
import { DashboardFilter } from "../../components";
import ReportFIlter from "./ReportFIlter";
import doc from "../../assets/doc.png";
import pdf from "../../assets/pdf.png";
import xls from "../../assets/xls.png";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import noReport from "../../assets/noReport.svg";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { CSVLink } from "react-csv";
import { formatDateFull } from "../../utils/Formatter";
import { ErrorNotification } from "../../utils";
import { Redirect,useLocation} from "react-router-dom";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [selectedItem, setSelectedItem] = useState("order");
  const items = ["order", "aggregator", "harvest"];

  const initialState = {
    region: "",
    count: "",
    status: "",
  };
  const [states, setState] = useState(initialState);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const getallReports = (value) => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      item: value,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}miller/get-all-reports/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setReports(response.data.report);
       
        setLoading(false);
      })
      .catch(function (error) {
        ErrorNotification("There was an error getting reports")
        console.log(error);
        setLoading(false);
      });
  };

  const ordersCsvHeader = [
    { label: "Id", key: "id" },
    { label: "Status", key: "status" },
    { label: "Aggregator Firstname", key: "aggregator_user.firstname" },
    { label: "Aggregator Lastname", key: "aggregator_user.lastname" },
    { label: "Phone nuumber", key: "aggregator_user.phone" },
    { label: "Email", key: "aggregator_user.email" },
    { label: "Community", key: "aggregator_user.community" },
    { label: "Region", key: "aggregator_user.region" },
    { label: "District", key: "aggregator_user.district" },
    { label: "Date Created", key: "datecreated" },
  ];

  const aggregatorCsvHeader = [
    { label: "Id", key: "id" },
    { label: "Status", key: "status" },
    { label: "Aggregator Firstname", key: "aggregator_user.firstname" },
    { label: "Aggregator Lastname", key: "aggregator_user.lastname" },
    { label: "Phone nuumber", key: "aggregator_user.phone" },
    { label: "Email", key: "aggregator_user.email" },
    { label: "Community", key: "aggregator_user.community" },
    { label: "Region", key: "aggregator_user.region" },
    { label: "District", key: "aggregator_user.district" },
    { label: "Date Created", key: "datecreated" },
  ];

  const harvestCsvHeader = [
    { label: "Id", key: "id" },
    { label: "harvest id", key: "harvest_id" },
    { label: "Aggregator Firstname", key: "aggregator.firstname" },
    { label: "Aggregator Lastname", key: "aggregator.lastname" },
    { label: "Weight", key: "weight" },
    { label: "Quantity", key: "quantity" },
    { label: "Value (GHC)", key: "value" },
    { label: "Status", key: "status" },
    { label: "Phone nuumber", key: "aggregator.phone" },
    { label: "Email", key: "aggregator.email" },
    { label: "Community", key: "aggregator.community" },
    { label: "Region", key: "aggregator.region" },
    { label: "District", key: "aggregator.district" },
    { label: "Date Created", key: "datecreated" },
  ];

  const csvLink = {
    filename: "report.csv",
    headers:
      selectedItem === "orders"
        ? ordersCsvHeader
        : selectedItem === "aggregator"
        ? aggregatorCsvHeader
        : harvestCsvHeader,
    data: reports,
  };

  const handleReportSearch = () => {
    setLoading(true);
    const data = reports.filter((report) => {
      return (
        report.aggregator_user.region === states.region ||
        report.status === states.status
      );
    });

    setReports(data);
    setLoading(false);
  };

  useEffect(() => {
    getallReports("order");
  }, []);

  const { state } = useLocation();

  const user=JSON.parse(localStorage.getItem("user"))
  if(user.isfirstTime){
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="w-full">
          <div className="mb-2">
            <select
              name="report"
              id="report"
              value={selectedItem}
              onChange={(e) => {
                setSelectedItem(e.target.value);
                getallReports(e.target.value);
              }}
              className="lg:w-32 md:w-32 w-full font-semibold capitalize bg-yellowcolor text-base px-3 py-2 mb-3 leading-tight  border-none rounded"
            >
              {items.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          {loading ? (
            <div className="h-96 grid items-center justify-center">
              <Spinner />
            </div>
          ) : reports && reports?.length > 0 ? (
            <>
              <div className="lg:flex md:flex items-center lg:justify-between md:justify-between mb-2">
                <div className="flex items-center mb-1">
                  <p className="font-bold text-base">FIlter: </p>
                  <BiFilter size={30} />
                </div>
                <div className="flex items-center">
                  <CSVLink {...csvLink}>
                    <button type="button" className="text-red-500">
                      <img src={xls} alt="doc" className="w-6 h-6" />
                    </button>
                  </CSVLink>
                  <CSVLink {...csvLink}>
                    <button type="button" className="mx-2 text-red-700">
                      <img src={pdf} alt="doc" className="w-6 h-6" />
                    </button>
                  </CSVLink>
                  <CSVLink {...csvLink}>
                    <button type="button" className=" text-red-500">
                      <img src={doc} alt="doc" className="w-6 h-6" />
                    </button>
                  </CSVLink>
                </div>
              </div>
              <ReportFIlter
                state={states}
                regions={regions}
                handleChange={handleChange}
                setRun={() => getallReports("order")}
                handleReportSearch={handleReportSearch}
              />
              <div className="my-5">
                <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  {selectedItem === "order" ? (
                    <table class="w-full text-xs text-left bg-white">
                      <thead class="text-xs text-gray-700 capitalize bg-white">
                        <tr>
                          <th scope="col" class="py-3 px-6">
                            <input
                              type={"checkbox"}
                              className="border-2 mr-5 border-gray-700"
                            />
                            ID
                          </th>
                          <th scope="col" class="py-3 px-6">
                            full name
                          </th>
                          <th scope="col" class="py-3 px-6">
                            phone number
                          </th>
                          <th scope="col" class="py-3 px-6">
                            region
                          </th>
                          <th scope="col" class="py-3 px-6">
                            price(GHS)
                          </th>
                          <th scope="col" class="py-3 px-6">
                            quantity
                          </th>
                          <th scope="col" class="py-3 px-6">
                            orderd_on
                          </th>
                          <th scope="col" class="py-3 px-6">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports &&
                          reports?.map((data, index) => (
                            <tr
                              key={index}
                              onClick={() => {}}
                              class={`${
                                index % 2 === 0
                                  ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400 cursor-pointer"
                                  : "bg-slate-100 border-b hover:bg-gray-100 cursor-pointer"
                              }`}
                            >
                              <th
                                scope="row"
                                class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="border-2 mr-5 border-gray-700"
                                />
                                {data?.id}
                              </th>
                              <td class="py-4 px-6">{`${data?.aggregator.firstname} ${data?.aggregator.lastname}`}</td>
                              <td class="py-4 px-6">
                                {data?.aggregator.phone}
                              </td>
                              <td class="py-4 px-6">
                                {data?.aggregator.region}
                              </td>
                              <td class="py-4 px-6">
                                {data?.order.order_price}
                              </td>

                              <td class="py-4 px-6">
                                {data?.order.order_quantity}
                              </td>

                              <td class="py-4 px-6">
                                {/* {moment(data?.order.order_date).format(
                                  "DD/MM/YYYY"
                                )} */}
                                {formatDateFull(data?.order_date)}
                              </td>
                              <td class="py-4 px-6">
                                {data?.order.order_status === "Recieved" ? (
                                  <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.order.order_status}
                                  </span>
                                ) : data?.order.order_status === "Fulfilled" ? (
                                  <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.order.order_status}
                                  </span>
                                ) : data?.order.order_status === "Available" ? (
                                  <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.order.order_status}
                                  </span>
                                ) : (
                                  <span className="bg-red-400 p-1 rounded-md font-semibold px-4 text-sm">
                                    {data?.order.order_status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : selectedItem === "aggregator" ? (
                    <table class="w-full text-xs text-left bg-white">
                      <thead class="text-xs text-gray-700 capitalize bg-white">
                        <tr>
                          <th scope="col" class="py-3 px-6">
                            <input
                              type={"checkbox"}
                              className="border-2 mr-5 border-gray-700"
                            />
                            ID
                          </th>
                          <th scope="col" class="py-3 px-6">
                            aggregator
                          </th>
                          <th scope="col" class="py-3 px-6">
                            phone number
                          </th>
                          <th scope="col" class="py-3 px-6">
                            region
                          </th>
                          <th scope="col" class="py-3 px-6">
                            community
                          </th>
                          <th scope="col" class="py-3 px-6">
                            date
                          </th>
                          <th scope="col" class="py-3 px-6">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports &&
                          reports?.map((data, index) => (
                            <tr
                              key={index}
                              onClick={() => {}}
                              class={`${
                                index % 2 === 0
                                  ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400 cursor-pointer"
                                  : "bg-slate-100 border-b hover:bg-gray-100 cursor-pointer"
                              }`}
                            >
                              <th
                                scope="row"
                                class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="border-2 mr-5 border-gray-700"
                                />
                                {data?.id}
                              </th>
                              <td class="py-4 px-6">{`${data?.aggregator_user?.firstname} ${data?.aggregator_user?.lastname}`}</td>
                              <td class="py-4 px-6">
                                {data?.aggregator_user?.phone}
                              </td>
                              <td class="py-4 px-6">
                                {data?.aggregator_user?.region}
                              </td>
                              <td class="py-4 px-6">
                                {data?.aggregator_user?.community}
                              </td>
                              <td class="py-4 px-6">
                                {moment(data?.datecreated).format("DD/MM/YYYY")}
                              </td>
                              <td class="py-4 px-6">
                                {data?.status === "active" ? (
                                  <span className="bg-green-400 capitalize p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.status}
                                  </span>
                                ) : data?.status === "Fulfilled" ? (
                                  <span className="bg-blue-400 capitalize p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.status}
                                  </span>
                                ) : data?.status === "Available" ? (
                                  <span className="bg-blue-400 p-1 capitalize px-4 rounded-md font-semibold text-sm">
                                    {data?.status}
                                  </span>
                                ) : (
                                  <span className="bg-red-400 p-1 capitalize rounded-md font-semibold px-4 text-sm">
                                    {data?.status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : selectedItem === "harvest" ? (
                    <table class="w-full text-xs text-left bg-white">
                      <thead class="text-xs text-gray-700 capitalize bg-white">
                        <tr>
                          <th scope="col" class="py-3 px-6">
                            <input
                              type={"checkbox"}
                              className="border-2 mr-5 border-gray-700"
                            />
                            harvest ID
                          </th>
                          <th scope="col" class="py-3 px-6">
                            aggregator
                          </th>
                          <th scope="col" class="py-3 px-6">
                            phone number
                          </th>
                          <th scope="col" class="py-3 px-6">
                            weight
                          </th>
                          <th scope="col" class="py-3 px-6">
                            quantity
                          </th>
                          <th scope="col" class="py-3 px-6">
                            price(GHS)
                          </th>
                          <th scope="col" class="py-3 px-6">
                            date
                          </th>
                          <th scope="col" class="py-3 px-6">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports &&
                          reports?.map((data, index) => (
                            <tr
                              key={index}
                              onClick={() => {}}
                              class={`${
                                index % 2 === 0
                                  ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400 cursor-pointer"
                                  : "bg-slate-100 border-b hover:bg-gray-100 cursor-pointer"
                              }`}
                            >
                              <th
                                scope="row"
                                class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="border-2 mr-5 border-gray-700"
                                />
                                {data?.harvest_id}
                              </th>
                              <td class="py-4 px-6">{`${data?.aggregator?.firstname} ${data?.aggregator?.lastname}`}</td>
                              <td class="py-4 px-6">
                                {data?.aggregator?.phone}
                              </td>
                              <td class="py-4 px-6">{data?.weight}</td>
                              <td class="py-4 px-6">{data?.quantity}</td>
                              <td class="py-4 px-6">{data?.price}</td>
                              <td class="py-4 px-6">
                                {moment(data?.datecreated).format("MMM Do YY")}
                              </td>
                              <td class="py-4 px-6">
                                {data?.status === "Recieved" ? (
                                  <span className="bg-green-400 capitalize p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.status}
                                  </span>
                                ) : data?.status === "Ordered" ? (
                                  <span className="bg-blue-400 capitalize p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.status}
                                  </span>
                                ) : data?.status === "Available" ? (
                                  <span className="bg-blue-400 capitalize  p-1 px-4 rounded-md font-semibold text-sm">
                                    {data?.status}
                                  </span>
                                ) : (
                                  <span className="bg-red-400 capitalize p-1 rounded-md font-semibold px-4 text-sm">
                                    {data?.status}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <>
              <ReportFIlter
                state={states}
                regions={regions}
                handleChange={handleChange}
                setRun={getallReports}
                handleReportSearch={handleReportSearch}
              />
              <EmpytReports />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const EmpytReports = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noReport} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No report information found</h1>
      </div>
    </div>
  );
};

export default Index;
