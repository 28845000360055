import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import "react-h5-audio-player/lib/styles.css";
import { useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import { BsPerson } from "react-icons/bs";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Form } from "react-bootstrap";

const HarvestDetailView = (props) => {
  const history = useHistory();
  const request = props.location.state;
  const [loading, setLoading] = useState(true);
  const [harvests, setHarvests] = useState({});
  const [open, setOpen] = React.useState(false);

  const getHarvestDetail = () => {
    setLoading(true);
    var axios = require("axios");
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/get-harvests-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setHarvests(response.data.harvests);
        console.log(response.data.harvests);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getHarvestDetail();
  }, []);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="w-96">
          <h1 className="capitalize font-bold text-base">
            Order for harvest #{harvests?.harvest_id}
          </h1>
          <div className="my-7" />
          <div className="lg:flex-auto md:flex-auto w-full">
            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">
                Message Type:
              </p>
              <div className="w-full">
                <div className="w-full">
                  <Form.Control
                    required
                    name="campaign_type"
                    as="select"
                    type="select"
                    onChange={() => {}}
                    style={{
                      backgroundColor: "white",
                      color: "#37517e",
                      borderColor: "#94a3b8",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    className="capitalize"
                  >
                    <option value="0">Select</option>
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">send to:</p>
              <div className="w-full">
                <div className="w-full">
                  <Form.Control
                    required
                    as="select"
                    type="select"
                    name="recipients"
                    onChange={() => {}}
                    style={{
                      backgroundColor: "white",
                      color: "#37517e",
                      borderColor: "#94a3b8",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    disabled={true}
                    editable={false}
                  >
                    <option value="">{harvests?.harvestuser?.phone}</option>
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">Weight:</p>
              <div className="w-full flex lg:items-center md:items-center lg:flex-row md:flex-row gap-0.5">
                <div className="w-full">
                  <input
                    type="number"
                    name="weight"
                    onChange={() => {}}
                    className="w-full rounded-md border border-slate-400 px-2 py-2 outline-none"
                  />
                </div>
                <div className="w-40">
                  <Form.Control
                    required
                    name="symbol"
                    as="select"
                    type="select"
                    onChange={() => {}}
                    onInput={(e) => {}}
                    style={{
                      backgroundColor: "white",
                      color: "#37517e",
                      borderColor: "#94a3b8",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    className="capitalize"
                  >
                    <option value={"kg"}>kg</option>
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">
                price offer:
              </p>
              <div className="w-full flex lg:items-center md:items-center lg:flex-row md:flex-row gap-4">
                <div className="w-full">
                  <input
                    type="number"
                    name="weight"
                    onChange={() => {}}
                    className="w-full rounded-md border border-slate-400 px-2 py-2 outline-none"
                  />
                </div>
                <div className="w-44 flex items-center gap-1">
                  <p className="capitalize text-base ">per</p>
                  <Form.Control
                    required
                    name="symbol"
                    as="select"
                    type="select"
                    onChange={() => {}}
                    onInput={(e) => {}}
                    style={{
                      backgroundColor: "white",
                      color: "#37517e",
                      borderColor: "#94a3b8",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    className="capitalize"
                  >
                    <option value={"kg"}>kg</option>
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">Date:</p>
              <div className="w-full">
                <div className="w-full">
                  <input
                    type="date"
                    name="scheduled_date"
                    onChange={() => {}}
                    className="w-full rounded-md border border-slate-400 px-2 py-2 outline-none"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">Time:</p>
              <div className="w-full">
                <div className="w-full">
                  <input
                    type="time"
                    name="scheduled_date"
                    onChange={() => {}}
                    className="w-full rounded-md border border-slate-400 px-2 py-2 outline-none"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:items-center md:items-center lg:flex-row md:flex-row gap-2 mt-4">
              <p className=" lg:basis-1/4 capitalize text-base ">Repeat:</p>
              <div className="w-full">
                <div className="w-full">
                  <Form.Control
                    required
                    name="message_type"
                    as="select"
                    type="select"
                    onChange={() => {}}
                    onInput={(e) => {}}
                    style={{
                      backgroundColor: "white",
                      color: "#37517e",
                      borderColor: "#94a3b8",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    className="capitalize"
                  ></Form.Control>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col gap-2">
              <div className="mt-8"></div>
            </div>
          </div>
          <div className="my-3" />
          <div className="flex items-end justify-end">
            <button className="bg-yellowcolor text-sm px-4 py-2 rounded-md hover:bg-yellow-300">
              Order Harvest
            </button>
          </div>
        </div>
      </Modal>

      <div className="w-full lg:px-6 px-2 h-full py-6">
        <button onClick={() => history.goBack()} className="mb-5">
          <IoMdArrowRoundBack size={25} />
        </button>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="bg-white shadow-md sm:rounded-md ">
              <div className="flex items-center px-5 justify-between">
                <p className="font-bold py-5  text-base capitalize mb-1">
                  Harvest: {harvests?.harvest_id}
                </p>
                <div className="my-2 capitalize">
                  {harvests?.status === "received" ? (
                    <span className="bg-green-400 capitalize p-1 px-4 rounded-md font-semibold text-sm text-white">
                      {harvests?.status}
                    </span>
                  ) : harvests?.status === "fulfilled" ? (
                    <span className="bg-blue-400 capitalize p-1 px-4 rounded-md font-semibold text-sm text-white">
                      {harvests?.status}
                    </span>
                  ) : harvests?.status === "published" ? (
                    <span className="bg-yellow-400 p-1 px-4 rounded-md font-semibold text-sm text-white">
                      {harvests?.status}
                    </span>
                  ) : (
                    <span className="bg-zinc-400 p-1 capitalize rounded-md font-semibold px-4 text-sm text-white">
                      {harvests?.status}
                    </span>
                  )}
                </div>
              </div>
              <div className="h-0.5 w-full bg-gray-500" />
              <div className="px-5">
                <p className="font-bold py-5  text-sm capitalize mb-1">
                  ID: {harvests?.harvest_id}
                </p>
                <div className="flex flex-col lg:flex-row md:flex-row my-2">
                  <BsPerson size={22} />
                  <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                    <p className="font-bold text-sm capitalize mb-2">
                      {harvests?.harvestuser?.firstname}
                    </p>
                    <p className="font-light text-sm capitalize">
                      {harvests?.harvestuser?.firstname +
                        " " +
                        harvests?.harvestuser?.lastname}
                    </p>
                    <p className="font-light text-sm">
                      {harvests?.harvestuser?.email}
                    </p>
                    <p className="font-light text-sm capitalize">
                      {harvests?.harvestuser?.phone}
                    </p>
                  </div>
                </div>
                {/* table */}
                <div className="my-10">
                  <div class="overflow-x-auto relative">
                    <table class="w-full text-sm text-left ">
                      <thead class="text-xs text-gray-700 capitalize bg-slate-100">
                        <tr>
                          <th scope="col" class="py-3 px-6">
                            harvest id
                          </th>
                          <th scope="col" class="py-3 px-6">
                            price/ton
                          </th>

                          <th scope="col" class="py-3 px-6">
                            weight/ton
                          </th>
                          <th scope="col" class="py-3 px-6">
                            quantity
                          </th>
                          <th scope="col" class="py-3 px-6">
                            value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="bg-white">
                          <th
                            scope="row"
                            class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {harvests?.id}
                          </th>
                          <td class="py-4 px-6">{harvests?.value}</td>
                          <td class="py-4 px-6">{harvests?.weight}</td>
                          <td class="py-4 px-6">{harvests?.quantity}</td>
                          <td class="py-4 px-6">{harvests?.value}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="my-10 grid lg:justify-end md:justify-end">
                      <p className="font-semibold text-base capitalize">
                        Total value&nbsp;&nbsp;&nbsp; GHS {harvests?.value}
                      </p>
                      {harvests?.status === "Available" && (
                        <button
                          onClick={onOpenModal}
                          className="bg-amber-400 mt-4 py-1 rounded-md text-base font-medium capitalize"
                        >
                          <p>order</p>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HarvestDetailView;
