import React from "react";
import { IoMdArrowRoundBack, IoMdTime } from "react-icons/io";
import { AiTwotoneCalendar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { formatDateFull, GetTimeFromDate } from "../../utils/Formatter";

export default function SMSDetail(props) {
  const history = useHistory();
  const request = props.location.state;

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      <div className="bg-white py-5 rounded-md border-2  shadow-md">
        <div className="border-b-2 border-gray-600 lg:px-7 px-5S ">
          <h1 className="capitalize text-sm font-bold">Campaign detail</h1>
          <h1 className="capitalize text-sm text-gray-700 py-1">
            details for Campaign ID: {request.state.id}
          </h1>
        </div>
        <div className="lg:my-10 lg:px-7  my-7 px-5 grid lg:grid-cols-4 gap-5 mb-6">
          <div className="flex flex-row sm:my-3">
            <AiTwotoneCalendar size={25} />
            <div className="ml-2">
              {request.state.sent_date ? (
                formatDateFull(request.state.sent_date)
              ) : (
                <>
                  {request.state.scheduled_date === null
                    ? "Not available"
                    : formatDateFull(request.state.scheduled_date)}
                </>
              )}

              <p className="capitalize text-base">SMS ID: {request.state.id}</p>
            </div>
          </div>
          <div className="flex flex-row sm:my-3">
            <IoMdTime size={25} />
            <div className="ml-2">
              <p className="capitalize text-base">
                {request.state.sent_date
                  ? GetTimeFromDate(request.state.sent_date)
                  : request.state.scheduled_time}
              </p>
            </div>
          </div>
          <div className=" sm:my-3">
            <p className="capitalize text-base">
              type: {request.state.campaign_type}
            </p>
          </div>
          <div className=" sm:my-3">
            <p className="capitalize text-base">
              To: {request.state.recipientGroup}
            </p>
          </div>
        </div>
        <div className="mt-5 lg:px-7 px-5">
          <p className="capitalize text-sm font-bold">message content</p>
          <div className="mt-5 w-full bg-gray-200 p-5 rounded-sm">
            <p className="capitalize text-sm font-semibold w-96 leading-6">
              {request.state.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
