import React, { useState, useEffect, useMemo } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { GiBarn, GiTreeFace } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import MapComponent from "../../components/MapComponent";

export default function MillerFarmDetail(props) {
  const history = useHistory();
  const farmDetail = props.location.state;
  const [loading, setLoading] = useState(false);
  console.log(farmDetail?.state)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCAAdM-WGYkgKZ7_x4vGEHC5zZ3P-TQ6eU",
  });

  const location = {
    address: farmDetail.state.community,
    lat: 5.66928,
    lng: -0.18214 ,
  };

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>

        <div className="bg-white capitalize px-4 py-2 mt-5 sm:rounded-sm border-2 border-gray-300 shadow-md">
          <div className="py-2 border-b border-gray-100s">
            <h2 className="text-base font-bold capitalize">farm details</h2>
          </div>
          <div className="py-4 mx-0 lg:mx-10">
            <div className="my-2 grid lg:grid-cols-2 sm:grid-cols-2">
              <div>
                <div className="flex my-2">
                  <GiBarn size={20} />

                  <div className="ml-2">
                    <p className="text-base font-bold">Farm</p>
                    <div className="mt-2">
                      <p className="text-sm">
                        {" "}
                        {`${farmDetail?.state.firstname} ${farmDetail?.state.lastname}`}
                      </p>
                      <p className="text-sm">{farmDetail?.state.email}</p>
                      <p className="text-sm">{farmDetail?.state.phone}</p>
                    </div>
                  </div>
                </div>

                <div className="flex  mt-4">
                  <MdLocationOn size={20} />
                  <div className="ml-2">
                    <p className="text-base font-bold">Location</p>
                    <div className="mt-2">
                      <p className="text-sm">{farmDetail?.state.district}</p>
                      <p className="text-sm">{farmDetail?.state.community}</p>
                      <p className="text-sm">{farmDetail?.state.region}</p>
                    </div>
                  </div>
                </div>

                <div className="flex  mt-4">
                  <GiTreeFace size={20} />
                  <div className="ml-2">
                    <p className="text-base font-bold">Crop</p>
                    <div className="mt-2">
                      <p className="text-sm">{farmDetail?.state.crop.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 lg:mt-0 sm:mt-0">
                <p className="text-base font-bold mb-2">Map location</p>
                {/* <div className="h-72 w-full">
                  <iframe
                    title="map"
                    src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d397${farmDetail?.state.latitude}!2d${farmDetail?.state.longitude}!3d${farmDetail?.state.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTHCsDQ2JzU0LjkiTiA3N8KwMjUnMjguOSJF!5e0!3m2!1sen!2ske!4v1622540000000!5m2!1sen!2ske`}
                    width="100%"
                    height="100%"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div> */}
                {!isLoaded ? (
                  <Spinner />
                ) : (
                  <MapComponent location={location} zoomLevel={13} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
