import React from "react";
import { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import TableRows from "./TableRows";
import { BsPlusCircle } from "react-icons/bs";
import api from "../../../../utils/api";
import { SuccessNotification, ErrorNotification } from "../../../../utils";

const NewDistrict = (props) => {
  const history = useHistory();
  const [district, setDistrict] = useState("");
  const [region, setRegion] = useState("");
  const [rowsData, setRowsData] = useState([]);

  const addTableRows = () => {
    const rowsInput = {
      community: "",
    };
    setRowsData((rowsData) => [...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const addDistrict = async (name) => {
    let communities = [];

    for (var i = 0; i < rowsData.length; i++) {
      communities.push(rowsData[i].community)
    }

    let payload =
    {
      "region": region,
      "name": district,
      "communities": communities

    }

    try {
      const data = await api.post(`auth/add-district/`, JSON.stringify(payload));
      setDistrict("")
      setRowsData([])
      SuccessNotification("District added successfully")

    } catch (error) {
      ErrorNotification("An error occured while adding district")
      console.log(error.message);
    }
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <button onClick={() => history.goBack()} className="mb-">
        <IoMdArrowRoundBack size={25} />
      </button>

      <div className="bg-white sm:rounded-sm border-2 border-gray-100 shadow-sm p-5 my-5">
        <div className="border-b border-gray-300 mb-2">
          <h1 className="capitalize font-bold text-base">add new district</h1>
        </div>
        <div>
          <div className="py-2">
            <div className="w-full flex flex-col gap-3 lg:flex-row lg:items-center mb-4">
              <label className="text-gray-700 font-semibold lg:w-36">
                Region:
              </label>
              <select
                onChange={(e) => setRegion(e.target.value)}
                className="border-2 capitalize border-gray-300 rounded-lg p-2 w-full lg:w-72"
              >
                {regions?.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full flex flex-col gap-3 lg:flex-row lg:items-center mb-2">
              <label className="text-gray-700 font-semibold lg:w-36">
                District:
              </label>
              <input
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                editable={!region ? false : true}
                placeholder={
                  !region ? "Select a Region" : "Enter district name"
                }
                className="border-2 capitalize border-gray-300 rounded-lg p-2 w-full lg:w-72"
              />
            </div>
            <div className="w-full flex flex-col gap-3 lg:flex-row mb-2">
              <label className="text-gray-700 font-semibold lg:w-36">
                Community:
              </label>
              <div>
                <TableRows
                  rowsData={rowsData}
                  deleteTableRows={deleteTableRows}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <button onClick={(e) => addTableRows()}>
              <BsPlusCircle />
            </button>
            <div className="mt-10">
              <div className="grid gap-2 md:px-10 lg:px-10 lg:flex md:flex lg:flex-row lg:justify-end items-center mt-10">
                <button className="bg-red-600 px-16 py-1 capitalize text-black rounded-md shadow-md">
                  <p>cancel</p>
                </button>
                <div className="mx-4" />
                <button
                  onClick={(e) => addDistrict()}
                  className="bg-amber-400 px-16 py-1 capitalize text-black rounded-md shadow-md"
                >
                  <p>{"Save"}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDistrict;
