import React, { useState, useEffect } from "react";
import { BsPerson } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillEye } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";

export default function OrderDetail(props) {
  const history = useHistory();
  const request = props.location.state;
  const [orderdetail, setorderDetail] = useState();
  const [loading, setLoading] = useState(true);

  const getOrderDetail = () => {
    setLoading(true);
    var axios = require("axios");
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-order-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setorderDetail(response.data.order);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <div className="w-full lg:px-20 px-5 h-full py-10">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="bg-white sm:rounded-sm border-2 border-gray-300 shadow-md">
          <div className="flex items-center px-5 justify-between">
            <p className="font-bold py-5  text-base capitalize mb-1">
              order {request.state.id}
            </p>
            {request.state.status === "Available" ? (
              <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm text-white">
                {request.state.order_status}
              </span>
            ) : request.state.status === "Fulfilled" ? (
              <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm text-white">
                {request.state.order_status}
              </span>
            ) : (
              <span className="bg-zinc-400 p-1 rounded-md font-semibold px-4 text-sm text-white">
                {request.state.order_status}
              </span>
            )}
          </div>
          <div className="h-0.5 w-full bg-gray-500" />
          <div className="px-5">
            <div className="flex flex-col lg:flex-row md:flex-row">
              <div className="flex flex-col lg:flex-row md:flex-row my-2">
                <BsPerson size={22} />
                <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                  <p className="font-bold text-sm capitalize mb-2">
                    {orderdetail?.usertype}
                  </p>
                  <p className="font-light text-sm capitalize">
                    {orderdetail?.aggregator.firstname +
                      " " +
                      orderdetail?.aggregator.lastname}
                  </p>
                  <p className="font-light text-sm ">
                    {" " + orderdetail?.aggregator.email}
                  </p>
                  <p className="font-light text-sm capitalize">
                    {orderdetail?.aggregator.phone}
                  </p>
                </div>
              </div>
            </div>

            <div className="my-10">
              <div className="overflow-x-auto relative ">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs text-gray-700 uppercase bg-slate-100">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Harvest ID
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Price/Ton
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Weight
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Quantity
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b hover:bg-gray-100 cursor-pointer dark:border-gray-700">
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {orderdetail?.harvests[0].harvest_id}
                      </th>
                      <td className="py-4 px-6">
                        {orderdetail?.harvests[0].price}
                      </td>
                      <td className="py-4 px-6">
                        {orderdetail?.harvests[0].weight}
                      </td>
                      <td className="py-4 px-6">
                        {orderdetail?.harvests[0].quantity}
                      </td>
                      {/* <td className="py-4 px-6">
                        {orderdetail?.harvests[0].value}
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex space-x-3 items-center justify-end m-4 ">
              <p className="font-bold">Total Order:</p>
              <p>GHS {orderdetail?.harvests[0].price * orderdetail.harvests[0].weight}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
