import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import api from "../../utils/api";
import UpdateModal from "../../components/UpdateModal";
import { Redirect, useLocation } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);

  const obj = JSON.parse(localStorage.getItem("user"));
  const userType = obj.user_type;

  useEffect(() => {
    getAllgroups();
  }, []);

  const getAllgroups = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url:
        userType === "miller"
          ? `${BASE_URL}miller/get-all-groups/`
          : `${BASE_URL}aggregator/get-all-group/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setGroups(response.data.groups);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleRowClick = (row) => {
    localStorage.setItem("usertype", row.usertype)
    history.push("/admin/groupDetail", { state: row });
  };

  const handleNewGroup = () => {
    history.push("/admin/NewGroup");
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }


  return (
    <>
      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="w-full">
          <div>
            <div className="flex justify-end px-2">
              <button
                onClick={() => handleNewGroup()}
                className="rounded-lg bg-yellowcolor p-2 flex px-6  items-center capitalize"
              >
                create group
              </button>
            </div>
          </div>

          <div className="my-5">
            {loading ? (
              <div className="h-96 grid items-center justify-center">
                <Spinner />
              </div>
            ) : groups.length > 0 ? (
              <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left bg-white">
                  <thead className="text-xs text-gray-700 capitalize bg-white">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        <input
                          type={"checkbox"}
                          className="border-2 mr-5 border-gray-700"
                        />
                        ID
                      </th>
                      <th scope="col" className="py-3 px-6">
                        group name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        description
                      </th>
                      <th scope="col" className="py-3 px-6">
                        number of participants
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 grid items-center justify-center"
                      >
                        actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups &&
                      groups.map((data, index) => (
                        <tr
                          key={index}
                          className={`${index % 2 === 0
                              ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400"
                              : "bg-slate-100 border-b hover:bg-gray-100"
                            }`}
                        >
                          <th
                            scope="row"
                            className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <input
                              type={"checkbox"}
                              className="capitalize border-2 mr-5 border-gray-700"
                            />
                            {data?.group_id}
                          </th>
                          <td className="capitalize py-4 px-6">
                            {data?.groupname}
                          </td>
                          <td className="capitalize py-4 px-6">
                            {truncate(data?.description, 20)}
                          </td>
                          <td className="capitalize py-4 px-6">
                            {data?.no_of_participants}
                          </td>
                          <td className="capitalize py-4 px-6">
                            <div className="flex items-center gap-4 justify-center">
                              <button
                                className="outline-none"
                                onClick={() => handleRowClick(data)}
                              >
                                <AiFillEye color="black" size={16} />
                              </button>
                              {/* <button className="outline-none">
                                <BsTelephone size={16} />
                              </button> */}
                              {/* <button className="outline-none">
                                <BsEnvelope size={16} />
                              </button> */}
                              <button
                                onClick={() => setShowUpdateModal(true)}
                                className="outline-none"
                              >
                                <MdModeEdit size={16} />
                              </button>
                              <button className="outline-none">
                                <AiFillDelete size={16} color="red" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="h-96 grid items-center justify-center">
                <p className="text-lg">No data found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
