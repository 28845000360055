import React from "react";
import { GrPowerReset } from "react-icons/gr";

const ReportFIlter = ({
  state,
  handleChange,
  regions,
  setRun,
  handleReportSearch,
}) => {
  const status = ["select status","active", "canclled", "recieved", "fulfilled", "pending"];

  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <input
            value={state.count}
            name="count"
            onChange={handleChange}
            min="1"
            type={"number"}
            placeholder="No. of records"
            className="text-slate-500 text-center border-white bg-white p-1 rounded-sm w-full outline-none "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <input
            type={"text"}
            placeholder="From - To"
            className="text-slate-500 text-center border-white bg-white p-1 rounded-sm w-full outline-none "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <select
            value={state.region}
            onChange={handleChange}
            name="region"
            className="w-full rounded-sm border-2 text-slate-400 border-white capitalize p-1 outline-none"
          >
            {regions.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <select
            onChange={handleChange}
            value={state.status}
            name="status"
            className="w-full rounded-sm border-2 text-slate-400 border-white p-1 outline-none"
          >
            {status.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mx-10"></div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <button
            onClick={() => {
              handleReportSearch();
            }}
            type="button"
            className="bg-yellowcolor text-black p-1 rounded-md w-full"
          >
            <span className="text-base font-semibold capitalize">generate</span>
          </button>
        </div>
        <div>
          <GrPowerReset
            className="cursor-pointer"
            onClick={() => {
              setRun("run");
            }}
            size={20}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportFIlter;
