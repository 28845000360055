import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { IoMdAddCircleOutline, IoMdEye } from "react-icons/io";
import { BiFilter } from "react-icons/bi";
import SearchFIlter from "../../components/SearchFIlter";
import noharvest from "../../assets/noharvest.png";
import api from "../../utils/api";
import { formatMydate } from "../../utils/Formatter";
import { createID } from "../../utils/Formatter";
import { SuccessNotification } from "../../utils";
import { GrPowerReset } from "react-icons/gr";
import DeleteModal from "../../components/DeleteModal";
import UpdateModal from "../../components/UpdateModal";
import { Redirect, useLocation } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const [mills, setMills] = useState();
  const [loading, setLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [district, setDistrict] = useState();
  const [community, setCommunity] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [sendSMSLoading, setSMSLoading] = useState(false);
  const [isFiltered, setIsfiltered] = useState(false);
  const [messageState, setMessageState] = useState({
    title: "",
    message: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selecteduser, setSelectedUser] = useState("");
  const [selected, setSelected] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [q, setQ] = useState("");
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);

  const initialState = {
    region: "",
    district: "",
    community: "",
  };
  const [states, setState] = useState(initialState);

  const searchMills = () => {
    let masterData = mills;
    let query = q.toLowerCase();
    let filteredData = masterData.filter((el) => {
      return Object.values(el.miller).join(" ").toLowerCase().includes(query);
    });
    setIsfiltered(true);
    console.log(filteredData);
    setFilteredResults(filteredData);
  };

  const filtermills = () => {
    const filteredData = mills.filter(
      (el) =>
        el.miller.region.toLowerCase().includes(states.region.toLowerCase()) &&
        el.miller.district
          .toLowerCase()
          .includes(states.district.toLowerCase()) &&
        el.miller.community
          .toLowerCase()
          .includes(states.community.toLowerCase())
    );
    setIsfiltered(true);
    console.log(filteredData);
    setFilteredResults(filteredData);
  };

  const reset = () => {
    setQ("");
    setIsfiltered(false);
    setFilteredResults([]);
    getAllMills();
  };

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const getDistrict = async (name) => {
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendSms = async (id) => {
    let c = formatMydate();
    console.log(messageState);
    setSMSLoading(true);
    let user = JSON.parse(localStorage.getItem("user"));
    try {
      let token = localStorage.getItem("token");
      var data = JSON.stringify({
        message: messageState.message,
        sms_id: createID(4),
        scheduled: "False",
        sent: "True",
        sent_date: new Date(),
        id: id,
      });

      var config = {
        method: "post",
        url: `${BASE_URL}miller/send-single-sms/`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          SuccessNotification("Message sent successfully");
          setShowMessageModal(false);
          setSMSLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setSMSLoading(false);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCommunity = async (name) => {
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      console.log(data);

      setCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const obj = JSON.parse(localStorage.getItem("user"));
  const userType = obj.user_type;

  const getAllMills = async () => {
    let token = localStorage.getItem("token");
    let millerID = JSON.parse(localStorage.getItem("user")).id;
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/get-all-mills/?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setMills(response.data.millers);
        console.log(response.data.millers);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshFilter = () => {
    setIsfiltered(false);
    setState(initialState);
    getAllMills();
  };

  function handleMessageChange(evt) {
    const value = evt.target.value;
    setMessageState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const handleRowClick = (row) => {
    history.push("/admin/aggregatormillsDetail", { state: row });
  };

  const handleNewGroup = () => {
    history.push("/admin/newfarmer");
  };

  const handleNext = () => {
    if (mills.next) {
      setCurrentPage(currentPage + 1);
      getAllMills();
    }
  };

  const handlePrev = () => {
    if (mills.previous) {
      setCurrentPage(currentPage - 1);
      getAllMills();
    }
  };

  useEffect(() => {
    getAllMills();
  }, []);

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <>
      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />

      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2 mt-2">
            <div className="lg:mb-0 mb-2">
              <SearchFarmFilter
                setQuery={setQ}
                searchMills={searchMills}
                value={q}
                reset={reset}
              />
            </div>
            <FarmFilter
              regions={regions}
              district={district}
              handleChange={handleChange}
              community={community}
              getCommunity={getCommunity}
              getDistrict={getDistrict}
              filtermills={filtermills}
              setRun={refreshFilter}
            />
          </div>
          {mills?.length < 1 ? (
            <EmptyMills />
          ) : (
            <div className="my-5">
              {loading ? (
                <Spinner />
              ) : (
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <table className="w-full text-xs text-left bg-white">
                    <thead className=" text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          email
                        </th>
                        <th scope="col" className="py-3 px-6">
                          phone number
                        </th>
                        <th scope="col" className="py-3 px-6">
                          region
                        </th>
                        <th scope="col" className="py-3 px-6">
                          district
                        </th>
                        <th scope="col" className="py-3 px-6">
                          community
                        </th>
                        <th scope="col" className="py-3 px-6">
                          action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredResults.length > 0 ? (
                        filteredResults?.map((data, index) => (
                          <>
                            <tr
                              key={data.id}
                              className={`${
                                index % 2 === 0
                                  ? "bg-white border-b dark:border-gray-200"
                                  : "bg-slate-100 border-b"
                              } hover:bg-gray-200`}
                            >
                              <th
                                scope="row"
                                className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="capitalize border-2 mr-5 border-gray-700"
                                />
                                {data?.miller?.firstname +
                                  " " +
                                  data?.miller?.lastname}
                              </th>
                              <td className=" py-4 px-6">
                                {data?.miller?.email}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.miller?.phone}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.miller?.region}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.miller?.district}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.miller?.community}
                              </td>
                              <td className="capitalize py-4 px-3">
                                <div className="flex items-center gap-4 justify-center">
                                  <button
                                    onClick={() => handleRowClick(data)}
                                    className="outline-none"
                                  >
                                    <IoMdEye size={16} />
                                  </button>
                                  {/* <button
                                      onClick={() => {
                                        setSelected(data.id);
                                        setShowConfirmModal(true);
                                      }}
                                      className="outline-none"
                                    >
                                      <AiFillDelete size={16} color="red" />
                                    </button> */}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                      ) : isFiltered === true && filteredResults.length < 1 ? (
                        <tr>
                          <td
                            colSpan="9"
                            className="py-4 px-3  border-t dark:border-gray-700 text-lg text-center text-gray-700"
                          >
                            No data available
                          </td>
                        </tr>
                      ) : (
                        mills?.map((data, index) => (
                          <tr
                            key={data.id}
                            className={`${
                              index % 2 === 0
                                ? "bg-white border-b dark:border-gray-200"
                                : "bg-slate-100 border-b"
                            } hover:bg-gray-200`}
                          >
                            <th
                              scope="row"
                              className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                className="capitalize border-2 mr-5 border-gray-700"
                              />
                              {data?.miller?.firstname +
                                " " +
                                data?.miller?.lastname}
                            </th>
                            <td className=" py-4 px-6">
                              {data?.miller?.email}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.miller?.phone}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.miller?.region}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.miller?.district}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.miller?.community}
                            </td>
                            <td className="capitalize py-4 px-3">
                              <div className="flex items-center gap-4 justify-center">
                                <button
                                  onClick={() => handleRowClick(data)}
                                  className="outline-none"
                                >
                                  <IoMdEye size={16} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {filteredResults.length > 0 && isFiltered === true ? (
                <div className="mt-5">
                  <div class="flex flex-col items-center">
                    <span class="text-sm text-gray-700 dark:text-gray-400">
                      Showing{" "}
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : mills?.length}
                      </span>{" "}
                      to{" "}
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : mills?.length}
                      </span>{" "}
                      of{" "}
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : count}
                      </span>{" "}
                      Entries
                    </span>
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        onClick={() => handlePrev()}
                        disabled={mills?.previous === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        <svg
                          aria-hidden="true"
                          class="mr-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Prev
                      </button>
                      <button
                        onClick={() => handleNext()}
                        disabled={mills?.next === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                        <svg
                          aria-hidden="true"
                          class="ml-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ) : filteredResults.length < 1 &&
                isFiltered === true ? null : mills?.length > 0 &&
                isFiltered === false ? (
                <div className="mt-5">
                  <div class="flex flex-col items-center">
                    <span class="text-sm text-gray-700 dark:text-gray-400">
                      Showing{" "}
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : mills?.length}
                      </span>{" "}
                      to{" "}
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : mills?.length}
                      </span>{" "}
                      of{" "}
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : count}
                      </span>{" "}
                      Entries
                    </span>
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        onClick={() => handlePrev()}
                        disabled={mills?.previous === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        <svg
                          aria-hidden="true"
                          class="mr-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Prev
                      </button>
                      <button
                        onClick={() => handleNext()}
                        disabled={mills?.next === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                        <svg
                          aria-hidden="true"
                          class="ml-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Filter = () => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row ">
        <div className="mb-2 lg:mb-0 md:mb-0 lg:w-64 w-full">
          <input
            type={"text"}
            placeholder="search"
            value={""}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <select className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none">
            <option>region</option>
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <select className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none">
            <option>community</option>
          </select>
        </div>
      </div>
    </div>
  );
};

const EmptyMills = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noharvest} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No mill record found</h1>
      </div>
    </div>
  );
};

const SearchFarmFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchMills,
  reset,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by name, crop, acres..."
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searchMills()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            reset();
          }}
          size={20}
        />
      </div>
    </div>
  );
};

const FarmFilter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filtermills,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option>Select Region</option>
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option>Select District</option>
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full">
          <select
            name="community"
            onChange={handleChange}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option>Select Community</option>
            {community?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filtermills()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun()}
          size={20}
        />
      </div>
    </div>
  );
};

export default Index;
