import React, { useEffect, useState } from "react";
import useAuth from "../../context/userContext";
import { SuccessNotification, ErrorNotification } from "../../utils";
import axios from "axios";
import api, { BASE_URL } from "../../utils/api";
import language from "../../assets/language.png";
import district from "../../assets/district.png";
import globe from "../../assets/globe.png";
import community from "../../assets/community.png";
import warehouse from "../../assets/warehouse.png";
import graduation from "../../assets/graduation-cap.png";
import { useHistory } from "react-router-dom";
import CustomSelect from "../../components/CustomSelect";
import { CustomSelect2, CustomSelect3 } from "../../components/CustomSelect";
import { Redirect, useLocation } from "react-router-dom";

const Index = () => {
  let tab = localStorage.getItem("activeTab");
  React.useEffect(() => {
    if (tab === null) {
      localStorage.setItem("activeTab", "Personal information");
    }
    setActiveTab(tab);
  }, [tab]);

  const [activeTab, setActiveTab] = React.useState();
  const { updatePassword, loading } = useAuth();
  const user = JSON.parse(localStorage.getItem("user"));
  const userType = user.user_type;

  const { state } = useLocation();
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  const activeTabHandler = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  return (
    <div className=" bg-white shadow-sm my-5 mx-3">
      <div className="border-b pt-5  px-10 border-gray-300 flex">
        <button
          onClick={() => activeTabHandler("Personal information")}
          className={`${
            activeTab === "Personal information"
              ? "border-b-4 border-amber-400 capitalize font-semibold text-base"
              : " font-medium text-gray-400 text-base"
          }`}
        >
          <p className="">Personal information</p>
        </button>
        <button
          onClick={() => activeTabHandler("Change Password")}
          className={`${
            activeTab === "Change Password"
              ? "border-b-4 border-amber-400 capitalize font-semibold text-base"
              : " font-medium text-gray-400 text-base"
          } mx-10`}
        >
          <p className="capitalize">Change Password</p>
        </button>
        {userType !== "miller" && (
          <button
            onClick={() => activeTabHandler("Other Setting")}
            className={`${
              activeTab === "Other Setting"
                ? "border-b-4 border-amber-400 capitalize font-semibold text-base"
                : " font-medium text-gray-400 text-base"
            }`}
          >
            <p className="capitalize">Other Settings</p>
          </button>
        )}
      </div>
      <div className="">
        {activeTab === "Personal information" ? (
          <PersonalInformation user={user} />
        ) : activeTab === "Change Password" ? (
          <ChangePassword updatePassword={updatePassword} loading={loading} />
        ) : activeTab === "Other Setting" ? (
          <OtherSetting />
        ) : null}
      </div>
    </div>
  );
};

const PersonalInformation = ({ user }) => {
  const {
    id,
    fullname,
    phone,
    profile_img,
    firstname,
    lastname,
    email,
    region,
    district,
    longitude,
    latitude,
    community,
    photo_url,
  } = user;
  const [states, setState] = useState({
    firstname: firstname,
    lastname: lastname,
    email: email,
    phone: phone,
    longitude: longitude,
    latitude: latitude,
    region: region,
    district: district,
    community: community,
    profile_img: profile_img,
  });
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [uploadloading, setUpLoading] = useState(false);
  const [districts, setDistrict] = useState();
  const [$community, setCommunity] = useState();
  const { loadUserFromCookies } = useAuth();

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const updateImage = async (e) => {
    setUpLoading(true);
    let token = localStorage.getItem("token");
    const fileUploaded = e.target.files[0];
    const size = e.target.files[0].size / 1024 / 1024;

    if (size > 1) {
      alert("File size must not exceed 1MB");
    } else {
      const formData = new FormData();
      formData.append("my_file", fileUploaded);

      try {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const response = await api.post("auth/upload_profile_image/", formData);
        setUpLoading(false);
        window.localStorage.setItem("user", JSON.stringify(response.data));
        loadUserFromCookies();
      } catch (error) {
        setUpLoading(true);
        ErrorNotification("There was an error in uploading profile pic");
      }
    }
  };

  const saveProfileDetails = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    console.log(states);
    var config = {
      method: "post",
      url: `${BASE_URL}auth/updateprofile/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: states,
    };

    axios(config)
      .then(function (response) {
        SuccessNotification("You have updated your profile successfully");
        setLoading(false);
        localStorage.setItem("user", JSON.stringify(response.data.user));
      })
      .catch(function (error) {
        console.log(error);
        ErrorNotification("An error occurred when updating profile");
        setLoading(false);
      });
  };

  const getDistrict = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setDistrict(data.data.districts);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  const getCommunity = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      console.log(data);

      setCommunity(data.data.communities);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  const regions = [
    { id: 1, name: "Western North Region" },
    { id: 2, name: "Savannah Region" },
    { id: 3, name: "Oti Region" },
    { id: 4, name: "Western Region" },
    { id: 5, name: "Upper West Region" },
    { id: 6, name: "Upper East Region" },
    { id: 7, name: "Northern Region" },
    { id: 8, name: "Greater Accra Region" },
    { id: 9, name: "Eastern Region" },
    { id: 10, name: "Central Region" },
    { id: 11, name: "Volta Region" },
    { id: 12, name: "North East Region" },
    { id: 13, name: "Ahafo Region" },
    { id: 14, name: "Brong-Ahafo Region" },
    { id: 15, name: "Ashanti Region" },
    { id: 16, name: "Bono East Region" },
  ];

  useEffect(() => {
    if (user) {
      setState(user);
    }
  }, [user]);

  const { state } = useLocation();

  const userobj = JSON.parse(localStorage.getItem("user"));
  if (userobj.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <div className="grid items-center justify-center mb-10">
        <div className="flex items-center flex-col lg:flex-row md:flex-row ">
          <img
            src={states?.profile_img}
            alt="profile"
            className="w-32 h-32 object-cover bg-blue-500 rounded-full"
          />
          <div className="lg:ml-3 md:ml-3 items-center">
            <h2 className="font-bold text-base capitalize">{`${firstname} ${lastname}`}</h2>

            <div class="flex justify-center items-center w-full">
              <label
                for="dropzone-file"
                class="flex flex-col justify-center items-center rounded-md shadow-sm py-1 px-4  bg-amber-400 hover:bg-yellow-300 hover:cursor-pointer"
              >
                <div class="flex flex-col justify-center items-center capitalize text-sm">
                  <p>
                    {uploadloading ? "uploading image ..." : "choose an image"}
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  name="profile_img"
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={(e) => {
                    updateImage(e);
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="md:px-10 lg:px-10">
        <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
          <RenderInput
            defaultValue={firstname}
            name="firstname"
            onChange={handleChange}
            label="First name"
            placeholder="First name"
          />
          <RenderInput
            name="lastname"
            onChange={handleChange}
            label="Last name"
            defaultValue={lastname}
            placeholder="Last name"
          />
        </div>
        {/* row-2 */}
        <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
          <RenderInput
            disabled="true"
            defaultValue={email}
            name="email"
            onChange={handleChange}
            label="Email"
            placeholder="user@gmail.com"
          />
          <div class="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
            <div class="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
              Phone number:
            </div>
            <div class="flex-initial w-full md:w-3/5 lg:w-3/5">
              <div className="flex-auto">
                <div className="flex border-2 border-slate-400 rounded-md">
                  <span className="inline-flex items-center px-3 text-sm text-slate-500 bg-white border border-slate-400">
                    +233
                  </span>
                  <input
                    type="text"
                    name="phone"
                    disabled={true}
                    onChange={handleChange}
                    defaultValue={phone.slice(3)}
                    className="w-full py-1.5 px-3 outline-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* row-3 */}
        <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
          <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
            <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
              region:
            </label>
            <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
              <CustomSelect
                selectedItem={region}
                value={states.region}
                getDistrict={getDistrict}
                handleChange={handleChange}
                options={regions}
                name="region"
              />
            </div>
          </div>
          <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
            <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
              district:
            </label>
            <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
              {districts?.length === 0 ? (
                <h4 className="text-center">Loading...</h4>
              ) : (
                <CustomSelect2
                  selectedItem={district}
                  getCommunity={getCommunity}
                  value={states.district}
                  handleChange={handleChange}
                  options={districts}
                  name="district"
                />
              )}
            </div>
          </div>
        </div>
        {/* row-4 */}
        <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
          <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
            <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
              community:
            </label>
            <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
              {$community?.length === 0 ? (
                <h4 className="text-center">Loading...</h4>
              ) : (
                <CustomSelect3
                  selectedItem={community}
                  value={states.community}
                  handleChange={handleChange}
                  options={$community}
                  name="community"
                />
              )}
            </div>
          </div>
          <RenderInput
            defaultValue={longitude}
            name="longitude"
            onChange={handleChange}
            label="Longitude"
            placeholder="0.02321"
          />
        </div>
        <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
         
          <RenderInput
            name="latitude"
            onChange={handleChange}
            label="Latitude"
            defaultValue={latitude}
            placeholder="1.20123"
          />
        </div>

        <div className="grid gap-2 md:px-10 lg:px-10 lg:flex md:flex lg:flex-row lg:justify-end items-center mt-10">
          <button className="bg-red-600 px-16 py-1 capitalize text-black rounded-md shadow-md">
            <p>cancel</p>
          </button>
          <div className="mx-4" />
          <button
            onClick={(e) => {
              e.preventDefault();
              saveProfileDetails();
            }}
            className="bg-amber-400 px-16 py-1 capitalize text-black rounded-md shadow-md"
          >
            <p>{loading ? "Loading..." : "Save"}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

const OtherSetting = ({}) => {
  const history = useHistory();

  const tabs = [
    {
      img: globe,
      name: "Region",
      desc: "Add, Edit and Delete Regions",
      onClick: () => history.push("/admin/setting/region"),
    },
    {
      img: community,
      name: "Community",
      desc: "Add, Edit and Delete Community",
      onClick: () => history.push("/admin/setting/community"),
    },
    {
      img: district,
      name: "District",
      desc: "Add, Edit and Delete District",
      onClick: () => history.push("/admin/setting/district"),
    },
    {
      img: language,
      name: "Language",
      desc: "Add, Edit and Delete Language",
      onClick: () => history.push("/admin/setting/language"),
    },
    {
      img: graduation,
      name: "Education Level",
      desc: "Add, Edit and Delete Education Level",
      onClick: () => history.push("/admin/setting/education-level"),
    },
    // {
    //   img: warehouse,
    //   name: "Mill Type",
    //   desc: "Add, Edit and Delete Mill Type",
    //   onClick: () => history.push("/admin/setting/miller-type"),
    // },
  ];

  return (
    <div className="px-5 py-5">
      <div className="grid grid-cols-1 md:grid-cols-2 justify-between lg:grid-cols-3 pt-2 gap-4">
        {tabs.map((tab, index) => (
          <div
            onClick={tab.onClick}
            key={index}
            className={`border border-gray-100 rounded p-2 bg-white text-center hover:cursor-pointer hover:shadow-md`}
          >
            <div>
              <img
                class="w-6 h-6 mx-auto object-contain mb-1"
                src={tab.img}
                alt={tab.name}
              />
            </div>

            <h3 className="font-bold text-sm capitalize mb-1">{tab.name}</h3>
            <span className="text-sm text-gray-500">{tab.desc}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ChangePassword = ({ updatePassword, loading }) => {
  const [state, setState] = useState({
    oldpassword: "",
    newpassword: "",
    confirmnewpassword: "",
  });

  return (
    <div className="p-4 lg:py-10 lg:px-10">
      <div className="my-10">
        <h1 className="font-bold text-lg capitalize my-3">
          Reset your Password
        </h1>
        <p className="font-semibold text-base text-gray-400">
          Enter a new password to proceed
        </p>
      </div>
      <div className="">
        <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-5 lg:mb-5  w-full">
          <label className="capitalize flex-none md:w-36 lg:w-36 text-black text-base">
            old password
          </label>
          <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
            <input
              type={"password"}
              value={state.oldpassword}
              onChange={(e) =>
                setState({ ...state, oldpassword: e.target.value })
              }
              className="lg:w-2/3 md:w-2/3 w-full rounded-md border-2 border-slate-400 p-1 outline-none"
              placeholder="Your old password"
            />
          </div>
        </div>
        <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-5 lg:mb-5  w-full">
          <label className="capitalize flex-none md:w-36 lg:w-36 text-black text-base">
            new password
          </label>
          <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
            <input
              type={"password"}
              value={state.newpassword}
              onChange={(e) =>
                setState({ ...state, newpassword: e.target.value })
              }
              className="lg:w-2/3 md:w-2/3 w-full rounded-md border-2 border-slate-400 p-1 outline-none"
              placeholder="Enter new password"
            />
          </div>
        </div>
        <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-5 lg:mb-5  w-full">
          <label className="capitalize flex-none md:w-36 lg:w-36 text-black text-base">
            confirm password
          </label>
          <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
            <input
              type={"password"}
              value={state.confirmnewpassword}
              onChange={(e) =>
                setState({ ...state, confirmnewpassword: e.target.value })
              }
              className="lg:w-2/3 md:w-2/3 w-full rounded-md border-2 border-slate-400 p-1 outline-none"
              placeholder="Confirm new password"
            />
          </div>
        </div>
        <button
          onClick={() => {
            if (state.newpassword === state.confirmnewpassword) {
              updatePassword(state);
            } else {
              ErrorNotification("Password does not match");
            }
          }}
          className="my-10 bg-amber-400 px-5 font-bold text-sm py-2 capitalize text-black rounded-md shadow-md"
        >
          <p>{loading ? "Loading..." : "update password"}</p>
        </button>
      </div>
    </div>
  );
};

const RenderInput = ({
  value,
  placeholder,
  onChange,
  name,
  label,
  defaultValue,
  disabled,
}) => {
  return (
    <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
      <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
        {`${label}:`}
      </label>
      <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
        <input
          name={name}
          // value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
        />
      </div>
    </div>
  );
};

const FormOptions = ({ label, value, options }) => {
  return (
    <div className="input-wrapper">
      <p className="text-sm font-semibold mb-2 text-slate-400 capitalize">
        {label}
      </p>
      <select className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none">
        {options ? (
          options.map((option, index) => {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          })
        ) : (
          <option value={value}>{value}</option>
        )}
      </select>
    </div>
  );
};

export default Index;
