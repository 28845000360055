import DashboardBoardView from "./views/DashboardView";
import OrderView from "./views/OrdersView";
import HarvestView from "./views/HarvestView";
import SettingView from "./views/SettingView";
import RegionView from "./views/SettingView/others/RegionView";
import CommunityView from "./views/SettingView/others/CommunityView";
import DistrictView from "./views/SettingView/others/DistrictView";
import LanguageView from "./views/SettingView/others/LanguageView";
import AggregatorView from "./views/AggregatorView";
import OrderDetail from "./views/OrdersView/OrderDetail";
import HarvesDetail from "./views/HarvestView/HarvestDetail";
import AggregatorDetail from "./views/AggregatorView/AggregatorDetail";
import ReportView from "./views/ReportView";
import NewsView from "./views/NewsView";
import NewsDetail from "./views/NewsView/NewsDetail";
import GroupView from "./views/GroupView";
import GroupDetail from "./views/GroupView/GroupDetail";
import NewGroup from "./views/GroupView/NewGroup";
import SMSView from "./views/SmsView";
import SMSDetail from "./views/SmsView/SMSDetail";
import ScheduleSMS from "./views/SmsView/ScheduleSMS";
import NewAggregator from "./views/AggregatorView/NewAggregator";
import AllSentSMS from "./views/SmsView/AllSentSms";
import AggregatorDashboard from "./AggregatorViews/AggregatorDashboard";
import FarmerView from "./AggregatorViews/FarmerView";
import NewFarmer from "./AggregatorViews/FarmerView/NewFarmer";
import FamrView from "./AggregatorViews/FarmView/";
import FarmDetail from "./AggregatorViews/FarmView/FarmDetail";
import MillView from "./AggregatorViews/MillView/";
import MillDetail from "./AggregatorViews/MillView/MillDetail";
import AggregatorDashboardView from "./AggregatorViews/AggregatorView";
import AddAgregator from "./AggregatorViews/AggregatorView/NewAggregator";
import AggregatorHarvestView from "./AggregatorViews/AggregatorHarvestView";
import AggregatorHarvestRequest from "./AggregatorViews/AggregatorHarvestRequest";
import AggregatorOrderView from "./AggregatorViews/OrderView";
import AggregatorOrderDetail from "./AggregatorViews/OrderView/OrderDetail";
import AggregatorReportView from "./AggregatorViews/ReportView";
import AggregatorHarvestDetail from "./AggregatorViews/AggregatorHarvestView/AggregatorHarvestDetail";
import NewRegion from "./views/SettingView/others/RegionView/NewRegion";
import NewCommunity from "./views/SettingView/others/CommunityView/NewCommunity";
import NewDistrict from "./views/SettingView/others/DistrictView/NewDistrict";
import NewLanguage from "./views/SettingView/others/LanguageView/NewLanguage";
import EducationLevelView from "./views/SettingView/others/EducationLevelView";
import NewEducation from "./views/SettingView/others/EducationLevelView/NewEducation";
import MillerTypeView from "./views/SettingView/others/MillerTypeView";
import NewMillerType from "./views/SettingView/others/MillerTypeView/NewMillerType";
import MillerFarmerView from "./views/MillerFarmerView/index";
import MillerFarmView from "./views/MillerFarmView/index";
import MillerNewFarmer from './views/MillerFarmerView/NewFarmer'
import MillerFarmDetail from "./views/MillerFarmView/MillerFarmDetail";
import MillerFarmerDetail from "./views/MillerFarmerView/MillerFarmerDetail";
import AggregatorFarmerDetail from "./AggregatorViews/FarmerView/AggregatorFarmerDetail";
import AggregatorFarmDetail from "./AggregatorViews/FarmView/FarmDetail";
import HarvestRequestDetail from "./AggregatorViews/AggregatorHarvestRequest/HarvestRequestDetail";
import AggregatorSmsView from "./AggregatorViews/SmsView/index";
import AggregatorSMSDetail from "./AggregatorViews/SmsView/SMSDetail";
import AggregatorScheduleSMS from "./AggregatorViews/SmsView/ScheduleSMS";
import AggregatorAllSentSMS from "./AggregatorViews/SmsView/AllSentSms";
import NewHarvest from "./AggregatorViews/AggregatorHarvestView/NewHarvest";
import HarvestDetailView from "./AggregatorViews/AggregatorHarvestView/HarvestDetailView";
import CreateHarvest from './AggregatorViews/AggregatorHarvestView/CreateHarvest'
import HarvesDetail2 from "./AggregatorViews/AggregatorHarvestRequest/HarvestRequestDetail2";
import NewAggregatorGroupView from './AggregatorViews/GroupView/NewGroup'
import AggregatorGroupView from './AggregatorViews/GroupView/index'
import AggregatorGroupDetail from './AggregatorViews/GroupView/GroupDetail'
import MillerHarvestRequest from './views/MillerHarvestRequestView/index'
import CreateHarvestMiller from './views/MillerHarvestRequestView/CreateHarvest'
import OutgoingharvestDetail from './views/MillerHarvestRequestView/HarvestRequestDetail'
import IncomingharvestDetail from './views/MillerHarvestRequestView/HarvestRequestDetail2'
import FAQPage from "./views/FaQ";
import UploadPage from "./components/Upload";
import UploadPageMiller from "./components/UploadMiller";
 
export const PrivateRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    exact: true,
    component: DashboardBoardView,
    layout: "/admin",
  },
  { path: "/orders", name: "orders", component: OrderView, layout: "/admin" },
  {
    path: "/harvest",
    name: "harvest",
    component: MillerHarvestRequest,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "support",
    component: FAQPage,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingView,
    layout: "/admin",
  },
  {
    path: "/analytics",
    name: "analytics",
    component: ReportView,
    layout: "/admin",
  },
  {
    path: "/aggregator",
    name: "aggregator",
    component: AggregatorView,
    layout: "/admin",
  },
  { path: "/news", name: "news", component: NewsView, layout: "/admin" },
  {
    path: "/orderDetails",
    name: "order",
    component: OrderDetail,
    layout: "/admin",
  },
  {
    path: "/harvestDetails",
    name: "harvest",
    component: HarvesDetail,
    layout: "/admin",
  },
  {
    path: "/aggregatorDetails",
    name: "aggregator",
    component: AggregatorDetail,
    layout: "/admin",
  },
  {
    path: "/newsDetail",
    name: "news",
    component: NewsDetail,
    layout: "/admin",
  },
  { path: "/groups", name: "groups", component: GroupView, layout: "/admin" },
  {
    path: "/groupDetail",
    name: "groups",
    component: GroupDetail,
    layout: "/admin",
  },
  { path: "/NewGroup", name: "groups", component: NewGroup, layout: "/admin" },
   {
     path: "/create-sms",
     name: "sms",
     component: SMSView,
     layout: "/admin",
   },
   { path: "/sms", name: "sms", component: SMSView, layout: "/admin" },
  { path: "/SMSDetail", name: "sms", component: SMSDetail, layout: "/admin" },
  {
   path: "/schedule-sms",
   name: "sms",
   component: ScheduleSMS,
   layout: "/admin",
  },
  { path: "/sent-sms", name: "sms", component: AllSentSMS, layout: "/admin" },
  {
    path: "/newAggregator",
    name: "newaggregator",
    component: NewAggregator,
    layout: "/admin",
  },
  {
    path: "/farmers",
    name: "farmers",
    component: MillerFarmerView,
    layout: "/admin",
  },
  {
    path: "/farmerDetail",
    name: "farmers",
    component: MillerFarmerDetail,
    layout: "/admin",
  },
  {
    path: "/newfarmer",
    name: "farmers",
    component: MillerNewFarmer,
    layout: "/admin",
  },
  {
    path: "/farms",
    name: "farms",
    component: MillerFarmView,
    layout: "/admin",
  },
  {
    path: "/farmDetail",
    name: "farms",
    component: MillerFarmDetail,
    layout: "/admin",
  },
  {
    path: "/add-new-harvest",
    name: "harvest",
    component: CreateHarvestMiller,
    layout: "/admin",
  },
  {
    path: "/harvest-outgoing-details",
    name: "harvest",
    component: OutgoingharvestDetail,
    layout: "/admin",
  },
  {
    path: "/harvest-incoming-details",
    name: "harvest",
    component: IncomingharvestDetail,
    layout: "/admin",
  },
  {
    path: "/upload-farmer",
    name: "farmers",
    component: UploadPageMiller,
    layout: "/admin",
  },
];


export const AggregatorRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    exact: true,
    component: AggregatorDashboard,
    layout: "/admin",
  },
  {
    path: "/upload-farmer",
    name: "farmers",
    component: UploadPage,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "support",
    component: FAQPage,
    layout: "/admin",
  },
  {
    path: "/farmers",
    name: "farmers",
    component: FarmerView,
    layout: "/admin",
  },
  {
    path: "/newfarmer",
    name: "farmers",
    component: NewFarmer,
    layout: "/admin",
  },
  {
    path: "/aggregatorFarmerDetail",
    name: "farmers",
    component: AggregatorFarmerDetail,
    layout: "/admin",
  },
  {
    path: "/aggregatorharvestDetails",
    name: "harvest",
    component: HarvesDetail2,
    layout: "/admin",
  },
  {
    path: "/farms",
    name: "farms",
    component: FamrView,
    layout: "/admin",
  },
  {
    path: "/aggregatorfarmDetail",
    name: "farms",
    component: AggregatorFarmDetail,
    layout: "/admin",
  },
  {
    path: "/mills",
    name: "mills",
    component: MillView,
    layout: "/admin",
  },
  {
    path: "/aggregatormillsDetail",
    name: "farms",
    component: MillDetail,
    layout: "/admin",
  },
  {
    path: "/aggretator",
    name: "aggretator",
    component: AggregatorDashboardView,
    layout: "/admin",
  },
  {
    path: "/newAggregator",
    name: "newaggregator",
    component: AddAgregator,
    layout: "/admin",
  },
  {
    path: "/harvest",
    name: "harvest",
    component: AggregatorHarvestView,
    layout: "/admin",
  },
  {
    path: "/add-harvest",
    name: "harvest",
    component: CreateHarvest,
    layout: "/admin",
  },
 
  {
    path: "/harvest-request",
    name: "harvestRequest",
    component: AggregatorHarvestRequest,
    layout: "/admin",
  },
  {
    path: "/harvest-request-detail",
    name: "harvestRequest",
    component: HarvestRequestDetail,
    layout: "/admin",
  },
  {
    path: "/harvestDetails",
    name: "harvest",
    component: HarvestDetailView,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "orders",
    component: AggregatorOrderView,
    layout: "/admin",
  },
  {
    path: "/orderDetails",
    name: "orders",
    component: AggregatorOrderDetail,
    layout: "/admin",
  },

  {
    path: "/analytics",
    name: "analytics",
    component: AggregatorReportView,
    layout: "/admin",
  },
  { path: "/groups", name: "groups", component: AggregatorGroupView, layout: "/admin" },

  {
    path: "/create-sms",
    name: "sms",
    component: ScheduleSMS,
    layout: "/admin",
  },
  { path: "/sms", name: "sms", component: AggregatorSmsView, layout: "/admin" },
  { path: "/SMSDetail", name: "sms", component: AggregatorSMSDetail, layout: "/admin" },
  {
    path: "/schedule-sms",
    name: "sms",
    component: AggregatorScheduleSMS,
    layout: "/admin",
  },
  { path: "/sent-sms", name: "sms", component: AggregatorAllSentSMS, layout: "/admin" },
  {
    path: "/aggregatorDetails",
    name: "aggregator",
    component: AggregatorDetail,
    layout: "/admin",
  },
  { path: "/NewGroup", name: "groups", component: NewAggregatorGroupView, layout: "/admin" },
  {
    path: "/settings",
    name: "settings",
    component: SettingView,
    layout: "/admin",
  },
  {
    path: "/setting/region",
    name: "region",
    component: RegionView,
    layout: "/admin",
  },
  {
    path: "/new-region",
    name: "region",
    component: NewRegion,
    layout: "/admin",
  },
  {
    path: "/new-community",
    name: "community",
    component: NewCommunity,
    layout: "/admin",
  },
  {
    path: "/setting/community",
    name: "community",
    component: CommunityView,
    layout: "/admin",
  },
  {
    path: "/setting/district",
    name: "district",
    component: DistrictView,
    layout: "/admin",
  },
  {
    path: "/new-district",
    name: "district",
    component: NewDistrict,
    layout: "/admin",
  },
  {
    path: "/setting/language",
    name: "language",
    component: LanguageView,
    layout: "/admin",
  },
  {
    path: "/new-language",
    name: "language",
    component: NewLanguage,
    layout: "/admin",
  },
  {
    path: "/setting/education-level",
    name: "education",
    component: EducationLevelView,
    layout: "/admin",
  },
  {
    path: "/new-level",
    name: "education",
    component: NewEducation,
    layout: "/admin",
  },
  {
    path: "/setting/miller-type",
    name: "millertype",
    component: MillerTypeView,
    layout: "/admin",
  },
  {
    path: "/new-miller-type",
    name: "millertype",
    component: NewMillerType,
    layout: "/admin",
  },
  {
    path: "/groupDetail",
    name: "groups",
    component: AggregatorGroupDetail,
    layout: "/admin",
  },
];
