import React, { useState } from "react";
import { FirstForm, LastForm, SecondForm, ThirdForm } from "./Allforms";
import useAuth from "../../context/userContext";
import Verification from "./EmailConfirmation";
import { ErrorNotification } from "../../utils";
import * as yup from "yup";
import { useFormik, Form } from "formik";

const Signup = () => {
  const allsteps = ["First Step", "Second Step", "Third Step"];
  const { signup } = useAuth();

  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState(allsteps);
  const [loading, setLoading] = useState(false);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      password: "",
      confirmpassword: "",
      phone: "",
      ghana_card_number: "",
      email: "",
      region: "",
      district: "",
      community: "",
      dob: "",
      gender: "",
      user_type:""
    },
    validationSchema: yup.object({
      firstname: yup.string().required("First name is required"),
      user_type: yup.string().required("User type is required"),
      lastname: yup.string().required("Last name is required"),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      phone: yup
        .string()
        .required("Phone number is required")
        .min(10, "Phone number must be at least 10 characters")
        .max(10, "Phone number must not be more than 10 characters"),
      ghana_card_number: yup
        .string(),
      email: yup
        .string()
        .required("Email is required")
        .email("Invalid email address"),
      region: yup.string().required("Region is required"),
      dob: yup.string().required("Date of birth is required"),
      gender: yup.string.one,
      district: yup.string().required("District is required"),
      region: yup.string().required("Region is required"),
      community: yup.string().required("Community is required"),
    }),
    onSubmit: async (values) => {
      if (values) {
        SIGNUP(values);
      } else {
        ErrorNotification("Please fill all fields");
      }
    },
  });

  function handleChange(e) {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  }

  function setGender(value){
    formik.setFieldValue("gender", value);
  }


  function resetLocation(){
    formik.setFieldValue("region", "");
    formik.setFieldValue("district", "");
    formik.setFieldValue("community", "");
  }


 
  const SIGNUP = async (values) => {
    setLoading(true);
    try {
      await signup(values);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ErrorNotification(error.message);
    }
  };

  return (
    <div>
      {activeStep === 0 ? (
        <FirstForm
          handleNext={handleNext}
          onSubmit={formik.handleSubmit}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
      ) : activeStep === 1 ? (
        <SecondForm
          handleNext={handleNext}
          handleBack={handleBack}
          resetLocation={resetLocation}
          onSubmit={formik.handleSubmit}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
      ) : activeStep === 2 ? (
        <LastForm
          handleNext={handleNext}
          handleBack={handleBack}
          loading={loading}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          setGender={setGender}
          handleChange={formik.handleChange}
          onSubmit={() => SIGNUP(formik.values)}
        />
      ) : (
        <Verification />
      )}
    </div>
  );
};

export default Signup;
