import React, { useState } from "react";
import Papa from "papaparse";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ErrorNotification, SuccessNotification } from "../utils";
import api from "../utils/api";
import Spinner from "./Spinner";

function UploadPage() {
    const history = useHistory();
    const userData = JSON.parse(localStorage.getItem("user"));
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            Papa.parse(file, {
                encoding: "utf-8",
                header: true,
                complete: (result) => {
                    console.log(result.data);
                    setData(result.data);
                },
                error: (error) => {
                    console.error(error);
                },
            });
        }
    };

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        // if (file) {
        //   data.map((row, index) =>
        //     Object.values(row).map((value, index) => console.log(value))
        //   );
        // }
      


        try {
            const response = await api.post("aggregator/addbulk/", JSON.stringify(data));
            setLoading(false);
            SuccessNotification("You have successfully loaded farmers");
            history.goBack();

        } catch (error) {
            ErrorNotification(error.response.data.error);
            setLoading(false);
        }


    };

    const handleDownload = () => {
        const url = process.env.PUBLIC_URL + '/Template.csv'; // Change the filename and path as per your requirement
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Template.csv'); // Change the filename as per your requirement
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="flex flex-col  items-start  bg-gray-100 p-6">
            <div className="flex items-center justify-between w-full mb-4 ">
                <button onClick={() => history.goBack()} className="mb-5">
                    <IoMdArrowRoundBack size={25} />
                </button>
                <button onClick={() => handleDownload()} className=" bg-blue-500 hover:bg-blue-900 p-2 text-white rounded-md text-sm">
                    Download Template
                </button>
            </div>
            
            <div className="w-full bg-blue-200 h-40  mb-8 p-4">
                <p className="font-medium text-sm underline">INSTRUCTIONS</p>
                <div className="pl-4">
                    <p className="flex text-blue-900 text-sm">1. Upload an excel spreadsheet  <p className="font-black"> (.xlsx) </p> containing data in the template file that was downloaded </p>
                    <p className="text-sm pl-2 text-blue-900">Only columns that are specified in the provided templates will be uploaded. The supported columns are <span className="font-semibold whitespace-nowrap">firstname,lastname,email,contact,district,region,community,gender,dob,education,farm_name,farm_region,acres,farm_district,farm_community,farm_longitude,</span></p>
                    <p className="text-sm pl-2 text-blue-900 font-semibold">farm_latitude</p>
                    <p className="flex text-blue-900 text-sm">2. To upload a file, click on the choose file option below to select the filled template file and then click on the upload button to upload the data</p>
                    <p className="flex text-blue-900 text-sm">3. If there are any errors, the system will prompt you and the upload will be rejected</p>


                </div>

            </div>

            <div className="w-full p-6 bg-white rounded-lg shadow-md">
                <h1 className="text-3xl font-semibold mb-6">File Upload</h1>
                <form onSubmit={handleSubmit} className="lg:grid lg:grid-cols-3">
                    <div className="mb-4 col-span-1">
                        <label className="block font-medium mb-2" htmlFor="file-upload">
                            Select a CSV file
                        </label>
                        <input
                            className="border-gray-300 rounded-md shadow-sm p-2 w-full"
                            type="file"
                            id="file-upload"
                            accept=".csv,.xls,.xlsx"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="col-span-2">
                        {data.length > 0 ? (
                            <div className="mb-4">
                                <h2 className="text-lg font-medium mb-2">Preview</h2>
                                <div className="overflow-x-auto">
                                    <table className="w-full whitespace-nowrap">
                                        <thead>
                                            <tr>
                                                {Object.keys(data[0]).map((key) => (
                                                    <th
                                                        key={key}
                                                        className="font-medium text-white py-2 px-4  bg-black "
                                                    >
                                                        {key}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row, index) => (
                                                <tr
                                                    key={index}
                                                    className={(index + 1) % 2 === 0 ? "bg-gray-300  border-1 border-black" : "border-b-1 border-black"}
                                                >
                                                    {Object.values(row).map((value, index) => (
                                                        <td key={index} className="py-2 px-4">
                                                            {value}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="flex justify-center items-center">
                                    <span className="text-gray-700 text-sm">
                                        No data available
                                    </span>
                                </div>
                            </>
                        )}
                        {data.length > 0 && (
                            <div className="mt-6">
                                <button
                                    className="bg-yellowcolor text-black font-medium py-2 px-4 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
                                    type="submit"
                                    disabled={!file}
                                >
                                    {loading ? <Spinner /> : <span>Upload</span>}

                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UploadPage;
