import React from "react";

const CustomSelect = ({
  selectedItem,
  value,
  name,
  options,
  handleChange,
  getDistrict,
}) => {
  return (
    <div className="input-wrapper flex flex-col space-y-2  ">
      <div className="border-b  py-2  flex capitalize items-center justify-center border rounded-md">
        <p className="text-sm font-semibold text-slate-400">{selectedItem}</p>
      </div>

      <select
        name={name}
        defaultValue={value}
        onInput={(e) => getDistrict(e.target.value)}
        onChange={handleChange}
        className="p-1 w-full outline-none border rounded-md"
      >
        <option>Select {name}</option>
        {options?.map((option, index) => (
          <option key={index} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;

export const CustomSelect2 = ({
  selectedItem,
  value,
  name,
  options,
  handleChange,
  getCommunity,
}) => {
  return (
    <div className="input-wrapper flex flex-col space-y-2  ">
      <div className="border-b  py-2  flex capitalize items-center justify-center border rounded-md">
        <p className="text-sm font-semibold text-slate-400">{selectedItem}</p>
      </div>

      <select
        name={name}
        defaultValue={value}
        onInput={(e) => getCommunity(e.target.value)}
        onChange={handleChange}
        className="p-1 w-full outline-none border rounded-md"
      >
        <option>Select {name}</option>
        {options?.map((option, index) => (
          <option key={index} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export const CustomSelect3 = ({
  selectedItem,
  value,
  name,
  options,
  handleChange,
}) => {
  return (
    <div className="input-wrapper flex flex-col space-y-2  ">
      <div className="border-b  py-2  flex capitalize items-center justify-center border rounded-md">
        <p className="text-sm font-semibold text-slate-400">{selectedItem}</p>
      </div>

      <select
        name={name}
        defaultValue={value}
        onChange={handleChange}
        className="p-1 w-full outline-none border rounded-md"
      >
        <option>Select {name}</option>
        {options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
