import React, { useEffect, useState } from "react";
import { BsPerson } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";

import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import Spinner from "../../components/Spinner";
import useAuth from "../../context/userContext";
import { ErrorNotification, SuccessNotification } from "../../utils";
import { BASE_URL } from "../../utils/api";
import { Modal } from "react-responsive-modal";
import { createID } from "../../utils/Formatter";
import axios from "axios";
import { formatDateFull } from "../../utils/Formatter";
import { useLocation } from "react-router-dom";

export default function HarvesDetail2(props) {
  const history = useHistory();
  const request = props.location.state;

  const [show, setShow] = React.useState(false);
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const [state, setState] = useState({
    weight: "",
    quantity: "",
    price: "",
    apppointment_date: "",
    apppointment_time: "",
  });

  function handleChange(evt) {
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  function makeid(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const createRequest = () => {
    let token = localStorage.getItem("token");

    var payload = {
      harvest_id: request.state.id,
      weight: state.weight,
      quantity: state.quantity,
      price: state.price,
      date: state.apppointment_date,
      order_id: makeid(5),
      farmer: request.state.farmer,
      harvests: [request.state.id],
      appointment_time: state.apppointment_time,
      appointment_date: state.apppointment_date + " " + state.appointment_time,
    };

    setLoading(true);
    var config = {
      method: "post",
      url: `${BASE_URL}miller/create-harvest-response/`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        SuccessNotification("You have successfully created an order for this");
        history.push("/admin/harvest")
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const getHarvestDetail = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-harvest-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setDetail(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => getHarvestDetail(), [location.state]);

  //   const getHarvestDetail = () => {
  //     setLoading(true);
  //     var axios = require("axios");
  //     var data = "";
  //     const token = localStorage.getItem("token");
  //     var config = {
  //       method: "get",
  //       url: `${BASE_URL}miller/get-harvest-details/${request.state.id}/`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       data: data,
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         setharvestDetail(response.data.harvests);
  //         setLoading(false);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         setLoading(false);
  //       });
  //   };

  //   useEffect(() => {
  //     getHarvestDetail();
  //   }, []);

  return (
    <div className="w-full lg:px-20 px-5 h-full py-10">
      <Modal open={open} onClose={onCloseModal} center>
        <div className="w-96">
          <h1 className="capitalize font-bold text-base">
          Book Evacuation Date 
          </h1>
          <div className="my-7" />
          <div className="">
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Weight</label>
              <input
                type="number"
                name="weight"
                value={state.weight}
                max={detail?.weight}
                onChange={(e) => {
                  if (
                    e.target.value > parseInt(detail?.weight)
                    
                  ) {

                    ErrorNotification(
                      "Weight cannot be greater than the available weight"
                    );
                  } else {
                    handleChange(e);
                  }
                }}
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Price</label>
              <input
                type="text"
                name="price"
                value={state.price}
                onChange={(e) => {
                  if (
                    e.target.value < detail?.price &&
                    e.target.value.length === detail?.weight.toString().length
                  ) {
                    ErrorNotification(
                      "Price cannot be lesser than what was demanded"
                    );
                  } else {
                    handleChange(e);
                  }
                }}
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Quantity</label>
              <input
                type="text"
                name="quantity"
                value={state.quantity}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Date</label>
              <input
                type="date"
                name="apppointment_date"
                value={state.apppointment_date}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Time</label>
              <input
                name="apppointment_time"
                value={state.apppointment_time}
                onChange={handleChange}
                type="time"
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          </div>
          <div className="my-7" />
          <div className="flex items-end justify-end">
            <button
              onClick={(e) => {
                createRequest();
              }}
              className="bg-yellowcolor text-sm px-4 py-2 rounded-md hover:bg-yellow-300"
            >
              {loading ? "updating..." : "Book Appointment"}
            </button>
          </div>
        </div>
      </Modal>

      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="bg-white shadow-md sm:rounded-md ">
          <div className="flex items-center px-5 justify-between">
            <p className="font-bold py-5  text-base capitalize mb-1">
              Harvest ID: {detail?.id}
            </p>
            <span className="bg-green-400 p-1 rounded-md font-semibold text-sm">
              {detail?.status}
            </span>
          </div>
          <div className="h-0.5 w-full bg-gray-500" />
          <div className="px-5">
            <p className="font-bold py-5  text-sm capitalize mb-1">
              ID: {detail?.id}
            </p>
            <div className="flex flex-col lg:flex-row md:flex-row my-2">
              <BsPerson size={22} />
              <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                <p className="font-bold text-sm capitalize mb-2">
                  {detail?.initiator_type}
                </p>
                <p className="font-light text-sm capitalize">
                  {detail?.harvest_initiator.firstname +
                    " " +
                    detail?.harvest_initiator.lastname}
                </p>
                <p className="font-light text-sm">
                  {detail?.harvest_initiator.email}
                </p>
                <p className="font-light text-sm capitalize">
                  {detail?.harvest_initiator.phone}
                </p>
              </div>
            </div>
            {/* table */}
            <div className="my-10">
              <div class="overflow-x-auto relative">
                <table class="w-full text-sm text-left ">
                  <thead class="text-xs text-gray-700 capitalize bg-slate-100">
                    <tr>
                      <th scope="col" class="py-3 px-6">
                        harvest id
                      </th>
                      <th scope="col" class="py-3 px-6">
                        price/ton
                      </th>

                      <th scope="col" class="py-3 px-6">
                        weight/ton
                      </th>
                      <th scope="col" class="py-3 px-6">
                        quantity
                      </th>
                      {/* <th scope="col" class="py-3 px-6">
                        value
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {detail?.harvest_id}
                      </th>
                      <td class="py-4 px-6">{detail?.price}</td>
                      <td class="py-4 px-6">{detail?.weight}</td>
                      <td class="py-4 px-6">{detail?.quantity}</td>
                      {/* <td class="py-4 px-6">{request.state?.value}</td> */}
                    </tr>
                  </tbody>
                </table>
                <div className="my-10 grid lg:justify-end md:justify-end">
                  <p className="font-semibold text-base capitalize">
                    Total value&nbsp;&nbsp;&nbsp; GHS{" "}
                    {parseInt(detail?.price) * parseInt(detail?.weight)}
                  </p>
                  {detail?.status === "pending" && (
                    <button
                      onClick={() => onOpenModal()}
                      className="bg-amber-400 mt-4 py-1 rounded-md text-base font-medium capitalize"
                    >
                      <p>order</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="capitalize font-bold text-base my-4">
        <h1 className="capitalize font-bold text-base">responses</h1>
      </div>

      <div className="bg-white sm:rounded-sm shadow-sm py-2">
        <div className="px-3 py-4">
          <div class="overflow-x-auto relative">
            <table class="w-5/6 text-sm text-left text-gray-500">
              <thead class="text-xs text-gray-700 capitalize">
                <tr>
                  <th scope="col" class="py-3 px-6">
                    name
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Weight
                  </th>
                  <th scope="col" class="py-3 px-6">
                    quantity
                  </th>
                  <th scope="col" class="py-3 px-6">
                    date created
                  </th>
                  <th scope="col" class="py-3 px-6">
                    response status
                  </th>
                </tr>
              </thead>
              <tbody>
                {detail?.response.map((data, index) => (
                  <tr class="" key={index}>
                    <td class="py-4 px-6">Me</td>
                    <td class="py-4 px-6">{data?.weight}</td>
                    <td class="py-4 px-6">{data?.quantity}</td>
                    <td class="py-4 px-6">
                      {formatDateFull(data?.date_created)}
                    </td>

                    <td class="py-4 px-6">
                      {data.status === "Supply" ? (
                        <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                          {data.status}
                        </span>
                      ) : data.status === "Rejected" ? (
                        <span className="bg-red-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                          {data.status}
                        </span>
                      ) : data.status === "Pending" ? (
                        <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                          {data.status}
                        </span>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const RenderMakeOrder = ({ onClick, id, handleClose }) => {
  const [options, setOptions] = useState();
  const { allusers } = useAuth();
  const [value, setValue] = React.useState();
  const [number, setNumber] = useState();
  const [quantity, setQuantity] = useState();
  const [formInputHide, setformInputHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setModal] = useState(false);

  const units = [
    { id: 1, value: "Tons" },
    { id: 2, value: "Weight" },
    { id: 3, value: "Amount" },
  ];

  const buyDecison = [
    { id: 1, value: "Buy all" },
    { id: 2, value: "Make an offer" },
  ];

  const handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value == 1) {
      setformInputHide(false);
    } else {
      setformInputHide(true);
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <>
          <FormInput
            defaultValue={number}
            placeholder="Enter value you want"
            onChange={(e) => setNumber(e.target.value)}
            type={"text"}
          />
        </>

        <div className="flex gap-2 items-center justify-center">
          <div className="flex-auto w-64">
            <FormInput
              defaultValue={quantity}
              placeholder="Enter quantity you want"
              onChange={(e) => setQuantity(e.target.value)}
              type={"text"}
            />
          </div>
        </div>
        <div className="mt-7 justify-end flex ">
          <button
            onClick={() => setModal(true)}
            className="rounded-md text-black text-base bg-yellowcolor  py-1 flex px-4  items-center capitalize"
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};
