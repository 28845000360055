import React,{Fragment} from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Redirect,
  Switch
} from "react-router-dom";

import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import DashboardLayout from "../layout/index";
import Dashboard from "../views/DashboardView";
import PasswordReset from '../pages/auth/PasswordReset'
import ResetPassword from '../pages/auth/ResetPassword'
import ExpirationVerification from '../pages/auth/ExpirationVerification'
import EmailConfirmation from "../pages/auth/EmailConfirmation";
import EmailSuccessPage from "../pages/EmailSuccessPage";
import PhoneNumberSuccessPage from "../pages/PhoneNumberSuccessPage";
import Cookies from "js-cookie";
import FAQPage from "../views/FaQ";
import UploadPage from "../components/Upload";

export function PrivateRoute({ component: Component, ...rest }) {
  const token = Cookies.get("token");
  
  return (
    <Route
      {...rest}
      render={(props) => {
        return token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }}
    />
  );
}



// export const PublicRoute = ({ component: Component, ...rest }) => {
//   let isLoggedIn = localStorage.token ? true : false;
//  console.log(isLoggedIn)
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isLoggedIn ? (
//           <Redirect to="/admin/dashboard" />
//         ) : (
//           <Component {...props} />
//         )
//       }
//     />
//   );
// };

export const Content = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/admin" component={DashboardLayout} />
       
        <Route path="/signup" component={Signup} />
        <Route path="/confirmation" component={EmailConfirmation} />
        {/* <Route path="/upload-farmers" component={UploadPage} /> */}
        <Route path="/expiration" component={ExpirationVerification} />
        <Route path="/passwordreset" component={PasswordReset} />
        <Route path="/reset-password/:id" component={ResetPassword} />
        <Route path="/email-success" component={EmailSuccessPage} />
        <Route path="/phonenumber-success" component={PhoneNumberSuccessPage} />
        <Route path="/"  component={Login} />
       
        {/* <Redirect to="/404" /> */}
      </Switch>
    </Router>
  );
};
