import React, { useEffect, useState } from "react";
import { BsPerson } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoFlaskOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { CustomModal, FormInput } from "../../components";
import FormSelect from "../../components/FormSelect";
import Spinner from "../../components/Spinner";
import useAuth from "../../context/userContext";
import { SuccessNotification } from "../../utils";
import { BASE_URL } from "../../utils/api";
import Modal from "./Modal";

export default function HarvesDetail(props) {
  const history = useHistory();
  const request = props.location.state;
  console.log(request)
  const [harvestdetail, setharvestDetail] = useState();
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  function makeid(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const createOrder = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var axios = require("axios");

    var data = JSON.stringify({
      order_id: makeid(6),
      order_status: "Recieved",
      harvests: [request.state.id.toString()],
    });

    var config = {
      method: "post",
      url: `${BASE_URL}miller/create-order/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);  
        setShow(false)
        getHarvestDetail()
        SuccessNotification(
          "You have successfully placed an order for this order"
        );
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };



  const getHarvestDetail = () => {
    setLoading(true);
    var axios = require("axios");
    var data = {
      usertype:request.state.usertype
    }
    const token = localStorage.getItem("token");
    var config = {
      method: "post",
      url: `${BASE_URL}miller/get-harvest-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setharvestDetail(response.data.harvests);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getHarvestDetail();
  }, []);

  return (
    <div className="w-full lg:px-20 px-5 h-full py-10">
      {show && (
        <Modal
          showModal={show}
          loading={loading}
          setModal={setShow}
          handleSubmit={(e) => {
            createOrder(); setShow(false)
          }}
        />
      )}
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="bg-white shadow-md sm:rounded-md ">
          <div className="flex items-center px-5 justify-between">
            <p className="font-bold py-5  text-base capitalize mb-1">
              Harvest ID: {request.state.id}
            </p>
            <span className="bg-green-400 p-1 rounded-md font-semibold text-sm">
              {request.state.status}
            </span>
          </div>
          <div className="h-0.5 w-full bg-gray-500" />
          <div className="px-5">
            <p className="font-bold py-5  text-sm capitalize mb-1">
              ID: {request.state.id}
            </p>
            <div className="flex flex-col lg:flex-row md:flex-row my-2">
              <BsPerson size={22} />
              <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                <p className="font-bold text-sm capitalize mb-2">
                  {harvestdetail?.usertype}
                </p>
                <p className="font-light text-sm capitalize">
                  {harvestdetail?.harvestuser.firstname +
                    " " +
                    harvestdetail?.harvestuser.lastname}
                </p>
                <p className="font-light text-sm">
                  {harvestdetail?.harvestuser.email}
                </p>
                <p className="font-light text-sm capitalize">
                  {harvestdetail?.harvestuser.phone}
                </p>
              </div>
            </div>
            {/* table */}
            <div className="my-10">
              <div class="overflow-x-auto relative">
                <table class="w-full text-sm text-left ">
                  <thead class="text-xs text-gray-700 capitalize bg-slate-100">
                    <tr>
                      <th scope="col" class="py-3 px-6">
                        harvest id
                      </th>
                      <th scope="col" class="py-3 px-6">
                        price/ton
                      </th>

                      <th scope="col" class="py-3 px-6">
                        weight/ton
                      </th>
                      <th scope="col" class="py-3 px-6">
                        quantity
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {harvestdetail?.id}
                      </th>
                      <td class="py-4 px-6">{harvestdetail?.price}</td>
                      <td class="py-4 px-6">{harvestdetail?.weight}</td>
                      <td class="py-4 px-6">{harvestdetail?.quantity}</td>
                      
                    </tr>
                  </tbody>
                </table>
                <div className="my-10 grid lg:justify-end md:justify-end">
                  <p className="font-semibold text-base capitalize">
                    Total value&nbsp;&nbsp;&nbsp; GHS {harvestdetail?.price * harvestdetail?.weight}
                  </p>
                  {harvestdetail?.status.toLowerCase() === "pending" && (
                    <button
                      onClick={() => handleOpen()}
                      className="bg-amber-400 mt-4 py-1 rounded-md text-base font-medium capitalize"
                    >
                      <p>order</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const RenderMakeOrder = ({ onClick, id, handleClose }) => {
  const [options, setOptions] = useState();
  const { allusers } = useAuth();
  const [value, setValue] = React.useState();
  const [number, setNumber] = useState();
  const [quantity, setQuantity] = useState();
  const [formInputHide, setformInputHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setModal] = useState(false);

  const units = [
    { id: 1, value: "Tons" },
    { id: 2, value: "Weight" },
    { id: 3, value: "Amount" },
  ];

  const buyDecison = [
    { id: 1, value: "Buy all" },
    { id: 2, value: "Make an offer" },
  ];

  const handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value == 1) {
      setformInputHide(false);
    } else {
      setformInputHide(true);
    }
  };

 

  return (
    <>
      <div className="flex flex-col space-y-4">
        <>
          <FormInput
            defaultValue={number}
            placeholder="Enter value you want"
            onChange={(e) => setNumber(e.target.value)}
            type={"text"}
          />
        </>

        <div className="flex gap-2 items-center justify-center">
          <div className="flex-auto w-64">
            <FormInput
              defaultValue={quantity}
              placeholder="Enter quantity you want"
              onChange={(e) => setQuantity(e.target.value)}
              type={"text"}
            />
          </div>
        </div>
        <div className="mt-7 justify-end flex ">
          <button
            onClick={() => setModal(true)}
            className="rounded-md text-black text-base bg-yellowcolor  py-1 flex px-4  items-center capitalize"
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};
