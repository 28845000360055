import React, { useState, useEffect } from "react";
import order from "../../assets/order.svg";
import { BiFilter } from "react-icons/bi";
import useAuth from "../../context/userContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SearchFIlter from "../../components/SearchFIlter";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import { GrPowerReset } from "react-icons/gr";
import { formatDateFull } from "../../utils/Formatter";
import moment from "moment/moment";
import { Redirect,useLocation} from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [aggregator_name, setAggregatorname] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const getallOrders = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-orders/?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setOrders(response.data);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleNext = () => {
    if (orders.next) {
      setCurrentPage(currentPage + 1);
      getallOrders();
    }
  };

  const handlePrev = () => {
    if (orders.previous) {
      setCurrentPage(currentPage - 1);
      getallOrders();
    }
  };

  const SearchOrders = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-orders/?search=${search}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setOrders(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const filterOrders = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-orders/?order_status=${status}&aggregator_name=${aggregator_name} `,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setOrders(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getallOrders();
  }, []);

  const handleRowClick = (row) => {
    history.push("/admin/orderDetails", { state: row });
  };

  const { state } = useLocation();

  const user=JSON.parse(localStorage.getItem("user"))
  if(user.isfirstTime){
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }


  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="w-full">
          <div className="flex items-center mb-1">
            <p className="font-bold text-base">FIlter: </p>
            <BiFilter size={30} />
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2">
            <div className="lg:mb-0 mb-2">
              <SearchOrderFilter
                setQuery={setSearch}
                searchOrders={SearchOrders}
                setRun={getallOrders}
                value={search}
              />
            </div>
            <OrdersFilter
              filterOrders={filterOrders}
              setRun={getallOrders}
              setStatus={setStatus}
              status={status}
            />
          </div>
          {/* table */}
          {loading ? (
            <div className="h-96 grid items-center justify-center">
              <Spinner />
            </div>
          ) : orders?.results && orders?.results.length > 0 && !loading ? (
            <>
              <div className="my-10">
                <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                  <table class="w-full text-sm text-left bg-white">
                    <thead class="text-xs text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" class="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          Id
                        </th>
                        <th scope="col" class="py-3 px-6">
                          type
                        </th>
                        <th scope="col" class="py-3 px-6">
                          Full Name
                        </th>
                        <th scope="col" class="py-3 px-6">
                          phone number
                        </th>
                        <th scope="col" class="py-3 px-6">
                          Price(GHS)
                        </th>
                        <th scope="col" class="py-3 px-6">
                          quantity
                        </th>
                        <th scope="col" class="py-3 px-6">
                          order date
                        </th>
                        <th scope="col" class="py-3 px-6">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.results &&
                        orders?.results.map((data, index) => (
                          <tr
                            onClick={() => handleRowClick(data)}
                            key={index}
                            class="bg-white border-b hover:bg-gray-100 cursor-pointer dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                className="border-2 mr-5 border-gray-700"
                              />
                              {data?.id}
                            </th>
                            <td class="py-4 px-6">
                             {data?.usertype}
                            </td>
                            <td class="py-4 px-6">
                              {" "}
                              {data?.order_user.firstname +
                                " " +
                                data?.order_user.lastname}
                            </td>
                            <td class="py-4 px-6">{data?.order_user.phone}</td>
                            <td class="py-4 px-6">{data?.order_price}</td>
                            <td class="py-4 px-6">{data?.order_quantity}</td>
                            <td class="py-4 px-6">
                              {/* {moment(data?.order_date).format("MMM Do YY")} */}
                              {formatDateFull(data?.order_date)}
                            </td>
                            <td class="py-4 px-6">
                              {data?.order_status === "Available" ? (
                                <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.order_status}
                                </span>
                              ) : data?.order_status === "Fulfilled" ? (
                                <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.order_status}
                                </span>
                              ) : (
                                <span className="bg-zinc-400 p-1 rounded-md font-semibold px-4 text-sm">
                                  {data?.order_status}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-5">
                <div class="flex flex-col items-center">
                  <span class="text-sm text-gray-700 dark:text-gray-400">
                    Showing{" "}
                    <span class="font-semibold text-gray-900 dark:text-white">
                      {orders?.results?.length}
                    </span>{" "}
                    to{" "}
                    <span class="font-semibold text-gray-900 dark:text-white">
                      {orders?.results?.length}
                    </span>{" "}
                    of{" "}
                    <span class="font-semibold text-gray-900 dark:text-white">
                      {orders?.count}
                    </span>{" "}
                    Entries
                  </span>
                  <div class="inline-flex mt-2 xs:mt-0">
                    <button
                      onClick={() => handlePrev()}
                      disabled={orders?.previous === null}
                      class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        class="mr-2 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      Prev
                    </button>
                    <button
                      onClick={() => handleNext()}
                      disabled={orders?.next === null}
                      class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Next
                      <svg
                        aria-hidden="true"
                        class="ml-2 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <EmpytOrders />
          )}
        </div>
      </div>
    </>
  );
};

const EmpytOrders = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={order} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">
          No orders information found
        </h1>
      </div>
    </div>
  );
};

const SearchOrderFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchOrders,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by email, firstname, lastname"
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searchOrders()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            setQuery("");
            setRun("run");
          }}
          size={20}
        />
      </div>
    </div>
  );
};

const OrdersFilter = ({ status, setStatus, setRun, filterOrders }) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
      <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            value={status}
            placeholder={"Search by aggregator name"}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            <option value={"recieved"}>Aggregator name</option>
  
          </select>
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            <option value={"Recieved"}>Recieved</option>
            <option value={"Fulfilled"}>Fulfilled</option>
            <option value={"Rejected"}>Rejected</option>
          </select>
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filterOrders()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun("run")}
          size={20}
        />
      </div>
    </div>
  );
};

export default Index;
