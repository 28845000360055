import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import SideBarComponent from "./sidebar/index";
import Spinner from "react-bootstrap/esm/Spinner";
import { Switch } from "react-router-dom";
import { PrivateRoutes, AggregatorRoutes } from "../routes";
import { Redirect, Route } from "react-router-dom";
import useAuth from "../context/userContext";
import Notification from "./notification";
import api from "../utils/api";
import { SuccessNotification } from "../utils";

const DashboardLayout = () => {
  const [selectedItem, setselectedItem] = useState("Dashboard");
  const [showNotification, setShowNotification] = useState(false);
  const [showSidebar, setSidebar] = useState(true);
  const [messages, setMessages] = useState();
  const [unreadmessages, unsetReadMessages] = useState();

  const obj = JSON.parse(localStorage.getItem("user"));
  const userType = obj.user_type;

  const getallNotifications = async () => {
    let token = localStorage.getItem("token");
    var allmessages = [];
    try {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await api.get("notification/getallNotifications/");
      let m = response.data.notifications;
      for (var i = 0; i < m.length; i++) {
        if (m[i].read == false) {
          console.log(m[i]);
          allmessages.push(m[i]);
        }
        unsetReadMessages(allmessages);
      }
      setMessages(response.data.notifications);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteallNotifications = async () => {
    let token = localStorage.getItem("token");
    try {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await api.delete("deleteallNotifications/");
      SuccessNotification("You have deleted all notifications successfully");
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteSingleNotification = async (id) => {
    let token = localStorage.getItem("token");
    try {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await api.get(`deleteSingleNotification/${id}/`);
      setMessages(response.data.remaining_notifications);
      SuccessNotification("You have deleted this notification successfully");
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateNotification = async (id) => {
    let token = localStorage.getItem("token");
    var data = JSON.stringify({
      read: true,
    });
    try {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const response = await api.post(`updatenotification/${id}/`);
      getallNotifications();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getallNotifications();
  }, []);

  const setLink = (item) => {
    setselectedItem(item);
  };

  return (
    <div className="flex h-full bg-slate-100 overflow-hidden">
      <SideBarComponent
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        selectedItem={selectedItem}
        setLink={setLink}
      />
      <div className="flex flex-col h-screen flex-1 overflow-x-auto mb-[10px] pt-14">
        <Navbar
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          messages={messages}
          unreadmessages={unreadmessages}
          showSidebar={showSidebar}
          setSidebar={setSidebar}
        />
        <React.Suspense fallback={<Spinner />}>
          <Switch>
            {userType === "miller"
              ? PrivateRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      exact={prop.exact}
                      key={key}
                    />
                  );
                })
              : AggregatorRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.layout + prop.path}
                      component={prop.component}
                      exact={prop.exact}
                      key={key}
                    />
                  );
                })}
          </Switch>
        </React.Suspense>
      </div>
      <Notification
        setShowNotification={setShowNotification}
        showNotification={showNotification}
        messages={messages}
        deleteallNotifications={deleteallNotifications}
        deleteSingleNotification={deleteSingleNotification}
        updateNotification={updateNotification}
      />
    </div>
  );
};

export default DashboardLayout;
