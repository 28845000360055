import React, { useState } from "react";
import { FormInput } from "../../components";
import logo from "../../assets/logo-new.png";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Link as ReachRouter, Redirect, useLocation } from "react-router-dom";
import useAuth from "../../context/userContext";
import { SuccessNotification } from "../../utils";
import { ErrorNotification } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { redirectToReferrer, signin } = useAuth();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const login = async (values) => {
    setLoading(true);
    try {
      await signin(values);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ErrorNotification(error.message);
    }
  };

  if (redirectToReferrer) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <div className="container">
      <div className="w-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen md:h-screen lg:h-screen">
        <div className="bg-[url('/src/background.png')] w-full bg-amber-400  hidden md:block lg:block">
          <div className="bg-[url('/src/Rectangle.png')] h-screen bg-opacity-0 w-full bg-amber-400">
            <h1 className="px-20 pb-6 font-extrabold text-5xl leading-tight text-white opacity-100 absolute inset-x-0 bottom-0">
              Welcome <br />
              to Solidaridad <br />
              Harvest Alert
            </h1>
          </div>
        </div>
        <div className="w-full bg-white grid content-center">
          <div className="text-center grid content-center ">
            <div className="flex content-center justify-center">
              <img src={logo} alt="" className="h-28" />
            </div>
            <h1 className="py-3 font-bold text-base">
              Sign in to your acccount
            </h1>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values) => login(values)}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is invalid")
                .required("Email is required"),
              password: Yup.string()
                .min(4, "Password must be at least 4 characters")
                .required("Password is required"),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="lg:w-9/12 md:w-9/12 w-full lg:px-0 md:px-0 px-5 my-5  grid content-center mx-auto ">
                <div className="md:flex lg:flex mt-5 md:mb-2 lg:mb-2 w-full">
                  <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 ">
                    email:
                  </div>
                  <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="username@website.com"
                      className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                    />
                    {touched.email && errors.email && (
                      <div className="text-red-500 text-sm">{errors.email}</div>
                    )}
                  </div>
                </div>
                {/*  */}
                <div className="md:flex lg:flex mt-4">
                  <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 ">
                    password:
                  </div>
                  <div className="flex-initial w-full md:w-3/5 lg:w-3/5 relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="*****"
                      className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                    />
                    <div className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer">
                      <button
                        type="button"
                        className="outline-none focus:outline-none px-2"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <RiEyeCloseLine className="text-black text-base" />
                        ) : (
                          <RiEyeLine className="text-black text-base" />
                        )}
                      </button>
                    </div>
                    {touched.password && errors.password && (
                      <div className="text-red-500 text-sm">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>
                {/* rest button */}
                <div className="md:flex lg:flex text-right mt-3 md:mb-0 lg:mb-0 md:mt-0 lg:mt-0">
                  <div className="flex-none w-32 capitalize font-medium text-slate-400"></div>
                  <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                    <NavLink
                      to="/passwordreset"
                      className="text-amber-400 cursor-pointer hover:text-primary"
                    >
                      Forgot Password?
                    </NavLink>
                  </div>
                </div>
                {/* button */}
                <div className="md:flex lg:flex mt-5 md:mt-5">
                  <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left"></div>
                  <div className="flex-initial w-full md:w-3/5 lg:w-3/5 z-999">
                    <button
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      type="submit"
                      className="bg-amber-400 w-full hover:bg-amber-500 text-white font-bold py-2 px-4 rounded-md"
                    >
                      {loading ? "Loading..." : "Sign In"}
                    </button>
                  </div>
                </div>

                <div className="flex justify-center mt-10 z-50 relative">
                  <span className="">Don't have an account?</span>
                  <NavLink
                    to="/signup"
                    className="text-amber-400 cursor-pointer hover:text-primary"
                  >
                    &nbsp; Register
                  </NavLink>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;