import Axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = Axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  // .. other options
});

export default api;

export const BASE_URL = baseURL;
