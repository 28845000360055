import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import FormSelect from "../../components/FormSelect";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "../../utils";
import Spinner from "../../components/Spinner";
import useAuth from "../../context/userContext";
import { BASE_URL } from "../../utils/api";
import useDeBounce from "../../utils/useDeBounce";
import api from "../../utils/api";
import { BiHelpCircle } from "react-icons/bi";

import AyncSelectForm from "../../components/AsyncSelect";

export default function NewGroup() {
  const history = useHistory();
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");
  const [participants, SetParticipant] = useState();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [values, setValues] = React.useState([]);
  const [allusers, setUsers] = useState();
  const [selectedgroupType, setSelectedGroupType] = useState("");
  const [loadusers, setloadusers] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [nameError, setnameError] = useState(false);
  const [groupTypeError, setgroupTypeError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [partError, setPertError] = useState(false);

  const debounce = useDeBounce(searchText, 500)



  const grouptype = [
    { value: "farmers", label: "farmers" },
    { value: "mills", label: "mills" },
  ];




  const getallfarmers = (text) => {
    return new Promise((resolve, reject) => {

      var token = localStorage.getItem("token");
      setloadusers(true);
      if (text == undefined) {
        text = ""
      }

      var config = {
        method: "get",
        url: `${BASE_URL}aggregator/getallaggregators/?search_item=${text}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      setTimeout(() => {
        axios(config)
          .then(function (response) {
            console.log(response.data);
            let users = [];
            for (var i = 0; i < response.data.users.length; i++) {
              let u = {
                value: response.data.users[i].user.id,
                label:
                  response.data.users[i].user.firstname +
                  " " +
                  response.data.users[i].user.lastname,
              };
              users.push(u);
            }
            setloadusers(false);
            setOptions(users);
            resolve(users);
          })
          .catch(function (error) {
            console.log(error);
            setloadusers(false);
            reject(error);
          });
      }, 2000); // 5 seconds timeout
    });
  };



  const getallmillers = (usertype) => {
    return new Promise((resolve, reject) => {
      var token = localStorage.getItem("token");
      setloadusers(true);
      let c = {
        usertype: usertype,
      };
      var config = {
        method: "get",
        url: `${BASE_URL}aggregator/getallmillers/?search_item=${searchText}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: c,
      };

      setTimeout(() => {
        axios(config)
          .then(function (response) {
            console.log(response.data);
            let users = [];
            for (var i = 0; i < response.data.users.length; i++) {
              let u = {
                value: response.data.users[i].miller,
                label:
                  response.data.users[i].user.firstname +
                  " " +
                  response.data.users[i].user.lastname,
              };
              users.push(u);
            }
            setloadusers(false);
            setOptions(users);
            resolve(users);
          })
          .catch(function (error) {
            console.log(error);
            setloadusers(false);
            reject(error);
          });
      }, 2000); // 5 seconds timeout
    });
  };


  const createGroup = () => {
    let isValid = true;

    if (name.trim() === "") {
      setnameError(true);
      isValid = false;
    } else {
      setnameError(false);
    }

    if (description.trim() === "") {
      setDescError(true)
      isValid = false;
    } else {
      setDescError(false)
    }

    if (values.length < 1) {
      setPertError(true)
      isValid = false;
    } else {
      setPertError(false)
    }

    // Proceed with form submission if all fields are valid
    if (isValid) {
      setLoading(true);
      let pArray = [];
      for (var i = 0; i < values.length; i++) {
        pArray.push(values[i].value.toString());
      }
      var data = JSON.stringify({
        groupname: name,
        description: description,
        participants: pArray.toString(),
        no_of_participants: values.length,
        usertype: selectedgroupType,
      });

      const token = localStorage.getItem("token");

      var config = {
        method: "post",

        url: `${BASE_URL}aggregator/create-group/`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };



      axios(config)
        .then(function (response) {
          setLoading(false);
          setDescription("");
          setname("");
          SuccessNotification(response.data.message);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
          ErrorNotification("An error occurred when creating group");
        });
    }
  };


















  useEffect(() => {

    if (selectedgroupType === "farmers") {
      getallfarmers()
    } else if (selectedgroupType === "mills") {
      getallmillers()
    }

  }, [selectedgroupType, debounce])

  return (
    <>
      <div className="w-full lg:px-20 px-5 h-full py-10">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>
        <div className="bg-white px-4 py-2 mt-5 sm:rounded-sm border-2 border-gray-300 shadow-md">
          <div className="py-2 border-b border-gray-100s">
            <h2 className="text-base font-bold capitalize">create group</h2>
          </div>
          <div className="py-4">
            <div className="">
              <div className="flex lg:flex-row flex-col gap-2 my-6">
                <p className=" lg:basis-1/4 capitalize text-base ">name:</p>
                <div className="lg:basis-1/3">
                  <div className="w-full">
                    <FormInput
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  {nameError && <p className="text-sm text-red-600 mt-1 ">Group name is required</p>}
                </div>

              </div>
              {/*  */}
              <div className="flex lg:flex-row flex-col gap-2 my-6">
                <p className=" lg:basis-1/4 capitalize text-base ">
                  group description:
                </p>
                <div className="lg:basis-1/3">
                  <div className="w-full">
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
                    />
                  </div>
                  {descError && <p className="text-sm text-red-600 mt-1 ">Group description is required</p>}
                </div>
              </div>
              {/*  */}

              <div className="flex lg:flex-row flex-col gap-2 my-6">
                <p className=" lg:basis-1/4 capitalize text-base ">
                  Group type:
                </p>
                <div className="lg:basis-1/3">
                  <div className="w-full">
                    <select
                      className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                      name="district"
                      id="district"
                      // onInput={(e) => getallusers(e.target.value)}
                      onChange={(e) => setSelectedGroupType(e.target.value)}
                      defaultValue={selectedgroupType}
                    >
                      <option value={"select usertype"}>select</option>
                      {grouptype.map((group) => {
                        return (
                          <option value={group.value}>{group.value}</option>
                        );
                      })}
                    </select>
                  </div>

                </div>
              </div>

              {selectedgroupType === "farmers" ? <div className="flex lg:flex-row flex-col gap-2 my-6">
                <p className=" lg:basis-1/4 capitalize text-base ">
                  add participants:
                </p>
                <div className="lg:basis-1/3">
                  {loadusers && (
                    <p className="text-xs mb-1 text-gray-400">
                      Loading users...
                    </p>
                  )}
                  <div className="w-full space-y-2 border rounded-md">
                    {/* <div>
                      <FormInput
                        className="px-4"
                        value={searchText}
                        placeholder={"Search..."}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div> */}

                    <AyncSelectForm
                      setValues={setValues}
                      defaultOptions={options}
                      loadoptions={getallfarmers}

                    />

                    {/* <FormSelect options={options} setValues={setValues} /> */}
                  </div>
                  {partError && <p className="text-sm text-red-600 mt-1 ">Group participants is required</p>}
                  {/* <div className="mb-2 mt-2 flex flex-row items-center justify-center">
                    <BiHelpCircle color="#9fa6b2" size={20} />
                    <p className="ml-0.5 text-xs text-gray-400 text-left">
                      You can either select from the list of users here or search the names of the users you want to add in the search box
                    </p>
                  </div> */}
                </div>

              </div> : <div className="flex lg:flex-row flex-col gap-2 my-6">
                <p className=" lg:basis-1/4 capitalize text-base ">
                  add participants:
                </p>
                <div className="lg:basis-1/3">
                  {loadusers && (
                    <p className="text-xs mb-1 text-gray-400">
                      Loading users...
                    </p>
                  )}
                  <div className="w-full space-y-2 border rounded-md">

                    <AyncSelectForm
                      setValues={setValues}
                      defaultOptions={options}
                      loadoptions={getallmillers}

                    />

                    {/* <FormSelect options={options} setValues={setValues} /> */}
                  </div>
                  {partError && <p className="text-sm text-red-600 mt-1 ">Group participants is required</p>}
                  {/* <div className="mb-2 mt-2 flex flex-row items-center justify-center">
                    <BiHelpCircle color="#9fa6b2" size={20} />
                    <p className="ml-0.5 text-xs text-gray-400 text-left">
                      You can either select from the list of users here or search the names of the users you want to add in the search box
                    </p>
                  </div> */}
                </div>

              </div>}



              {/*  */}
              <div className="flex lg:flex-row flex-col gap-2 mt-10">
                <div className=" lg:basis-1/4 capitalize text-base" />
                <div className="lg:basis-1/3">
                  <div className="w-full">
                    <div className="mt-5 justify-end flex">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          createGroup();
                        }}
                        className="rounded-md text-black text-base bg-yellowcolor  py-1 flex px-4  items-center capitalize"
                      >
                        {loading ? <Spinner /> : "create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
