import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import axios from "axios";
import api from "../../utils/api";
import { ErrorNotification, SuccessNotification } from "../../utils";

export default function NewFarmer() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    user_type: "farmer",
    firstname: "",
    lastname: "",
    email: "",
    password: "password12345",
    contact: "",
    district: "",
    region: "",
    community: "",
    gender: "",
    dob: "",
    education: "",
    doc_url: "",
    farm_name: "",
    farmer: null,
    acres: 0,
    crop: "",
    farm_region: "",
    farm_district: "",
    farm_community: "",
    longitude: "",
    latitude: "",
  });
  const [districts, setDistrict] = useState();
  const [communities, setCommunity] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [commodities, setCommodities] = useState();
  const [farmdistricts, setFarmDistrict] = useState();
  const [farmcommunities, setFarmCommunity] = useState();
  const crops = ["Oil palm"];

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const getDistrict = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setDistrict(data.data.districts);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  // const validatePhoneNumber=(value)=>{
  //   if(value.startsWith("0")){
  //     value.replace('0', '');
  //     console.log(value)
  //   }

  // }

  const getCommunity = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );

      setCommunity(data.data.communities);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  const getCommodities = async () => {
    try {
      const data = await api.get(`farms/get-all-farm-commodities/`);
      let c = [];
      for (var i = 0; i < data.data.commodities.length; i++) {
        let u = {
          value: data.data.commodities[i].id,
          label: data.data.commodities[i].name,
        };
        c.push(u);
      }

      setCommodities(c);
    } catch (error) { }
  };

  const createFarmer = async () => {
    setLoading(true);
    try {
      const resp = await api.post(
        "aggregator/create-farmer/",
        JSON.stringify(state)
      );

      setLoading(false);
      createFarm(resp.data.data.id);
    } catch (error) {
      if (Array.isArray(error.response.data["error"])) {
        const formattedMessage = error.response.data.error.join('\n');
        ErrorNotification(formattedMessage);

      } else {
        ErrorNotification(error.response.data.error)

      }


      setLoading(false);
      console.log(error);
    }
  };

  const createFarm = async (id) => {
    setLoading(true);

    let payload = {
      name: state.farm_name,
      farmer: id,
      acres: state.acres,
      crops: ["1"],
      region: state.farm_region,
      district: state.farm_district,
      community: state.farm_community,
      longitude: state.longitude,
      latitude: state.latitude,
    };
    try {
      const resp = await api.post(
        "aggregator/create-farm/",
        JSON.stringify(payload)
      );

      setLoading(false);
      createFarmAll(resp.data.data.id, resp.data.data.farmer);
    } catch (error) {
      ErrorNotification("An error occurred in creating farmer");
      setLoading(false);
      console.log(error);
    }
  };

  const createFarmAll = async (id, farmerid) => {
    setLoading(true);

    let payload = {
      farmer: farmerid,
      status: "Available",
      farms: [id.toString()],
    };
    try {
      const resp = await api.post(
        "aggregator/farmers/",
        JSON.stringify(payload)
      );

      setLoading(false);
      SuccessNotification("You have successfully created a farmer");
      history.goBack();
    } catch (error) {
      ErrorNotification("An error occurred in creating farmer");
      setLoading(false);
      console.log(error);
    }
  };

  const getDistrict2 = async (name) => {
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setFarmDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getCommunity2 = async (name) => {
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      console.log(data);

      setFarmCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>
        <div className="bg-white px-4 py-2 mt-2 rounded-sm shadow-md">
          <div className="py-2 border-b border-gray-100s">
            <h2 className="text-base font-bold capitalize">Add a new farmer</h2>
          </div>
          <div className="py-4">
            <h2 className="text-sm font-bold capitalize mb-5 ">
              personal information
            </h2>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                defaultValue={state.firstname}
                onChange={(e) =>
                  setState({ ...state, firstname: e.target.value })
                }
                value={state.firstname}
                name="firstname"
                label="First name"
              />
              <RenderInput
                name="lastname"
                defaultValue={state.lastname}
                onChange={(e) =>
                  setState({ ...state, lastname: e.target.value })
                }
                value={state.lastname}
                label="Last name"
              />
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                defaultValue={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                value={state.email}
                name="email"
                label="email"
                type="email"
              />
              <div class="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
                <div class="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  Phone number:
                </div>
                <div class="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <div className="flex-auto">
                    <div className="flex border border-slate-400 rounded-md">
                      <span className="inline-flex items-center px-3 text-sm text-slate-500 bg-white border border-slate-400">
                        +233
                      </span>
                      <input
                        type="text"
                        name="phone"
                        defaultValue={state.contact}
                        onChange={(e) =>

                          setState({ ...state, contact: e.target.value })
                        }
                        value={state.contact}
                        className="w-full py-1.5 px-3 outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                name="date of birth"
                defaultValue={state.dob}
                onChange={(e) => setState({ ...state, dob: e.target.value })}
                value={state.dob}
                label="date of birth"
                type="date"
              />
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  level of education:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    defaultValue={state.education}
                    onChange={(e) =>
                      setState({ ...state, education: e.target.value })
                    }
                    value={state.education}
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="education"
                    id="education"
                  >
                    <option>select</option>
                    <option value="primary">primary</option>
                    <option value="secondary">secondary</option>
                    <option value="tertiary">tertiary</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  region:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="region"
                    id="region"
                    onChange={(e) =>
                      setState({ ...state, region: e.target.value })
                    }
                    onInput={(e) => getDistrict(e.target.value)}
                    defaultValue={state.region}
                  >
                    <option>Select region</option>
                    {regions?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  district:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="district"
                    id="district"
                    onInput={(e) => getCommunity(e.target.value)}
                    onChange={(e) =>
                      setState({ ...state, district: e.target.value })
                    }
                    defaultValue={state.district}
                  >
                    <option>Select district</option>
                    {districts?.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  community:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="community"
                    id="community"
                    onChange={(e) =>
                      setState({ ...state, community: e.target.value })
                    }
                    value={state.community}
                  >
                    <option>Select community</option>
                    {communities?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  id card:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <input
                    className="w-full capitalize rounded-md border border-slate-400 outline-none"
                    type="file"
                    id="formFile"
                    defaultValue={state.doc_url}
                    onChange={(e) =>
                      setState({ ...state, doc_url: e.target.value })
                    }
                    value={state.doc_url}
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  gender:
                </label>
                <div className="flex flex-row items-center gap-3">
                  <div class="flex items-center">
                    <input
                      id="default-radio-1"
                      type="radio"
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      value={state.gender}
                      onChange={(e) => setState({ ...state, gender: "Male" })}
                    />
                    <label
                      for="default-radio-1"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      M
                    </label>
                  </div>
                  <div class="flex items-center">
                    <input
                      checked=""
                      id="default-radio-2"
                      type="radio"
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      value={state.gender}
                      onChange={(e) => setState({ ...state, gender: "Female" })}
                    />
                    <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      F
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5">
            <h2 className="text-sm font-bold capitalize mb-5 ">
              Farm information
            </h2>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                name="farm name"
                label="farm name"
                value={state.farm_name}
                onChange={(e) =>
                  setState({ ...state, farm_name: e.target.value })
                }
              />
              <RenderInput
                name="farm size"
                label="farm size (Acres)"
                value={state.acres}
                onChange={(e) => setState({ ...state, acres: e.target.value })}
              />
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  region:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="region"
                    id="region"
                    defaultValue={state.farm_region}
                    onInput={(e) => getDistrict2(e.target.value)}
                    onChange={(e) =>
                      setState({ ...state, farm_region: e.target.value })
                    }
                  >
                    <option>Select region</option>
                    {regions?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  district:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="district"
                    id="district"
                    onChange={(e) =>
                      setState({ ...state, farm_district: e.target.value })
                    }
                    onInput={(e) => getCommunity2(e.target.value)}
                    defaultValue={state.farm_district}
                  >
                    <option>Select district</option>
                    {farmdistricts?.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  community:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="community"
                    id="community"
                    onChange={(e) =>
                      setState({ ...state, farm_community: e.target.value })
                    }
                    value={state.farm_community}
                  >
                    <option>Select community</option>
                    {farmcommunities?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2 w-full">
                <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
                  crop:
                </label>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <select
                    className="w-full capitalize rounded-md border border-slate-400 p-1 outline-none"
                    name="crop"
                    id="crop"
                    onChange={(e) =>
                      setState({ ...state, farm_crop: e.target.value })
                    }
                    disabled={true}
                    editable={false}
                    value={"Oil palm"}
                    defaultValue={"Oil palm"}
                    placeholder="Oil palm"
                  >
                    <option>Select Crop</option>
                    {crops?.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full lg:grid lg:grid-cols-2 gap-4 items-center justify-center mb-4">
              <RenderInput
                name="longitude"
                label="Longitude"
                value={state.longitude}
                onChange={(e) =>
                  setState({ ...state, longitude: e.target.value })
                }
              />
              <RenderInput
                name="latitude"
                label="Latitude"
                value={state.latitude}
                onChange={(e) =>
                  setState({ ...state, latitude: e.target.value })
                }
              />
            </div>
          </div>
          <div className="grid gap-2 md:px-10 lg:px-10 lg:flex md:flex lg:flex-row lg:justify-end items-center mt-10">
            <button className="bg-red-600 px-16 py-1 capitalize text-black rounded-md shadow-md">
              <p>cancel</p>
            </button>
            <div className="mx-4" />
            <button
              onClick={(e) => createFarmer()}
              className="bg-amber-400 px-16 py-1 capitalize text-black rounded-md shadow-md"
            >
              <p>{loading ? "Loading..." : "Save"}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const RenderInput = ({
  value,
  placeholder,
  onChange,
  name,
  label,
  defaultValue,
  disabled,
  type,
}) => {
  return (
    <div className="md:flex lg:items-center md:items-center lg:flex mb-5 md:mb-2 lg:mb-2  w-full">
      <label className="capitalize flex-none md:w-36 lg:w-36 text-slate-500 text-base">
        {`${label}:`}
      </label>
      <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
        <input
          name={name}
          // value={value}
          disabled={disabled}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          className="w-full rounded-md border border-slate-400 p-1 outline-none"
        />
      </div>
    </div>
  );
};
