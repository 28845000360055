import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdArrowRoundBack } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import UpdateModal from "../../../../components/UpdateModal";
import api from "../../../../utils/api";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [educationData, setEducationData] = useState()
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);

  const handleNewLevel = () => {
    history.push("/admin/new-level");
  };

  const getEducation = async (name) => {
    try {



      const data = await api.get(`auth/getEducation/`);
      console.log(data)
      setEducationData(data.data.educationlist);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getEducation()

  }, [])


  return (
    <>
      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />
      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex flex-row items-center justify-between px-2">
            <button onClick={() => history.goBack()} >
              <IoMdArrowRoundBack size={25} />
            </button>
            {/* <div className="flex justify-end px-2">
              <button
                onClick={handleNewLevel}
                className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
              >
                <IoMdAddCircleOutline size={19} />
                Add New Education Level
              </button>
            </div> */}
          </div>
        </div>
        <div className="my-5">
          {loading ? (
            <Spinner />
          ) : (
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-xs bg-white">
                <thead class="text-xs text-left text-gray-700 uppercase bg-white">
                  <tr>
                    <th scope="col" class="py-3 px-3">
                      <input
                        type={"checkbox"}
                        className="border-2 mr-5 border-gray-700"
                      />
                      Education Levels
                    </th>
                    <th scope="col" class="py-3 px-3"></th>
                    {/* <th scope="col" class="py-3 px-3">
                      actions
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {educationData?.map((data, index) => (
                    <tr
                      className={`${index % 2 === 0
                          ? "bg-white border-b dark:border-gray-200"
                          : "bg-slate-100 border-b"
                        } hover:bg-gray-100 capitalize text-left`}
                      onClick={() => { }}
                    >
                      <th
                        scope="row"
                        className="capitalize py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <input
                          type={"checkbox"}
                          className="capitalize border-2 mr-5 border-gray-700"
                        />
                        {data.name}
                      </th>

                      <td class="py-4 px-3"></td>
                      {/* <td class="py-4 px-3">
                        <div className="flex items-center gap-4 justify-center">
                          <button onClick={() => setShowUpdateModal(true)}>
                            <MdModeEdit size={16} />
                          </button>
                          <button>
                            <AiFillDelete size={15} color="red" />
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
