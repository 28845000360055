import React, { useState } from "react";
import { GrPowerReset } from "react-icons/gr";

const DashboardFilter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filterAggregator,
  setRun
}) => {
  return ( 
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            <option>Select District</option>
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <select
            name="community"
            onChange={handleChange}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            <option>Select Community</option>
            {community?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button onClick={()=>filterAggregator()} className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md ">
            filter
          </button>
        </div>
      </div>
      <div>
      <GrPowerReset className="cursor-pointer" onClick={()=>setRun("run")} size={20} />
      </div>
    </div>
  );
};

export default DashboardFilter;
