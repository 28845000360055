import React, { useEffect, useState } from 'react'

const useDeBounce=(val, delay) => {
    const [debounceVal, setdebounceVal] = useState()

    useEffect(() => {
        const handler = setTimeout(() => {
            setdebounceVal(val)

        }, delay);

        return () => {
            clearTimeout(handler)
        };


    }, [val])

    return debounceVal
}

export default useDeBounce