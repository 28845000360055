import React from "react";
import { BiFilter } from "react-icons/bi";
import { GrDocumentPdf } from "react-icons/gr";
import { VscFilePdf } from "react-icons/vsc";
import { DashboardFilter } from "../../components";
import ReportFIlter from "./ReportFIlter";
import doc from "../../assets/doc.png";
import pdf from "../../assets/pdf.png";
import xls from "../../assets/xls.png";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import noReport from "../../assets/noReport.svg";
import { IoMdEye } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { CSVLink } from "react-csv";
import { MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import UpdateModal from "../../components/UpdateModal";
import { useLocation, Redirect } from "react-router-dom";
import { formatDateFull } from "../../utils/Formatter";
import { ErrorNotification } from "../../utils";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState("order");
  const items = ["order", "farmer", "harvest"];
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);



  const initialState = {
    "no_of_records": null,
    "status": "",
    "start_date": "",
    "end_date": "",
    "region": "",


  }

  

  const [states, setState] = useState(initialState)


  const handleChange = (e) => {
    const value = e.target.value
    console.log(value)
    setState(
      {
        ...states,
        [e.target.name]: value
      }
    )

  }


  const filter = () => {
    let token = localStorage.getItem("token");


    if (selectedItem === "harvest") {
      if (states.no_of_records === "" || states.status === "" || states.start_date === "" || states.end_date === "") {
        ErrorNotification("Make sure all filter options are filled")
      }
      else {
        setLoading(true);

        let payload = {
          "item": selectedItem,
          "no_of_records": parseInt(states.no_of_records),
          "status": states.status,
          "start_date": states.start_date,
          "end_date": states.end_date

        }


        var config = {
          method: "post",
          url: `${BASE_URL}aggregator/get-all-reports-filter/`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: payload,
        };

        axios(config)
          .then(function (response) {
            setReports(response.data.report);
            setCount(response.data.length);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
          });


      }
    }
    else {
      if (states.no_of_records === "" || states.status === "" || states.start_date === "" || states.end_date === "" || states.region === "") {
        ErrorNotification("Make sure all filter options are filled")
      }
      else {
        setLoading(true);

        let payload = {
          "item": selectedItem,
          "no_of_records": parseInt(states.no_of_records),
          "status": states.status,
          "start_date": states.start_date,
          "end_date": states.end_date,
          "region": states.region

        }


        var config = {
          method: "post",
          url: `${BASE_URL}aggregator/get-all-reports-filter/`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: payload,
        };

        axios(config)
          .then(function (response) {
            setReports(response.data.report);
            setCount(response.data.length);
            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
          });

      }

    }




  };

  const getallReports = (value) => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      item: value,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/get-all-reports/?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setReports(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleNext = () => {
    if (reports.next) {
      setCurrentPage(currentPage + 1);
      getallReports();
    }
  };

  const handlePrev = () => {
    if (reports.previous) {
      setCurrentPage(currentPage - 1);
      getallReports();
    }
  };

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      id: id,
      item: "reports",
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/deleteItem`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const ordersCsvHeader = [
    { label: "Id", key: "id" },
    { label: "Status", key: "status" },
    { label: "Aggregator Firstname", key: "aggregator_user.firstname" },
    { label: "Aggregator Lastname", key: "aggregator_user.lastname" },
    { label: "Phone nuumber", key: "aggregator_user.phone" },
    { label: "Email", key: "aggregator_user.email" },
    { label: "Community", key: "aggregator_user.community" },
    { label: "Region", key: "aggregator_user.region" },
    { label: "District", key: "aggregator_user.district" },
    { label: "Date Created", key: "datecreated" },
  ];

  const farmerCsvHeader = [
    { label: "Id", key: "id" },
    { label: "Status", key: "status" },
    { label: "Aggregator Firstname", key: "farmer.firstname" },
    { label: "Aggregator Lastname", key: "farmer.lastname" },
    { label: "Phone nuumber", key: "farmer.phone" },
    { label: "Email", key: "farmer.email" },
    { label: "Community", key: "farmer.community" },
    { label: "Region", key: "farmer.region" },
    { label: "District", key: "farmer.district" },
    { label: "Date Created", key: "datecreated" },
  ];

  const harvestCsvHeader = [
    { label: "Id", key: "id" },
    { label: "harvest id", key: "harvest_id" },
    { label: "Aggregator Firstname", key: "aggregator.firstname" },
    { label: "Aggregator Lastname", key: "aggregator.lastname" },
    { label: "Weight", key: "weight" },
    { label: "Quantity", key: "quantity" },
    { label: "Value (GHC)", key: "value" },
    { label: "Status", key: "status" },
    { label: "Phone nuumber", key: "aggregator.phone" },
    { label: "Email", key: "aggregator.email" },
    { label: "Community", key: "aggregator.community" },
    { label: "Region", key: "aggregator.region" },
    { label: "District", key: "aggregator.district" },
    { label: "Date Created", key: "datecreated" },
  ];

  const csvLink = {
    filename: "report.csv",
    headers:
      selectedItem === "orders"
        ? ordersCsvHeader
        : selectedItem === "farmer"
          ? farmerCsvHeader
          : harvestCsvHeader,
    data: reports,
  };

  const handleRowClick = (row) => {
    history.push("/admin/aggregatorFarmerDetail", { state: row });
  };

  useEffect(() => {
    const item = localStorage.getItem("report-item")

    if (item === null) {
      getallReports("order");

    }
    else {
     
      setSelectedItem(localStorage.getItem("report-item"))
      getallReports(localStorage.getItem("report-item"))
    }

  }, []);

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <>
      <DeleteModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        title="mill"
        handleDelete={() => handleDelete(selected)}
      />

      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />

      <div className="w-full lg:px-6 px-2 h-full py-6">
        <div className="w-full">
          <div className="mb-2">
            <select
              name="report"
              id="report"
              value={selectedItem}
              onChange={(e) => {
                setSelectedItem(e.target.value);
                localStorage.setItem("report-item",e.target.value)
                getallReports(e.target.value);
              }}
              className="lg:w-32 md:w-32 w-full font-semibold capitalize bg-yellowcolor text-base px-3 py-2 mb-3 leading-tight  border-none rounded"
            >
              {items.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="lg:flex md:flex items-center lg:justify-between md:justify-between mb-2">
            <div className="flex items-center mb-1">
              <p className="font-bold text-base">FIlter: </p>
              <BiFilter size={30} />
            </div>
            {reports && reports?.length > 0 && (
              <div className="flex items-center">
                <CSVLink {...csvLink}>
                  <button type="button" className="text-red-500">
                    <img src={xls} alt="doc" className="w-6 h-6" />
                  </button>
                </CSVLink>
                <CSVLink {...csvLink}>
                  <button type="button" className="mx-2 text-red-700">
                    <img src={pdf} alt="doc" className="w-6 h-6" />
                  </button>
                </CSVLink>
                <CSVLink {...csvLink}>
                  <button type="button" className=" text-red-500">
                    <img src={doc} alt="doc" className="w-6 h-6" />
                  </button>
                </CSVLink>
              </div>
            )}
          </div>
          <ReportFIlter selectedItem={selectedItem} handleChange={handleChange} filter={filter} />

          {loading ? (
            <div className="h-96 grid items-center justify-center">
              <Spinner />
            </div>
          ) : reports.length < 1 ? (
            <EmpytReports />
          ) : (
            <div className="my-5">
              <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                {selectedItem === "order" ? (
                  <table class="w-full text-sm text-left bg-white">
                    <thead class="text-xs text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" class="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          order ID
                        </th>
                        <th scope="col" class="py-3 px-6">
                          famer
                        </th>
                        <th scope="col" class="py-3 px-6">
                          phone number
                        </th>
                        <th scope="col" class="py-3 px-6">
                          region
                        </th>

                        <th scope="col" class="py-3 px-6">
                          quantity
                        </th>
                        <th scope="col" class="py-3 px-6">
                          order date
                        </th>
                        <th scope="col" class="py-3 px-6">
                          Status
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {reports &&
                        reports?.map((data, index) => (
                          <tr
                            key={index}
                            onClick={() => { }}
                            class={`${index % 2 === 0
                              ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400 cursor-pointer"
                              : "bg-slate-100 border-b hover:bg-gray-100 cursor-pointer"
                              }`}
                          >
                            <th
                              scope="row"
                              class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                className="border-2 mr-5 border-gray-700"
                              />
                              {data?.id}
                            </th>
                            <td class="py-4 px-6">{`${data?.aggregator?.firstname} ${data?.aggregator?.lastname}`}</td>
                            <td class="py-4 px-6">{data?.aggregator.phone}</td>
                            <td class="py-4 px-6">{data?.aggregator.region}</td>


                            <td class="py-4 px-6">
                              {data?.order.order_quantity}
                            </td>

                            <td class="py-4 px-6">
                              {formatDateFull(data?.order.appointment_date)}

                            </td>
                            <td class="py-4 px-6">
                              {data?.order.order_status === "Recieved" ? (
                                <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.order.order_status}
                                </span>
                              ) : data?.order.order_status === "Fulfilled" ? (
                                <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.order.order_status}
                                </span>
                              ) : data?.order.order_status === "Available" ? (
                                <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.order.order_status}
                                </span>
                              ) : (
                                <span className="bg-red-400 p-1 rounded-md font-semibold px-4 text-sm">
                                  {data?.order.order_status}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : selectedItem === "farmer" ? (
                  <table class="w-full text-xs text-left bg-white">
                    <thead class="text-xs text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          gender
                        </th>
                        <th scope="col" className="py-3 px-6">
                          phone number
                        </th>
                        <th scope="col" className="py-3 px-6">
                          region
                        </th>
                        <th scope="col" className="py-3 px-6">
                          district
                        </th>
                        <th scope="col" className="py-3 px-6">
                          community
                        </th>
                        <th scope="col" className="py-3 px-6">
                          date of birth
                        </th>
                        <th scope="col" class="py-3 px-6">
                          Status
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {reports &&
                        reports?.map((data, index) => (
                          <tr
                            key={data.id}
                            className={`${index % 2 === 0
                              ? "bg-white border-b dark:border-gray-200"
                              : "bg-slate-100 border-b"
                              } hover:bg-gray-200 text-xs`}
                          >
                            <th
                              scope="row"
                              className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                className="capitalize border-2 mr-5 border-gray-700"
                              />
                              {`${data?.farmer.firstname} ${data?.farmer.lastname}`}
                            </th>
                            <td className="capitalize py-4 px-6">
                              {data?.farmer.gender}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.farmer.phone}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.farmer.region}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.farmer.district}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {data?.farmer.community}
                            </td>
                            <td className="capitalize py-4 px-6">
                              {formatDateFull(data?.farmer.dob)}
                            </td>
                            <td class="py-4 px-6">
                              {data?.status === "Available" ? (
                                <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.status}
                                </span>
                              ) : data?.status === "Inactive" ? (
                                <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.status}
                                </span>
                              ) : null}
                            </td>
                            {/* <td className="capitalize py-4 px-3">
                              <div className="flex items-center gap-4 justify-center">
                                <button
                                  onClick={() =>
                                    handleRowClick({
                                      farmer: data.farmer,
                                      farms: data.farms,
                                    })
                                  }
                                  className="outline-none"
                                >
                                  <IoMdEye size={16} />
                                </button>
                                <button
                                  onClick={() => setShowUpdateModal(true)}
                                  className="outline-none"
                                >
                                  <MdModeEdit size={16} />
                                </button>
                                <button
                                  onClick={() => {
                                    setSelected(data.id);
                                    setShowConfirmModal(true);
                                  }}
                                  className="outline-none"
                                >
                                  <AiFillDelete size={16} color="red" />
                                </button>
                              </div>
                            </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : selectedItem === "harvest" ? (
                  <table class="w-full text-xs text-left bg-white">
                    <thead class="text-xs text-gray-700 capitalize bg-white">
                      <tr>
                        <th scope="col" class="py-3 px-6">
                          <input
                            type={"checkbox"}
                            className="border-2 mr-5 border-gray-700"
                          />
                          harvest ID
                        </th>
                        <th scope="col" class="py-3 px-6">
                          aggregator
                        </th>
                        <th scope="col" class="py-3 px-6">
                          phone number
                        </th>
                        <th scope="col" class="py-3 px-6">
                          weight
                        </th>
                        <th scope="col" class="py-3 px-6">
                          quantity
                        </th>
                        <th scope="col" class="py-3 px-6">
                          price
                        </th>
                        <th scope="col" class="py-3 px-6">
                          date
                        </th>
                        <th scope="col" class="py-3 px-6">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports &&
                        reports?.map((data, index) => (
                          <tr
                            key={index}
                            onClick={() => { }}
                            class={`${index % 2 === 0
                              ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400 cursor-pointer"
                              : "bg-slate-100 border-b hover:bg-gray-100 cursor-pointer"
                              }`}
                          >
                            <th
                              scope="row"
                              class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                className="border-2 mr-5 border-gray-700"
                              />
                              {data?.harvest_id}
                            </th>
                            <td class="py-4 px-6">{`${data?.aggregator?.firstname} ${data?.aggregator?.lastname}`}</td>
                            <td class="py-4 px-6">{data?.aggregator?.phone}</td>
                            <td class="py-4 px-6">{data?.weight}</td>
                            <td class="py-4 px-6">{data?.quantity}</td>
                            <td class="py-4 px-6">GHC {data?.price}</td>
                            <td class="py-4 px-6">
                              {formatDateFull(data?.datecreated)}
                              {/* {moment(data?.datecreated).format("MMM Do YY")} */}
                            </td>
                            <td class="py-4 px-6">
                              {data?.status === "responded" ? (
                                <span className="bg-green-400 capitalize p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.status}
                                </span>
                              ) : data?.status === "Ordered" ? (
                                <span className="bg-blue-400 capitalize p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.status}
                                </span>
                              ) : data?.status === "pending" ? (
                                <span className="bg-gray-400 capitalize  p-1 px-4 rounded-md font-semibold text-sm">
                                  {data?.status}
                                </span>
                              ) : (
                                <span className="bg-red-400 capitalize p-1 rounded-md font-semibold px-4 text-sm">
                                  {data?.status}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
          )}
        </div>
        {reports?.length > 0 && (
          <div className="mt-5">
            <div class="flex flex-col items-center">
              <span class="text-sm text-gray-700 dark:text-gray-400">
                Showing{" "}
                <span class="font-semibold text-gray-900 dark:text-white">
                  {reports?.length}
                </span>{" "}
                to{" "}
                <span class="font-semibold text-gray-900 dark:text-white">
                  {reports?.length}
                </span>{" "}
                of{" "}
                <span class="font-semibold text-gray-900 dark:text-white">
                  {count}
                </span>{" "}
                Entries
              </span>
              <div class="inline-flex mt-2 xs:mt-0">
                <button
                  onClick={() => handlePrev()}
                  disabled={reports?.previous === null}
                  class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <svg
                    aria-hidden="true"
                    class="mr-2 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Prev
                </button>
                <button
                  onClick={() => handleNext()}
                  disabled={reports?.next === null}
                  class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Next
                  <svg
                    aria-hidden="true"
                    class="ml-2 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const EmpytReports = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noReport} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No report information found</h1>
      </div>
    </div>
  );
};

export default Index;
