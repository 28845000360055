import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../utils/api";
import { BiFilter } from "react-icons/bi";
import SearchFIlter from "../../components/SearchFIlter";
import noharvest from "../../assets/noharvest.png";
import SMSModal from "../../components/SMSModal";
import { GrPowerReset } from "react-icons/gr";
import api from "../../utils/api";
import { ErrorNotification, SuccessNotification } from "../../utils";
import { formatDateFull, formatMydate } from "../../utils/Formatter";
import { createID } from "../../utils/Formatter";
import { IoMdAddCircleOutline, IoMdEye } from "react-icons/io";
import DeleteModal from "../../components/DeleteModal";
import UpdateModal from "../../components/UpdateModal";
import { Redirect, useLocation } from "react-router-dom";
import { AiOutlineCloudUpload } from 'react-icons/ai'


const Index = () => {
  const history = useHistory();
  const [farmers, setFarmers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [district, setDistrict] = useState();
  const [community, setCommunity] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [sendSMSLoading, setSMSLoading] = useState(false);
  const [q, setQ] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isFiltered, setIsfiltered] = useState(false);
  const [searchParam, serSearchParam] = useState([]);
  const [farmersObj, setFarmersObj] = useState();
  const [messageState, setMessageState] = useState({
    title: "",
    message: "",
  });

  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [rangeText, setrangeText] = useState("")

  const [filteredResults, setFilteredResults] = useState([]);

  const searchFarmers = () => {
    let masterData = farmers;
    console.log(masterData)
    let query = q.toLowerCase();
    let filteredData = masterData.filter((item) => {
      return Object.values(item.farmer).join(" ").toLowerCase().includes(query);
    });
    setIsfiltered(true);
    setFilteredResults(filteredData);
  };

  const filterFarmers = () => {
    const filteredData = farmers.filter(
      (el) =>
        el.farmer.region.toLowerCase().includes(states.region.toLowerCase()) &&
        el.farmer.district
          .toLowerCase()
          .includes(states.district.toLowerCase()) &&
        el.farmer.community
          .toLowerCase()
          .includes(states.community.toLowerCase())
    );
    setIsfiltered(true);

    setFilteredResults(filteredData);
  };

  const [selecteduser, setSelectedUser] = useState("");

  const initialState = {
    region: "",
    district: "",
    community: "",
  };

  const [states, setState] = useState(initialState);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const getDistrict = async (name) => {
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendSms = async (id) => {
    console.log(selecteduser);
    let c = formatMydate();
    console.log(messageState);
    setSMSLoading(true);
    let user = JSON.parse(localStorage.getItem("user"));
    try {
      let token = localStorage.getItem("token");
      var data = JSON.stringify({
        message: messageState.message,
        sms_id: createID(4),
        sent_date: new Date(),
        id: selecteduser,
        campaign_type: "Sms",
        usertype: "farmer",
      });

      var config = {
        method: "post",
        url: `${BASE_URL}aggregator/send-single-sms/`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          SuccessNotification("Message sent successfully");
          setShowMessageModal(false);
          setSMSLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setSMSLoading(false);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCommunity = async (name) => {
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      console.log(data);

      setCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const obj = JSON.parse(localStorage.getItem("user"));
  const userType = obj.user_type;

  const getAllFarmers = async (count) => {
    let token = localStorage.getItem("token");
    let millerID = JSON.parse(localStorage.getItem("user")).id;
    setLoading(true);


    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/get-farmer-farms/?page=${count}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {

       

        setFarmersObj(response.data)
        setFarmers(response.data.results);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshFilter = () => {
    setIsfiltered(false);
    setState(initialState);
    getAllFarmers(1);
  };

  const reset = () => {
    setQ("");
    setIsfiltered(false);
    setFilteredResults([]);
    getAllFarmers(1);
    getPaginationRange(1)
  };

  function handleMessageChange(evt) {
    const value = evt.target.value;
    setMessageState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const handleRowClick = (row) => {
    history.push("/admin/aggregatorFarmerDetail", { state: row });
  };

  const handleNewGroup = () => {
    history.push("/admin/newfarmer");
  };

   function getPaginationRange(page) {
    const itemsPerPage = 10;
    const currentPage = parseInt(page);
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = currentPage * itemsPerPage;
    setrangeText(`Showing ${startItem} to ${endItem} of `)

  }


  const handleNext = () => {

    if (farmersObj.next) {

      const urlParams = new URLSearchParams(farmersObj.next.split('?')[1]);
      const page = urlParams.get('page');
      getPaginationRange(page)



      getAllFarmers(page);
    }
  };

 


  const handlePrev = () => {
    if (farmersObj.previous) {

      const urlParams = new URLSearchParams(farmersObj.previous.split('?')[1]);
      const page = urlParams.get('page');
     


      if (page == null) {
        getAllFarmers(1)
        getPaginationRange(1)
      }
      else {
        getAllFarmers(page);
        getPaginationRange(page)

      }




    }
  };

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      id: id,
      item: "farmer",
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/deleteItem/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        SuccessNotification("Farmer deleted successfully");
        getAllFarmers();
      })
      .catch(function (error) {
        console.log(error);
        ErrorNotification("An error occurred while deleting");
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllFarmers(1);
    getPaginationRange(1)
  }, []);

  const FarmerData = {
    title: "Update farmer",
    dataset: [
      {
        id: 1,
        label: "firstname",
        inputType: "text",
      },
      {
        id: 2,
        label: "lastname",
        inputType: "text",
      },
      {
        id: 3,
        label: "email",
        inputType: "email",
      },
      {
        id: 4,
        label: "phone",
        inputType: "text",
      },
      {
        id: 5,
        label: "dob",
        inputType: "date",
      },
      {
        id: 6,
        label: "education",
        inputType: "select",
        values: [
          { id: 1, name: "Tertiary" },
          { id: 2, name: "Secondary" },
          { id: 3, name: "Primary" },
        ],
      },
      {
        id: 7,
        label: "longitude",
        inputType: "text",
      },
      {
        id: 8,
        label: "latitude",
        inputType: "text",
      },
      {
        id: 9,
        label: "gender",
        inputType: "radio",
        values: [
          { id: "1", name: "Male" },
          { id: 2, name: "Female" },
        ],
      },
    ],
  };

  const payload = {
    item: "farmer",
    id: selecteduser.id,
  };

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <>
      <SMSModal
        state={messageState}
        handleChange={handleMessageChange}
        showMessageModal={showMessageModal}
        setSMSLoading={sendSMSLoading}
        setShowMessageModal={setShowMessageModal}
        onClick={() => sendSms(selecteduser)}
      />

      <DeleteModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        title="farmer"
        handleDelete={() => handleDelete(selected)}
      />

      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
        data={FarmerData}
        modalData={selecteduser}
        payload={payload}
        setLoading={getAllFarmers}
      />

      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex justify-end ">
            <div className="flex justify-end px-2">
              <button
                onClick={() => history.push("/admin/upload-farmer")}
                className="rounded-lg bg-blue-300 p-2 flex items-center px-6 gap-2 capitalize"
              >
                <AiOutlineCloudUpload size={19} />
                Upload farmers
              </button>
            </div>
            <div className="flex justify-end px-2">
              <button
                onClick={() => handleNewGroup()}
                className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
              >
                <IoMdAddCircleOutline size={19} />
                add new farmer
              </button>
            </div>
          </div>

          {loading ? (
            <div className="h-96 grid items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex items-center mb-1">
                <p className="font-bold text-base">FIlter: </p>
                <BiFilter size={30} />
              </div>
              <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2 mt-2">
                <div className="lg:mb-0 mb-2">
                  <SearchFarmerFilter
                    setQuery={setQ}
                    searchFarmers={searchFarmers}
                    value={q}
                    reset={reset}
                  />
                </div>
                <FarmerFilter
                  regions={regions}
                  district={district}
                  handleChange={handleChange}
                  community={community}
                  getCommunity={getCommunity}
                  getDistrict={getDistrict}
                  filterFarmer={filterFarmers}
                  setRun={reset}
                />
              </div>

              <div className="my-5">
                {farmers?.length > 0 ? (
                  <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table className="w-full text-xs text-left bg-white">
                      <thead className=" text-gray-700 capitalize bg-white">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            <input
                              type={"checkbox"}
                              className="border-2 mr-5 border-gray-700"
                            />
                            name
                          </th>
                          <th scope="col" className="py-3 px-6">
                            gender
                          </th>
                          <th scope="col" className="py-3 px-6">
                            phone
                          </th>
                          <th scope="col" className="py-3 px-6">
                            region
                          </th>
                          <th scope="col" className="py-3 px-6">
                            district
                          </th>
                          <th scope="col" className="py-3 px-6">
                            community
                          </th>
                          <th scope="col" className="py-3 px-6">
                            dob
                          </th>
                          <th
                            scope="col"
                            className="py-3 px-6 "
                          >
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredResults.length > 0 ? (
                          filteredResults?.map((data, index) => (
                            <tr
                              key={data.id}
                              className={`${index % 2 === 0
                                ? "bg-white border-b dark:border-gray-200"
                                : "bg-slate-100 border-b"
                                } hover:bg-gray-200 text-xs`}
                            >
                              <th
                                scope="row"
                                className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="capitalize border-2 mr-5 border-gray-700"
                                />
                                {`${data?.farmer.firstname} ${data?.farmer.lastname}`}
                              </th>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.gender}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.phone}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.region}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.district}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.community}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {formatDateFull(data?.farmer.dob)}
                              </td>
                              <td className="capitalize py-4 px-3">
                                <div className="flex items-center gap-4 justify-center">
                                  <button
                                    onClick={() =>
                                      handleRowClick({
                                        farmer: data.farmer,
                                        farms: data.farms,
                                      })
                                    }
                                    className="outline-none"
                                  >
                                    <IoMdEye size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedUser(data.farmer);
                                      setShowUpdateModal(true);
                                    }}
                                    className="outline-none"
                                  >
                                    <MdModeEdit size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelected(data.id);
                                      setShowConfirmModal(true);
                                    }}
                                    className="outline-none"
                                  >
                                    <AiFillDelete size={16} color="red" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setShowMessageModal(true);
                                      setSelectedUser(data?.farmer.id);
                                    }}
                                    className="outline-none bg-yellowcolor  px-1 rounded"
                                  >
                                    Send SMS
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : isFiltered === true &&
                          filteredResults.length < 1 ? (
                          <tr>
                            <td
                              colSpan="9"
                              className="py-4 px-3  border-t dark:border-gray-700 text-lg text-center text-gray-700"
                            >
                              No data available
                            </td>
                          </tr>
                        ) : (
                          farmers?.map((data, index) => (
                            <tr
                              key={data.id}
                              className={`${index % 2 === 0
                                ? "bg-white border-b dark:border-gray-200"
                                : "bg-slate-100 border-b"
                                } hover:bg-gray-200 text-xs`}
                            >
                              <th
                                scope="row"
                                className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="capitalize border-2 mr-5 border-gray-700"
                                />
                                {`${data?.farmer.firstname} ${data?.farmer.lastname}`}
                              </th>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.gender}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.phone}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.region}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.district}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {data?.farmer.community}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {formatDateFull(data?.farmer.dob)}
                              </td>
                              <td className="capitalize py-4 px-3">
                                <div className="flex items-center gap-4 justify-center">
                                  <button
                                    onClick={() =>
                                      handleRowClick({
                                        farmer: data.farmer,
                                        farms: data.farms,
                                      })
                                    }
                                    className="outline-none"
                                  >
                                    <IoMdEye size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedUser(data.farmer);
                                      setShowUpdateModal(true);
                                    }}
                                    className="outline-none"
                                  >
                                    <MdModeEdit size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelected(data.id);
                                      setShowConfirmModal(true);
                                    }}
                                    className="outline-none"
                                  >
                                    <AiFillDelete size={16} color="red" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setShowMessageModal(true);
                                      setSelectedUser(data?.farmer.id);
                                    }}
                                    className="outline-none bg-yellowcolor  px-1 rounded"
                                  >
                                    Send SMS
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : filteredResults?.length < 1 ? (
                  <EmptyFarmer handleNewGroup={handleNewGroup} />
                ) : (
                  <EmptyFarmer handleNewGroup={handleNewGroup} />
                )}
              </div>

              {filteredResults.length > 0 && isFiltered === true ? (
                <div className="mt-5">
                  <div class="flex flex-col items-center">
                    <span class="text-sm text-gray-700 dark:text-gray-400">

                      {rangeText}
                     
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : count}
                      </span>{" "}
                      Entries
                    </span>
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        onClick={() => handlePrev()}
                        disabled={farmers?.previous === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        <svg
                          aria-hidden="true"
                          class="mr-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Prev
                      </button>
                      <button
                        onClick={() => handleNext()}
                        disabled={farmers?.next === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                        <svg
                          aria-hidden="true"
                          class="ml-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ) : filteredResults.length < 1 &&
                isFiltered === true ? null : farmers?.length > 0 &&
                  isFiltered === false ? (
                <div className="mt-5">
                  <div class="flex flex-col items-center">
                    <span class="text-sm text-gray-700 dark:text-gray-400 space-x-4">
                      {rangeText}

                    
                      <span class="font-semibold text-gray-900 dark:text-white">
                        {filteredResults.length > 0
                          ? filteredResults.length
                          : count}
                      </span>{" "}
                      Entries
                    </span>
                    <div class="inline-flex mt-2 xs:mt-0">
                      <button
                        onClick={() => handlePrev()}
                        disabled={farmers?.previous === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        <svg
                          aria-hidden="true"
                          class="mr-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Prev
                      </button>
                      <button
                        onClick={() => handleNext()}
                        disabled={farmers?.next === null}
                        class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                        <svg
                          aria-hidden="true"
                          class="ml-2 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const SearchFarmerFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchFarmers,
  reset,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by email, name, gender..."
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searchFarmers()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            reset();
          }}
          size={20}
        />
      </div>
    </div>
  );
};

const FarmerFilter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filterFarmer,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option>Select Region</option>
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option>Select District</option>
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <select
            name="community"
            onChange={handleChange}
            className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
          >
            <option>Select Community</option>
            {community?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filterFarmer()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun("run")}
          size={20}
        />
      </div>
    </div>
  );
};

const EmptyFarmer = ({ handleNewGroup }) => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noharvest} alt="" className="w-36" />
        <h1 className="my-2 font-bold text-lg">No farmer record found</h1>
      </div>
    </div>
  );
};

export default Index;
