import React from "react";
// import Select from "react-dropdown-select";
import AsyncSelect from 'react-select/async';
import './styles.css'


export default function AyncSelectForm({ options, setValues, defaultvalue, disabled, loadoptions, defaultOptions }) {


    return (
        <AsyncSelect
            onChange={(value) => {
                console.log(value)
                if (value.length == 0) {
                    loadoptions()

                } else {
                    setValues(value)
                }
            }}
            loadOptions={loadoptions}
            cacheOptions
            defaultValue={defaultvalue}
            isDisabled={disabled}
            isMulti
            values={setValues}
            defaultOptions={defaultOptions}
            className="w-full rounded-md border-1 border-slate-400 "
            classNamePrefix="select"
        />
    );
}

