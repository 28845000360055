import React from "react";
// import Select from "react-dropdown-select";
import Select from "react-select";
import './styles.css'


export default function FormSelect({ options,setValues,defaultvalue,disabled }) {


  return (
    <Select
      onChange={(value) => {console.log(value); setValues(value)}}
      name="phone"
      defaultValue={defaultvalue}
      isDisabled={disabled}
      isMulti
      values={[]}
      options={options}
      className="w-full rounded-md border-1 border-slate-400 " 
      classNamePrefix="select"
    />
  );
}

