import moment from "moment";
export const removeSlashes = (path) => {
  return path.startsWith("/")
    ? path
        .split("/")
        .filter((x) => x !== "")
        .join("/")
    : path;
};

export const formatDateFull = (date) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  };
  let dateToFormat = new Date(date);
  let formmateddate = dateToFormat.toLocaleDateString("en-GB", options);
  return formmateddate;
};

function padTo2Digits(num) {
  return String(num).padStart(2, "0");
}

export const GetTimeFromDate = (date) => {
  const mydate = new Date(date);
  const hoursAndMinutes =
    padTo2Digits(mydate.getHours()) + ":" + padTo2Digits(mydate.getMinutes());

  return hoursAndMinutes;
};

export const createID = (length) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function formatMydate() {
  let now = new Date();
  let day = now.getDate();

  let month = now.getMonth() + 1;

  let year = now.getFullYear().toString();
  
  let result = year.substring(2, 4);

  let mins=parseInt(now.getMinutes()+1)

  let time = now.getHours() + ":" + mins.toString();

  let formatted_time = month + "/" + day + "/" + result + " " + time;

  return formatted_time;
}


export function formatdateForSchedule(data) {
 
 
  let tdate=new Date(data)


  let day = tdate.getDate();

  let month = tdate.getMonth() + 1;

  let year = tdate.getFullYear().toString();
  
  let result = year.substring(2, 4);

  let formatted_time = month + "/" + day + "/" + result 

  return formatted_time;
}
