import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ErrorNotification, SuccessNotification } from "../../../../utils";
import api from "../../../../utils/api";

const NewCommunity = (props) => {
  const history = useHistory();
  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");
  const [region, setRegion] = useState("");
  const [community, setCommunity] = useState("");
  const [refreshing, setRefreshing] = useState(false);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const addCommunity = async (name) => {
    let payload = {
      "region": region,
      "district": district,
      "name": community,
    }

    console.log(payload)
    
    try {
      const data = await api.post(
        `auth/add-community/`,
        JSON.stringify(payload)
      );
      setDistrict("");
      SuccessNotification("Community added successfully");
    } catch (error) {
      ErrorNotification("An error occured while adding community");
      console.log(error.message);
    }
  };

  const getDistrict = async (name) => {
    setRefreshing(true);
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      setDistricts(data.data.districts);
      setRefreshing(false);
    } catch (error) {
      setRefreshing(false);
      console.log(error.message);
    }
  };

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>

      <div className="bg-white sm:rounded-sm border-2 border-gray-100 shadow-sm p-5 my-5">
        <div className="border-b border-gray-300 mb-2">
          <h1 className="capitalize font-bold text-base">add new community</h1>
        </div>
        <div>
          <div className="py-2">
            <div className="w-full flex flex-col gap-3 lg:flex-row lg:items-center mb-3">
              <label className="text-gray-700 font-semibold lg:w-36">
                Region:
              </label>
              <select
              
                onInput={(e) => {getDistrict(e.target.value); setRegion(e.target.value)}}
                value={region}
                className="border-2 capitalize border-gray-300 rounded-lg p-2 w-full lg:w-72"
              >
                {regions?.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full flex flex-col gap-3 lg:flex-row lg:items-center mb-3">
              <label className="text-gray-700 font-semibold lg:w-36">
                District:
              </label>
              <select
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                className="border-2 capitalize border-gray-300 rounded-lg p-2 w-full lg:w-72"
              >
                {refreshing ? (
                  <option value="">Loading...</option>
                ) : (
                  districts?.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })
                )}
              </select>
            </div>
            <div className="w-full flex flex-col gap-3 lg:flex-row lg:items-center mb-3">
              <label className="text-gray-700 font-semibold lg:w-36">
                Community:
              </label>
              <input
                value={community}
                onChange={(e) => setCommunity(e.target.value)}
                editable={!district ? false : true}
                placeholder={
                  !district ? "Select a district" : "Enter community name"
                }
                className="border-2 capitalize border-gray-300 rounded-lg p-2 w-full lg:w-72"
              />
            </div>
            <div className="mt-10">
              <div className="grid gap-2 md:px-10 lg:px-10 lg:flex md:flex lg:flex-row lg:justify-end items-center mt-10">
                <button className="bg-red-600 px-16 py-1 capitalize text-black rounded-md shadow-md">
                  <p>cancel</p>
                </button>
                <div className="mx-4" />
                <button
                  onClick={addCommunity}
                  className="bg-amber-400 px-16 py-1 capitalize text-black rounded-md shadow-md"
                >
                  <p>{"Save"}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCommunity;
