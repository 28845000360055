import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";

const NewLanguage = (props) => {
  const history = useHistory();

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>

      <div className="bg-white sm:rounded-sm border-2 border-gray-100 shadow-sm p-5 my-5">
        <div className="border-b border-gray-300 mb-2">
          <h1 className="capitalize font-bold text-base">add new Language</h1>
        </div>
        <div>
          <div className="py-2">
            <div className="w-full flex flex-col gap-3 lg:flex-row lg:items-center">
              <label className="text-gray-700 font-semibold lg:w-36">
                Language:
              </label>
              <input
                type="text"
                className="border-2 border-gray-300 rounded-lg p-2 w-full lg:w-72"
              />
            </div>
            <div className="mt-10">
            <div className="grid gap-2 md:px-10 lg:px-10 lg:flex md:flex lg:flex-row lg:justify-end items-center mt-10">
          <button className="bg-red-600 px-16 py-1 capitalize text-black rounded-md shadow-md">
            <p>cancel</p>
          </button>
          <div className="mx-4" />
          <button
            onClick={(e) => {}}
            className="bg-amber-400 px-16 py-1 capitalize text-black rounded-md shadow-md"
          >
            <p>{"Save"}</p>
          </button>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLanguage;
