import React, { useState, useEffect } from "react";
import { BsPerson } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { AiFillEye, AiOutlineCalendar } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import { formatDateFull } from "../../utils/Formatter";
import { ErrorNotification, SuccessNotification } from "../../utils";

export default function OrderDetail(props) {
  const history = useHistory();
  const request = props.location.state;
  const [loading, setLoading] = useState(true);
  const [orderdetail, setorderDetail] = useState({});
  const [status, setStatus] = useState();

  const getOrderDetail = () => {
    setLoading(true);
    var axios = require("axios");
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/get-order-details/${request.state.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data)
        setorderDetail(response.data.order);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        ErrorNotification("An error occurred while getting info");
        setLoading(false);
      });
  };

  function isInThePast(date) {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return date < today;
  }

  const updateOrder = () => {
    setLoading(true);
    var axios = require("axios");

    const token = localStorage.getItem("token");

    const payload = {
      order_status:status,
      item:"order",
      id:request.state.id
    };

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/updateItem/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        getOrderDetail();
        SuccessNotification("You have updated the order successfully")
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        ErrorNotification("An error occurred while getting info");
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      {loading ? (
        <Spinner />
      ) : (
        <div className="bg-white sm:rounded-sm border-2 border-gray-300 shadow-md">
          <div className="flex items-center px-5 justify-between">
            <div className="py-4">
              <p className="font-bold text-base capitalize">order Details</p>
              <p className="font-bol text-sm capitalize">
                Details for order ID: {orderdetail?.order_id}
              </p>
            </div>
          </div>
          <div className="h-0.5 w-full bg-gray-300" />
          <div className="px-5">
            <div className="flex flex-row justify-between lg:items-center lg:flex-row md:flex-row">
              <div className="flex flex-col lg:flex-row md:flex-row my-2">
                <AiOutlineCalendar size={22} />
                <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                  <p className="text-sm capitalize mb-2">
                    {`${formatDateFull(orderdetail?.order_date)}`}
                  </p>
                  <p className="font-light text-sm capitalize">
                    order ID: {orderdetail?.order_id}
                  </p>
                </div>
              </div>
              <div className="my-2 capitalize">
                {isInThePast(new Date(orderdetail?.datecreated)) === true ? (
                  <span className="bg-green-400 capitalize p-1 px-4 rounded-md font-semibold text-sm text-white">
                    {orderdetail?.order_status}
                  </span>
                ) : (
                  <span className="bg-blue-400 capitalize p-1 px-4 rounded-md font-semibold text-sm text-white">
                    {orderdetail?.order_status}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-5 grid grid-cols-2 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3">
              <div className="flex flex-col lg:flex-row md:flex-row my-2">
                <AiOutlineCalendar size={22} />
                <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                  <p className="text-sm font-bold capitalize mb-2">
                    Aggregator
                  </p>
                  <p className="font-semibold text-sm capitalize">
                    {orderdetail?.order_user.firstname +
                      " " +
                      orderdetail?.order_user.lastname}
                  </p>
                  <p className="font-semibold text-sm ">
                    {orderdetail?.order_user.email}
                  </p>
                  <p className="font-semibold text-sm capitalize">
                    {orderdetail?.order_user.phone}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row md:flex-row my-2">
                <AiOutlineCalendar size={22} />
                <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                  <p className="text-sm font-bold capitalize mb-2">{orderdetail?.usertype}</p>
                  <p className="font-semibold text-sm capitalize">
                    {orderdetail?.harvestuser.firstname +
                      " " +
                      orderdetail?.harvestuser.lastname}
                  </p>
                  <p className="font-semibold text-sm ">
                    {orderdetail?.harvestuser.email}
                  </p>
                  <p className="font-semibold text-sm capitalize">
                    {orderdetail?.harvestuser.phone}
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-col lg:flex-row md:flex-row my-2">
                <AiOutlineCalendar size={22} />
                <div className="my-2 lg:my-0 md:my-0 lg:ml-4 md:ml-4  ">
                  <p className="text-sm font-bold capitalize mb-2">farm</p>
                  <p className="font-semibold text-sm capitalize">
                    {orderdetail?.farm.name}
                  </p>
                  <p className="font-semibold text-sm capitalize">
                    {orderdetail?.farm.region}
                  </p>
                </div>
              </div> */}
            </div>

            <div className="my-10">
              <div className="overflow-x-auto relative ">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs text-gray-700 uppercase bg-slate-100">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Harvest ID
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Weight
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderdetail?.harvests.map((item) => (
                      <tr className="bg-white border-b hover:bg-gray-200 dark:border-gray-700">
                        <th
                          scope="row"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item?.harvest_id}
                        </th>
                        <td className="py-4 px-6">GhC {item?.price}</td>
                        <td className="py-4 px-6">{item?.weight}</td>
                        <td className="py-4 px-6">{item?.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex space-x-3 sm:items-center lg:items-center md:items-center lg:justify-end sm:justify-end  md:justify-end m-4 ">
              <p className="font-bold">Total Value:</p>
              <p>GHS {orderdetail?.harvests[0].value}</p>
            </div>
            <div className="flex flex-col sm:flex-row lg:flex-row md:flex-row mt-2 lg:space-x-3 md:items-center lg:items-center justify-end m-4 ">
              <select
                onChange={(e) => setStatus(e.target.value)}
                className="bg-white mb-2 lg:mb-0 w-full lg:w-36 border border-gray-300 rounded-md shadow-sm py-2 px-4 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Change Status</option>
                <option value="Supplied">Mark as supplied </option>
                <option value="Cancelled">Cancel order</option>
              </select>

              <div className="mx-1" />
              <button onClick={(e)=>{e.preventDefault(); updateOrder()}} className="bg-amber-400 px-8 py-2 capitalize text-black rounded-md shadow-sm">
                <p>Save</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
