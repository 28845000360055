import React, { useState, useEffect } from "react";
import { BiFilter } from "react-icons/bi";
import { DashboardFilter } from "../../components";
import { AiFillEye, AiOutlinePlusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/api";
import SearchFIlter from "../../components/SearchFIlter";
import nodata from "../../assets/no-data.svg";
import api from "../../utils/api";
import Spinner from "../../components/Spinner";
import { Redirect,useLocation} from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [aggregators, setAggregators] = useState([]);
  const [query, setQuery] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const initialState = {
    region: "",
    district: "",
    community: "",
  };

  const [states, setState] = useState(initialState);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const [district, setDistrict] = useState();
  const [community, setCommunity] = useState();

  const getDistrict = async (name) => {
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCommunity = async (name) => {
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      console.log(data);

      setCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const handleRowClick = (row) => {
    // if (row.status === "pending") {
    //   Swal.fire(
    //     "No information now",
    //     "Aggregator has still not responded to your request",
    //     "question"
    //   );
    // } else {
    history.push("/admin/aggregatorDetails", { state: row });
  };

  useEffect(() => {
    getAllAggregators();
  }, []);

  // handle pagination
  const getAllAggregators = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-aggregators/?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setAggregators(response.data);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleNext = () => {
    if (aggregators.next) {
      setCurrentPage(currentPage + 1);
      getAllAggregators();
    }
  };

  const handlePrev = () => {
    if (aggregators.previous) {
      setCurrentPage(currentPage - 1);
      getAllAggregators();
    }
  };

  const SearchAggregator = () => {
    console.log(query);
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-aggregators/?search=${query}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.results);
        setAggregators(response.data);

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshFilter = () => {
    setState(initialState);
    getAllAggregators();
  };

  const filterAggregator = () => {
    console.log(query);
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-aggregators/?region=${states.region}&distict=${states.district}&community=${states.community}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.results);
        setAggregators(response.data);

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

    const { state } = useLocation();

  const user=JSON.parse(localStorage.getItem("user"))
  if(user.isfirstTime){
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }


  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <div className="w-full">
        <div>
          <div className="flex justify-end px-2">
            <button
              onClick={() => history.push("/admin/newAggregator")}
              className="rounded-lg bg-yellowcolor p-2 flex px-6  items-center "
            >
              <span className="pr-2">
                {" "}
                <AiOutlinePlusCircle />{" "}
              </span>
              Add aggregator
            </button>
          </div>
        </div>
        <div className="flex items-center mb-1">
          <p className="font-bold text-base">Filter: </p>
          <BiFilter size={30} />
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2">
          <div className="lg:mb-0 mb-2">
            <div className="">
              <SearchFIlter
                setQuery={setQuery}
                SearchAggregator={SearchAggregator}
                setRun={getAllAggregators}
              />
            </div>
          </div>
          <DashboardFilter
            regions={regions}
            district={district}
            handleChange={handleChange}
            community={community}
            getCommunity={getCommunity}
            getDistrict={getDistrict}
            filterAggregator={filterAggregator}
            setRun={refreshFilter}
          />
        </div>
        {loading ? (
          <div className="h-96 grid items-center justify-center">
            <Spinner animation="border" />
          </div>
        ) : aggregators?.results?.length > 0 && !loading ? (
          <>
            <div className="my-10">
              <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left bg-white">
                  <thead className="text-xs text-gray-700 uppercase bg-white">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Id
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Region
                      </th>
                      <th scope="col" className="py-3 px-6">
                        District
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Community
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      <th scope="col" className="py-3 px-6 ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="py-3 px-6 text-center" colSpan={7}>
                          <p className="text-lg font-semibold">Loading...</p>
                        </td>
                      </tr>
                    ) : (
                      aggregators?.results.map((data, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-gray-100 dark:border-gray-700"
                        >
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {data.id}
                          </th>
                          <td className="py-4 px-6">
                            {data.aggregator_user.firstname +
                              " " +
                              data.aggregator_user.lastname}
                          </td>
                          <td className="py-4 px-6">
                            {data.aggregator_user.region}
                          </td>
                          <td className="py-4 px-6">
                            {data.aggregator_user.district}
                          </td>
                          <td className="py-4 px-6">
                            {data.aggregator_user.community}
                          </td>
                          <td className="py-4 px-6">
                            <span className="bg-green-300 p-1 rounded-md font-semibold text-sm">
                              {data.status}
                            </span>
                          </td>
                          <td className="py-4 px-6">
                            <div className="flex items-center gap-4 justify-center">
                              <button
                                onClick={() => handleRowClick(data)}
                                className="outline-none"
                              >
                                <AiFillEye size={16} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-5">
              <div class="flex flex-col items-center">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                  Showing{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {aggregators?.results?.length}
                  </span>{" "}
                  to{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {aggregators?.results?.length}
                  </span>{" "}
                  of{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {aggregators?.count}
                  </span>{" "}
                  Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button
                    onClick={() => handlePrev()}
                    disabled={aggregators?.previous === null}
                    class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      class="mr-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Prev
                  </button>
                  <button
                    onClick={() => handleNext()}
                    disabled={aggregators?.next === null}
                    class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                    <svg
                      aria-hidden="true"
                      class="ml-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="h-96 grid items-center justify-center">
            <h1 className="text-center text-xl font-bold">
              <img
                src={nodata}
                alt="img"
                className="w-36 object-contain items-center"
              />
              <h1 className="mt-5 text-center text-lg font-bold">
                No data found
              </h1>
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
