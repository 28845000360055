import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { CustomModal, FormInput, FormOptions } from "../../components";
import FormSelect from "../../components/FormSelect";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "../../utils";
import Spinner from "../../components/Spinner";
import useAuth from "../../context/userContext";
import { BsConeStriped, BsPerson } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { Form } from "react-bootstrap";
import { BASE_URL } from "../../utils/api";
import { GrPowerReset } from "react-icons/gr";
import { BiFilter, BiUserCircle } from "react-icons/bi";
import { RiUserAddFill } from "react-icons/ri";

export default function NewAggregator() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { allusers } = useAuth();
  const [usersLeftover, setusersLeftover] = useState([]);
  const [details, setDetails] = useState();
  const [selectedUser, setSelectedUser] = useState("");
  const [ismulti, setIsMulti] = useState(false)
  const [selectType, setSelectType] = useState("single")
  const [selectedIds, setSelectedId] = useState(new Array(usersLeftover.length).fill(false))
  const [count, setCount] = useState(null)

  const DUMMY_DATA = [{}, {}, {}];

  const getUserDetail = (id) => {
    var axios = require("axios");
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}auth/getUserDetails/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setDetails(response.data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLeftUsers = () => {
    var axios = require("axios");
    setLoading(true);
    var data = "";
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}miller/getUnrelatedAggregators/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setCount(response.data.users.length)
        setusersLeftover(response.data.users);
        // setDetails(response.data.user);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };



  useEffect(() => {
    getLeftUsers();
  }, []);

  const creatAggregator = (id) => {
    setLoading(true);

    var data = JSON.stringify({
      aggregator: id,
    });

    const token = localStorage.getItem("token");

    var config = {
      method: "post",

      url: `${BASE_URL}miller/create-aggregator/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        getLeftUsers();
        SuccessNotification(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        ErrorNotification("An error occurred when adding this aggregator");
      });
  };

  const handleChange2 = (value) => {
    console.log(value)
    console.log(selectedIds)
   const updated=selectedIds.map((item,index)=> index===value ? !item : item )
   console.log(updated)
   setSelectedId(updated)

 

   updated.reduce((i,currentState,index)=>{
    console.log(currentState)
   })

  }



  return (
    <>
      <div className="w-full lg:px-10 px-5 py-5">
        <div className="flex items-center justify-between">
          <button onClick={() => history.goBack()} className="mb-5">
            <IoMdArrowRoundBack size={25} />
          </button>
        </div>
        <div className="flex items-center mb-1">
          <p className="font-bold text-base">FIlter: </p>
          <BiFilter size={30} />
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center justify-between  gap-2">
          <div className="lg:mb-0 mb-2">
            <SearchFilter />
          </div>
          <AggregatorFilter />
        </div>
      </div>
      <div className="w-full lg:px-10 px-5 h-full">
        <div className="bg-white py-2 rounded border-2 border-gray-100 shadow-md">
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left ">
              <thead className="text-xs text-gray-700 capitalize bg-white">
                <tr>
                  {/* <th scope="col" className="py-3 px-6">
                    <input
                      type={"checkbox"}
                      onClick={() => setIsMulti(!ismulti)}

                      className="border-2 mr-5 border-gray-700"
                    />
                  </th> */}
                  <th scope="col" className="py-3 px-6">
                    Name
                  </th>
                  <th scope="col" className="py-3 px-6">
                    phone number
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Community
                  </th>
                  <th scope="col" className="py-3 px-6">
                    region
                  </th>
                  <th scope="col" className="py-3 px-6">
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="py-3 px-6 text-center" colSpan={7}>
                      <p className="text-base font-semibold">Loading...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {usersLeftover?.length > 0 ? (
                      usersLeftover?.map((data, index) => (
                        <tr key={index} className={`bg-white border-b ${ismulti ? 'bg-blue-100' : null}`}>
                          {/* <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                          >
                            <div className="flex flex-row items-center gap-5">
                              <input
                              onChange={()=>handleChange2(index)}

                                type={"checkbox"}
                                value={index}
                                checked={ismulti || selectedIds[index]}

                                className="border-2 mr-5 border-gray-700"
                              />
                             
                            </div>
                          </th> */}

                          <td className="py-4 px-6">
                            {data.firstname + " " + data.lastname}
                          </td>
                          <td className="py-4 px-6">{data.phone}</td>
                          <td className="py-4 px-6">{data.community}</td>
                          <td className="py-4 px-6">{data.region}</td>
                          <td className="py-4 px-6">
                            <button
                              onClick={() => creatAggregator(data.id)}
                              className="bg-yellowcolor p-1 rounded-md flex flex-row items-center gap-2"
                            >
                              <span className="p-1 rounded-md font-semibold capitalize ">
                                add
                              </span>
                              <RiUserAddFill size={15} />
                            </button>
                          </td>
                        </tr>


                      ))
                    ) : (
                      <tr>
                        <td className="py-3 px-6 text-center" colSpan={7}>
                          <p className="text-base font-semibold">No aggregator to add yet</p>
                        </td>
                      </tr>
                    )}

                  </>
                )}

              </tbody>
            </table>
            {ismulti && <tr><td className="py-3 px-6 text-center" colSpan={1}>{count} row(s) selected</td></tr>}
          </div>
        </div>
      </div>
    </>
  );
}

const AggregatorFilter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filterAggregator,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <select
            name="community"
            onChange={handleChange}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {community?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filterAggregator()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun("run")}
          size={20}
        />
      </div>
    </div>
  );
};

const SearchFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchAggregator,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by email, firstname, lastname"
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searchAggregator()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            setQuery("");
            setRun("run");
          }}
          size={20}
        />
      </div>
    </div>
  );
};
