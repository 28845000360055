import React from "react";

const FormInput = ({
  value,
  placeholder,
  onChange,
  type,
  defaultValue,
  name,
  editable,
  errors,
  touched,
  onBlur,
}) => {
  return (
    <input
      type={type}
      value={value}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      editable={editable}
      onBlur={onBlur}
      
      className="w-full rounded-md border border-slate-400 p-1 px-2.5 text-base outline-none"
    />
  );
};

export default FormInput;
