import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import api from "../../utils/api";
import { SuccessNotification } from "../../utils";
import { ErrorNotification } from "../../utils";


const ExpirationVerification = () => {
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const onOpenModal = () => setShowUpdateModal(true);
  const onCloseModal = () => setShowUpdateModal(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)

  const sendverifyemail = async () => {
    setLoading(true)
    let payload = {
      email: email
    }
    try {
      const { data } = await api.post("auth/request-verify-email/", payload);

      if (data) {
        setLoading(false)
        onCloseModal()
        SuccessNotification("Verification email sent successfully");

      }
    } catch (error) {
      setLoading(false)
      throw ErrorNotification(error.response.data.error);
    }
  };


  return (
    <>
      <Modal open={showUpdateModal} onClose={onCloseModal} center>
        <div className="w-full">
          <div className="flex content-center justify-center mb-4 ">
            <img src={logo} alt="" className="" />
          </div>
          <h1 className="text-center font-semibold text-2xl mb-3 capitalize from-neutral-900">
            request new link
          </h1>
          <div className="w-full lg:w-full md:w-2/4 mx-auto">
            <p className="text-slate-400 text-base text-center">
              Enter email you used in signing up
            </p>
            <div className="my-6 flex items-center justify-center lg:flex-row md:flex-row flex-col  z-50">
              <input
                type="email"
                placeholder="Email"
                className="bg-white w-full border border-gray-300 focus:border-primary focus:ring-1 focus:ring-primary focus:outline-none text-base py-2 px-4 rounded-md"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="my-1 lg:my-0 md:my-0 lg:mx-2 md:mx-2" />
              <button
                onClick={() => sendverifyemail()}
                className="bg-yellowcolor capitalize hover:bg-primary  text-white font-medium py-2 w-full lg:w-1/2 md:w-1/2 rounded-md"
              >
                <p>{loading ? "Loading" : "Send link"}</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="w-screen  h-screen grid content-center justify-center background">
        <div className="p-5 rounded-md">
          <div className="flex content-center justify-center mb-4 ">
            <img src={logo} alt="" className="" />
          </div>
          <h1 className="text-center font-semibold text-3xl mb-3 capitalize from-neutral-900">
            reset link expired
          </h1>
          <div className="w-full lg:w-full md:w-2/4 mx-auto">
            <p className="text-slate-400 text-base text-center">
              The reset link you requested has expired. Please request a new
              one.
            </p>
            <div className="my-6 flex items-center justify-center lg:flex-row md:flex-row flex-col  z-50">
              <button
                onClick={onOpenModal}
                className="bg-yellowcolor capitalize hover:bg-primary  text-white font-medium py-2 px-10 rounded-md"
              >
                <p>request new link</p>
              </button>
              <div className="my-1 lg:my-0 md:my-0 lg:mx-2 md:mx-2" />
              <NavLink
                to="/"
                className=" capitalize text-primary underline  font-medium py-2 px-10"
              >
                <p>return to login</p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpirationVerification;
