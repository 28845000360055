import React from "react";

const CustomModal = ({ title, body, onClose, size,show,handleClose }) => {
  console.log(show)

  return (
    <>
      <div
        id="defaultModal"
        tabindex="-1"
        className={`${show} && 'overflow-y-auto bg-black bg-opacity-80 overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full justify-center items-center flex`}
        aria-modal="true"
        role="dialog"
      >
        <div className="relative  w-full p-4 max-w-lg h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex justify-between items-start p-4 rounded-t border-b ">
              <h3 className="text-lg font-semibold text-gray-900 ">
                {title}
              </h3>
              {onClose && <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
                onClick={() => handleClose()}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>}
            </div>
            <div className="p-6 ">
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {body}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
