import React, { useState } from "react";
import { FormInput, FormOptions } from "../../components";
import logo from "../../assets/logo-new.png";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import axios from "axios";
import api from "../../utils/api";
import { ErrorNotification } from "../../utils";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";

const gender = [
  {
    name: "male",
    value: "male",
  },
  {
    name: "female",
    value: "female",
  },
];

export const FirstForm = ({
  handleNext,
  values,
  handleChange,
  onSubmit,
  errors,
  touched,
  handleBlur,
}) => {
  const UserType = [
    {
      id: 1,
      name: "miller",
      showName: "Miller"
    },
    {
      id: 3,
      name: "aggregator",
      showName: "Aggregator"
    },
  ];

  const handleNextStep = (e) => {
    e.preventDefault();


    if (
      values.firstName === "" ||
      values.lastName === "" ||
      values.user_type === "" ||
      values.password === "" ||
      values.confirmPassword === ""
    ) {
      onSubmit();
      ErrorNotification("Please fill all fields");
    } else if (!errors.firstname && !errors.lastname && !errors.user_type && !errors.password && !errors.confirmpassword && (values.user_type === "miller" || values.user_type === "aggregator")) {

      handleNext();
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="container mt-10 md:mt-0 lg:mt-0">
      <div className="w-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:h-screen lg:h-screen">
        <div className="bg-[url('/src/background.png')] w-full bg-amber-400 h-full hidden md:block lg:block">
          <div className="bg-[url('/src/Rectangle.png')] h-screen bg-opacity-0 w-full bg-amber-400">
            <h1 className="px-20 pb-6 font-extrabold text-5xl leading-tight text-white opacity-100 absolute inset-x-0 bottom-0">
              Welcome <br />
              to Solidaridad <br />
              Harvest Alert,
            </h1>
          </div>
        </div>
        <div className="w-full bg-white grid content-center h-full">
          <div className="text-center grid content-center ">
            <div className="flex content-center justify-center">
              <img src={logo} alt="" className="h-28" />
            </div>
            <h1 className="py-3 font-bold text-base">
              Sign in to your acccount
            </h1>
          </div>
          <form className="lg:w-9/12 md:w-9/12 w-full lg:px-0 md:px-0 px-5 my-5 mt:10 content-center mx-auto justify-center">
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                first name:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <input
                  type={"text"}
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  defaultValue={values.firstname}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                {touched.firstname && errors.firstname && (
                  <div className="text-red-500 text-xs italic">
                    {errors.firstname}
                  </div>
                )}
              </div>
            </div>
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                last name:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <input
                  name="lastname"
                  type={"text"}
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  defaultValue={values.lastname}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                {touched.lastname && errors.lastname && (
                  <div className="text-red-500 text-xs italic">
                    {errors.lastname}
                  </div>
                )}
              </div>
            </div>
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                UserType:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <select
                  onChange={handleChange}
                  name={"user_type"}
                  value={values.user_type}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                >
                  <option>Select User type</option>
                  {UserType
                    ? UserType?.map((option, index) => {
                      return (
                        <option key={index} value={option.name}>
                          {option.showName}
                        </option>
                      );
                    })
                    : null}
                </select>
                {touched.user_type && errors.user_type && (
                  <div className="text-red-500 text-xs italic">
                    {errors.user_type}
                  </div>
                )}
              </div>
            </div>
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                password:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5 relative">
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={"***********"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}

                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                <div className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer">
                  <button
                    type="button"
                    className="outline-none focus:outline-none px-2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <RiEyeCloseLine className="text-black text-base" />
                    ) : (
                      <RiEyeLine className="text-black text-base" />
                    )}
                  </button>
                </div>
                {touched.password && errors.password && (
                  <div className="text-red-500 text-xs italic">
                    {errors.password}
                  </div>
                )}
              </div>
            </div>
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-full lg:w-32 md:w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                confirm password:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5 relative">
                <input
                  name="confirmpassword"
                  type={showPassword ? "text" : "password"}
                  placeholder={"***********"}
                  value={values.confirmpassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                {/* <div className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer">
                  <button
                    type="button"
                    className="outline-none focus:outline-none px-2"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <RiEyeCloseLine className="text-black text-base" />
                    ) : (
                      <RiEyeLine className="text-black text-base" />
                    )}
                  </button>
                </div>
                {touched.confirmpassword && errors.confirmpassword && (
                  <div className="text-red-500 text-xs italic">
                    {errors.confirmpassword}
                  </div>
                )} */}

                {touched.confirmpassword && errors.confirmpassword && (
                  <div className="text-red-500 text-xs italic">
                    {errors.confirmpassword}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mt-6 z-100 relative">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left"></div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <button
                  type="submit"
                  onClick={handleNextStep}
                  className="bg-amber-400 w-full hover:bg-amber-500 text-white  py-2 px-4 rounded-md font-bold"
                >
                  Next
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center mt-10  mb:mt-10 z-50 relative">
              <span className="items-center">Already have an account?</span>
              <NavLink
                to="/"
                className="items-center text-amber-400 cursor-pointer hover:text-primary"
              >
                &nbsp; Login
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const SecondForm = ({
  handleNext,
  handleBack,
  values,
  handleChange,
  onSubmit,
  resetLocation,
  errors,
  touched,
  handleBlur,
}) => {
  const regions = [
    "Select Region",
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const handleNextStep = (e) => {
    e.preventDefault();

    if (values.phone === "" || values.email === "" || !values.region === "") {
      onSubmit();
      ErrorNotification("Please fill all fields");
    }
    else if (!errors.phone && !errors.email && !errors.region && !errors.district && !errors.community && !(values.region === "Select Region")) {
      handleNext();
    }

  };

  const [district, setDistrict] = useState();
  const [community, setCommunity] = useState();
  const [districtLoading, setdistrictLoading] = useState(false);
  const [communityLoading, setCommunityLoading] = useState(false);
  const [districtOther, setdistrictOther] = useState(false)
  const [communityOther, setCommunityOther] = useState(false)
  const getDistrict = async (name) => {
    setdistrictLoading(true);
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      if (data) {
        setdistrictLoading(false);
      }

      setDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
      setdistrictLoading(false);
    }
  };

  const getCommunity = async (name) => {
    console.log(name)
    setCommunityLoading(true);
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      if (data) {
        setCommunityLoading(false);
      }

      setCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
      setCommunityLoading(false);
    }
  };

  return (
    <div className="container mt-10 md:mt-0 lg:mt-0">
      <div className="w-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:h-screen lg:h-screen">
        <div className="bg-[url('/src/background.png')] w-full bg-amber-400 hidden md:block lg:block">
          <div className="bg-[url('/src/Rectangle.png')] h-screen bg-opacity-0 w-full bg-amber-400">
            <h1 className="px-20 pb-6 font-extrabold text-5xl leading-tight text-white opacity-100 absolute inset-x-0 bottom-0">
              Welcome <br />
              to Solidaridad <br />
              Harvest Alert
            </h1>
          </div>
        </div>
        <div className="w-full bg-white grid content-center">
          <div className="text-center grid content-center ">
            <div className="flex content-center justify-center">
              <img src={logo} alt="" className="h-20" />
            </div>
            <h1 className="py-5 font-bold text-base">Create an acccount</h1>
          </div>
          <form className="lg:w-9/12 md:w-9/12 w-full lg:px-0 md:px-0 px-5 my-5 content-center mx-auto justify-center">
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                Phone number:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <div className="flex-auto">
                  <div className="flex border border-slate-400 rounded-md">
                    <span className="inline-flex items-center px-3 text-sm text-slate-500 bg-white border  border-slate-400">
                      +233
                    </span>
                    <input
                      type="text"
                      name="phone"
                      id="website-admin"
                      className="w-full py-1.5 px-3 outline-none"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                {touched.phone && errors.phone && (
                  <div className="text-red-500 text-xs italic">
                    {errors.phone}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                Email:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <input
                  type={"email"}
                  name="email"
                  id="website-admin"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                {touched.email && errors.email && (
                  <div className="text-red-500 text-xs italic">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                region:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <select
                  name={"region"}
                  defaultValue={values.region}
                  onChange={handleChange}
                  onInput={(e) => { getDistrict(e.target.value) }}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                >
                  {regions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {touched.region && errors.region && (
                  <div className="text-red-500 text-xs italic">
                    {errors.region}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                district:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                {districtLoading && (
                  <p className="text-xs flex items-end text-gray-500 mb-1">
                    loading...
                  </p>
                )}
                <select
                  onInput={(e) => getCommunity(e.target.value)}
                  name={"district"}
                  defaultValue={values.district}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                >


                  <option>{values.district !== "" ? values.district : "Select district"}</option>

                  {district?.map((option, index) => {
                    return (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    );
                  })
                  }

                </select>
                {touched.district && errors.district && (
                  <div className="text-red-500 text-xs italic">
                    {errors.district}
                  </div>
                )}
              </div>


            </div>

            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                community:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                {communityLoading && (
                  <p className="text-xs flex items-end text-gray-500 mb-1">
                    loading...
                  </p>
                )}
                <select
                  name={"community"}
                  defaultValue={values.community}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                >
                  <option>{values.community !== "" ? values.community : "Select community"}</option>
                  {community?.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    );
                  })}

                </select>
                {touched.community && errors.community && (
                  <div className="text-red-500 text-xs italic">
                    {errors.community}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mt-6 z-50 relative">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left"></div>
              <div className="flex flex-row justify-between lg:w-3/5 space-x-2">
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleBack();
                    }}
                    className="bg-amber-400 w-full hover:bg-amber-500 text-white  py-2 px-4 rounded-md font-bold"
                  >
                    Back
                  </button>
                </div>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <button
                    onClick={handleNextStep}
                    className="bg-amber-400 w-full hover:bg-amber-500 text-white  py-2 px-4 rounded-md font-bold"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center mt-10  mb:mt-10 z-50 relative">
              <span className="items-center">Already have an account?</span>
              <NavLink
                to="/"
                className="items-center text-amber-400 cursor-pointer hover:text-primary"
              >
                &nbsp; Login
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const LastForm = ({
  SIGNUP,
  handleBack,
  values,
  handleChange,
  onSubmit,
  errors,
  touched,
  handleBlur,

  setGender,
  loading,
}) => {
  const navigate = useLocation();
  
  const [selectedOption, setSelectedOption] = useState(() => {
    return values.gender || null;
  });


  const onValueChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };

  console.log(values.gender)

  const handleSubmit = (e) => {
    console.log(values.dob)
    e.preventDefault();
    if (values.dob === "" || values.gender === "") {
      ErrorNotification("Please fill all fields");
    } else {
      onSubmit()
    }
  };

  return (
    <div className="container mt-10 md:mt-0 lg:mt-0">
      <div className="w-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:h-screen lg:h-screen">
        <div className="bg-[url('/src/background.png')] w-full bg-amber-400 hidden md:block lg:block">
          <div className="bg-[url('/src/Rectangle.png')] h-screen bg-opacity-0 w-full bg-amber-400">
            <h1 className="px-20 pb-6 font-extrabold text-5xl leading-tight text-white opacity-100 absolute inset-x-0 bottom-0">
              Welcome <br />
              to Solidaridad <br />
              Harvest Alert
            </h1>
          </div>
        </div>
        <div className="w-full bg-white grid content-center">
          <div className="text-center grid content-center ">
            <div className="flex content-center justify-center">
              <img src={logo} alt="" className="h-20" />
            </div>
            <h1 className="py-5 font-bold text-base">Create an acccount</h1>
          </div>
          <form className="lg:w-9/12 md:w-9/12 w-full lg:px-0 md:px-0 px-5 my-5 content-center mx-auto justify-center">
            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none lg:w-32 md:w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                Ghana Card Number:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <input
                  type={"text"}
                  name="ghana_card_number"
                  value={values.ghana_card_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                {touched.ghana_card_number && errors.ghana_card_number && (
                  <div className="text-red-500 text-xs italic">
                    {errors.ghana_card_number}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                Date of birth:
              </div>
              <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                <input
                  name="dob"
                  onChange={handleChange}
                  value={values.dob}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  type={"date"}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:border-transparent"
                />
                {touched.dob && errors.dob && (
                  <div className="text-red-500 text-xs italic">
                    {errors.dob}
                  </div>
                )}
              </div>
            </div>

            <div className="md:flex lg:flex mb-5 md:mb-2 lg:mb-2">
              <div className="flex-none items-center w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left">
                Gender:
              </div>
              <div className="flex flex-row items-center">
                {gender.map((g) => (
                  <div  className="flex-initial mt-2 flex items-center w-full md:w-3/5 lg:w-3/5">
                    <div className="flex items-center mb-4">
                      <input
                        errors={errors}
                        touched={touched}
                        onBlur={handleBlur}
                        checked={selectedOption && selectedOption  === g.value}
                        id={`gender-${g.value}`}
                        type="radio"
                        name="gender"
                        value={g.value}
                        onChange={handleChange}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-1"
                      />

                      <label htmlFor={`gender-${g.value}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 capitalize">
                        {g.value}
                      </label>
                    </div>
                    {touched.gender && errors.gender && (
                      <div className="text-red-500 text-xs italic">
                        {errors.gender}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="md:flex lg:flex mt-10 z-50 relative">
              <div className="flex-none w-32 md:h-14 sm:h-14 capitalize font-medium text-slate-400 text-left"></div>
              <div className="flex flex-row justify-between lg:w-3/5 space-x-2">
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleBack();
                    }}
                    className="bg-amber-400 w-full hover:bg-amber-500 text-white  py-2 px-4 rounded-md font-bold"
                  >
                    Back
                  </button>
                </div>
                <div className="flex-initial w-full md:w-3/5 lg:w-3/5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(e)
                    }}
                    className="bg-amber-400 w-full hover:bg-amber-500 text-white  py-2 px-4 rounded-md font-bold"
                  >
                    {loading ? "Signing up..." : "Sign up"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center mt-10  mb:mt-10 z-50 relative">
              <span className="items-center">Already have an account?</span>
              <NavLink
                to="/"
                className="items-center text-amber-400 cursor-pointer hover:text-primary"
              >
                &nbsp; Login
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
