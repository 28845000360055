import React from "react";

const SMSModal = ({
  showMessageModal,
  setShowMessageModal,
  state,
  onClick,
  sendmsgLoading,
  handleChange,
  setSMSLoading,
}) => {
  return (
    <div
      id="popup-modal"
      tabindex="-1"
      aria-hidden="true"
      className={`
        ${showMessageModal ? "block" : "hidden"}
        bg-black  bg-opacity-80 h-screen grid items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 transition duration-300 ease-in-out`}
    >
      <div class="relative p-3 w-96 max-w-md h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow">
          <button
            onClick={() => setShowMessageModal(false)}
            type="button"
            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <div class="p-6">
            <div class="my-4">
              <div className="mb-3">
                <lable for="title" className="mb-2 capitalize">
                  title
                </lable>
                <input
                  type={"text"}
                  placeholder="Title"
                  name="title"
                  value={state.title}
                  onChange={handleChange}
                  className="bg-gray-100 p-1 rounded-md w-full"
                />
              </div>
              <div className="mb-3">
                <lable for="title" className="mb-2 capitalize">
                  message
                </lable>
                <textarea
                  name="message"
                  value={state.message}
                  onChange={handleChange}
                  className="bg-gray-100 p-1 rounded-md w-full"
                />
              </div>
              <div className="mt-2">
                <button
                  onClick={onClick}
                  className="capitalize bg-yellowcolor text-white w-full px-4 py-2 rounded-md"
                >
                  {setSMSLoading ? "Sending...." : "Send SMS"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSModal;
