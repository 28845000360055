import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdArrowRoundBack } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import UpdateModal from "../../../../components/UpdateModal";
import { GrPowerReset } from "react-icons/gr";
import api, { BASE_URL } from "../../../../utils/api";
import { ErrorNotification, SuccessNotification } from "../../../../utils";
import axios from "axios";
import DeleteModal from "../../../../components/DeleteModal";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [district, setDistrict] = React.useState([]);
  const [communities, setCommunity] = React.useState([]);
  const [selectedCommunity, setSelectedCommunity] = React.useState("");
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);

  const onOpenModal = () => setShowUpdateModal(true);
  const onCloseModal = () => setShowUpdateModal(false);

  const initialState = {
    region: "",
    district: "",
    community: "",
  };
  const [states, setState] = React.useState(initialState);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const reset = () => {
    setState(initialState);
  };

  const getDistrict = async (name) => {
    try {
      let p = {
        region: name,
      };
      setLoadingDistrict(true);
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));

      setDistrict(data.data.districts);
      setLoadingDistrict(false);
    } catch (error) {
      setLoadingDistrict(false);
      console.log(error.message);
    }
  };

  const filter = async () => {
    try {
      let p = {
        district: states.district,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );

      setCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const handleNewCommunity = () => {
    history.push("/admin/new-community");
  };

  const handleDelete = () => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      item: "community",
      region: states.region,
      name: selectedCommunity,
      district:states.district
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/deleteItem/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        SuccessNotification("Item deleted successfully");
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const updateCommunity = async (name) => {
    let token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;

    let payload = {
      item: "community",
      region: states.region,
      district: states.district,
      name: selectedCommunity,
      community: states.community,
    };

    console.log(payload);

    try {
      const data = await api.post(
        `aggregator/updateItem/`,
        JSON.stringify(payload)
      );
      filter()
      SuccessNotification("community updated successfully");
    } catch (error) {
      ErrorNotification("An error occured while adding community");
      console.log(error.message);
    }
  };

  return (
    <>
      <Modal open={showUpdateModal} onClose={onCloseModal} center>
        <div className="w-full">
          <div className="w-fit flex justify-between items-center">
            <h1 className="text-lg font-semibold ">Update Community</h1>
          </div>
        </div>
        <div className="w-full mt-4 grid lg:grid-cols-2 lg:items-center md:grid-cols-2 md:items-center gap-2">
          <div className="w-full">
            <label className="text-base font-semibold mb-1">
              Select Region
            </label>
            <select
              name="region"
              onInput={(e) => getDistrict(e.target.value)}
              value={states.region}
              disabled="true"
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md px-4 py-2 "
            >
              <option value="">Select Region</option>
              {regions.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full">
            <label className="text-base font-semibold mb-1">
              Select District
            </label>
            <select
              name="region"
              onInput={(e) => getDistrict(e.target.value)}
              value={states.district}
              disabled="true"
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md px-4 py-2 "
            >
              {loadingDistrict ? (
                <option value="">Loading...</option>
              ) : (
                district.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))
              )}
            </select>
          </div>

          <div className="w-full">
            <label className="text-base font-semibold mb-1">
              {selectedCommunity}
            </label>
            <input
              type="text"
              name="community"
              defaultValue={selectedCommunity}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md px-4 py-2 "
            />
          </div>
        </div>

        <div class="my-4">
          <div className="flex flex-row items-center gap-4">
            <button
              onClick={() => {
                updateCommunity();
                onCloseModal();
              }}
              className="capitalize bg-yellowcolor text-white w-full px-4 py-2 rounded-md"
            >
              Update
            </button>
            <button
              onClick={() => {
                onCloseModal();
              }}
              className="capitalize bg-[#e41d21] text-white w-full px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <DeleteModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        title="Community"
        handleDelete={() => handleDelete()}
      />
      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex flex-row items-center justify-between px-2">
            <button onClick={() => history.goBack()}>
              <IoMdArrowRoundBack size={25} />
            </button>
            <div className="flex justify-end px-2">
              <button
                onClick={handleNewCommunity}
                className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
              >
                <IoMdAddCircleOutline size={19} />
                add new community
              </button>
            </div>
          </div>
          <div className="my-3">
            <Filter
              handleChange={handleChange}
              getDistrict={getDistrict}
              district={district}
              regions={regions}
              filter={filter}
              setRun={reset}
            />
          </div>
        </div>
        <div className="my-5">
          {loading ? (
            <div className="h-96 grid items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-xs bg-white">
                <thead class="text-xs text-left text-gray-700 uppercase bg-white">
                  <tr>
                    <th scope="col" class="py-3 px-3">
                      <input
                        type={"checkbox"}
                        className="border-2 mr-5 border-gray-700"
                      />
                      Communities
                    </th>
                    <th scope="col" class="py-3 px-3"></th>
                    {/* <th scope="col" class="py-3 px-3">
                      actions
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {communities?.map((data, index) => (
                    <tr
                      className={`${
                        index % 2 === 0
                          ? "bg-white border-b dark:border-gray-200"
                          : "bg-slate-100 border-b"
                      } hover:bg-gray-100 capitalize text-left`}
                      onClick={() => {}}
                    >
                      <th
                        scope="row"
                        className="capitalize py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <input
                          type={"checkbox"}
                          className="capitalize border-2 mr-5 border-gray-700"
                        />
                        {data}
                      </th>

                      <td class="py-4 px-3"></td>
                      <td class="py-4 px-3">
                        {/* <div className="flex items-center gap-4 justify-center">
                          <button onClick={() => { setSelectedCommunity(data); setShowUpdateModal(true)}}>
                            <MdModeEdit size={16} />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedCommunity(data);
                              setShowConfirmModal(true);
                            }}
                          >
                            <AiFillDelete size={15} color="red" />
                          </button>
                        </div> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Filter = ({
  regions,
  district,
  community,
  getDistrict,
  handleChange,
  filter,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-2 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2  border-slate-400 p-1 outline-none"
          >
            <option>Select region</option>
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            // onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2  border-slate-400 p-1 outline-none"
          >
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filter()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun()}
          size={20}
        />
      </div>
    </div>
  );
};

export default Index;
