import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdArrowRoundBack } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import UpdateModal from "../../../../components/UpdateModal";
import api from "../../../../utils/api";
import { GrPowerReset } from "react-icons/gr";
import DeleteModal from "../../../../components/DeleteModal";
import { BASE_URL } from "../../../../utils/api";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "../../../../utils";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { BsPlusCircle } from "react-icons/bs";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [district, setDistrict] = React.useState([]);
  const onOpenModal = () => setShowUpdateModal(true);
  const onCloseModal = () => setShowUpdateModal(false);
  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const initialState = {
    region: "",
  };
  const [states, setState] = React.useState(initialState);
  const [district2, setDistrict2] = React.useState([]);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [region, setRegion] = React.useState("");
  const [selectedDistrict, setSelectedDistrict] = React.useState("");
  const [rowsData, setRowsData] = React.useState([]);
  const [newName, setNewName] = React.useState(null);


  const addTableRows = () => {
    const rowsInput = {
      community: "",
    };
    setRowsData((rowsData) => [...rowsData, rowsInput]);
  };


  const reset = () => {
    setState(initialState);
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const getDistrict = async () => {
    try {
      let p = {
        region: states.region,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));

      setDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDelete = () => {
    let token = localStorage.getItem("token");
    setLoading(true);

    var data = JSON.stringify({
      item: "district",
      region: states.region,
      district: selectedDistrict,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/deleteItem/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        SuccessNotification("Item deleted successfully");
        getDistrict();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };


  const updateDistrict = async (name) => {
    let token = localStorage.getItem("token");
    let communities = [];
    api.defaults.headers.Authorization = `Bearer ${token}`;
    for (var i = 0; i < rowsData.length; i++) {
      console.log(rowsData[i])
      communities.push(rowsData[i].community)

    }



    let payload =
    {
      "item": "district",
      "region": states.region,
      "district": selectedDistrict,
      "name": newName === null ? selectedDistrict : newName,
      "communities": communities
    }

    console.log(payload)

    try {
      const data = await api.post(`aggregator/updateItem/`, JSON.stringify(payload));
      getDistrict()
      SuccessNotification("District updated successfully")
    } catch (error) {
      ErrorNotification("An error occured while adding district")
      console.log(error.message);
    }
  };



  const handleNewDistrict = () => {
    history.push("/admin/new-district");
  };


  return (
    <>
      <Modal open={showUpdateModal} onClose={onCloseModal} center>
        <div className="w-full">
          <div className="w-fit flex justify-between items-center">
            <h1 className="text-lg font-semibold ">Update District</h1>
          </div>
        </div>
        <div className="w-full mt-4 grid lg:grid-cols-2 lg:items-center md:grid-cols-2 md:items-center gap-2">


          <div className="w-full">
            <label className="text-base font-semibold mb-1">District New Name</label>
            <input
              type="text"
              defaultValue={selectedDistrict}
              onChange={(evnt) => setNewName(evnt.target.value)}
              className="border-2 capitalize border-gray-300 rounded-lg p-1.5 w-full lg:w-72"
            />

          </div>
        </div>
        <div class="mt-2 w-full">
          <label className="text-base font-semibold mb-1">Community</label>
          <div className="w-full grid lg:grid-cols-2 lg:items-center md:grid-cols-2 md:items-center gap-2">
            {rowsData.map((item, index) => (
              <input
                value={item.name}
                type="text"
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...rowsData];
                  list[index].community = value;
                  setRowsData(list);

                }}
                className="border-2 border-gray-300 rounded-lg p-2 w-full"
              />
            ))}
          </div>
          <button onClick={(e) => addTableRows()}>
            <BsPlusCircle />
          </button>
        </div>
        <div class="my-4">
          <div className="flex flex-row items-center gap-4">
            <button
              onClick={() => {
                updateDistrict();
                onCloseModal();
              }}
              className="capitalize bg-yellowcolor text-white w-full px-4 py-2 rounded-md"
            >
              Update
            </button>
            <button
              onClick={() => {
                onCloseModal();
              }}
              className="capitalize bg-[#e41d21] text-white w-full px-4 py-2 rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <DeleteModal
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        title="district "
        handleDelete={() => handleDelete()}
      />

      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex flex-row items-center justify-between px-2">
            <button onClick={() => history.goBack()}>
              <IoMdArrowRoundBack size={25} />
            </button>
            <div className="flex justify-end px-2">
              <button
                onClick={handleNewDistrict}
                className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
              >
                <IoMdAddCircleOutline size={19} />
                add new district
              </button>
            </div>
          </div>
          <div className="my-3">
            <Filter
              handleChange={handleChange}
              getDistrict={getDistrict}
              district={district}
              regions={regions}
              setRun={reset}
            />
          </div>
        </div>
        <div className="my-5">
          {loading ? (
            <Spinner />
          ) : (
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-xs bg-white">
                <thead class="text-xs text-left text-gray-700 uppercase bg-white">
                  <tr>
                    <th scope="col" class="py-3 px-3">
                      <input
                        type={"checkbox"}
                        className="border-2 mr-5 border-gray-700"
                      />
                      district
                    </th>
                    <th scope="col" class="py-3 px-3"></th>
                    <th scope="col" class="py-3 px-3">
                      actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {district?.map((data, index) => (
                    <tr
                      className={`${index % 2 === 0
                        ? "bg-white border-b dark:border-gray-200"
                        : "bg-slate-100 border-b"
                        } hover:bg-gray-100 capitalize text-left`}
                      onClick={() => { }}
                    >
                      <th
                        scope="row"
                        className="capitalize py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <input
                          type={"checkbox"}
                          className="capitalize border-2 mr-5 border-gray-700"
                        />
                        {data?.name}
                      </th>

                      <td class="py-4 px-3"></td>
                      <td class="py-4 px-3">
                        <div className="flex items-center gap-4 justify-center">
                          <button
                            onClick={() => {
                              setSelectedDistrict(data.name);
                              onOpenModal();
                            }}
                          >
                            <MdModeEdit size={16} />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedDistrict(data.name);
                              setShowConfirmModal(true);
                            }}
                          >
                            <AiFillDelete size={15} color="red" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Filter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filter,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-2 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2  border-slate-400 p-1 outline-none"
          >
            <option>Select region</option>
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-2 lg:mb-0 md:mb-0 w-full">
          <button
            onClick={() => getDistrict()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun()}
          size={20}
        />
      </div>
    </div>
  );
};

export default Index;
