import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdArrowRoundBack } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import UpdateModal from "../../../../components/UpdateModal";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);

  const handleNewRegion = () => {
    history.push("/admin/new-region");
  };

  return (
    <>
      <UpdateModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
      />
      <div className="w-full lg:px-6 px-2 h-full py-10">
        <div className="w-full">
          <div className="flex flex-row items-center justify-between px-2">
            <button onClick={() => history.goBack()}>
              <IoMdArrowRoundBack size={25} />
            </button>
            {/* <div className="flex justify-end px-2">
              <button
                onClick={handleNewRegion}
                className="rounded-lg bg-yellowcolor p-2 flex items-center px-6 gap-2 capitalize"
              >
                <IoMdAddCircleOutline size={19} />
                add new region
              </button>
            </div> */}
          </div>
        </div>
        <div className="my-5">
          {loading ? (
            <Spinner />
          ) : (
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-xs bg-white">
                <thead class="text-xs text-left text-gray-700 uppercase bg-white">
                  <tr>
                    <th scope="col" class="py-3 px-3">
                      <input
                        type={"checkbox"}
                        className="border-2 mr-5 border-gray-700"
                      />
                      Region
                    </th>
                    <th scope="col" class="py-3 px-3"></th>
                    {/* <th scope="col" class="py-3 px-3">
                      actions
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {regions?.map((data, index) => (
                    <tr
                      className={`${
                        index % 2 === 0
                          ? "bg-white border-b dark:border-gray-200"
                          : "bg-slate-100 border-b"
                      } hover:bg-gray-100 capitalize text-left`}
                    >
                      <th
                        scope="row"
                        className="capitalize py-4 px-3 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <input
                          type={"checkbox"}
                          className="capitalize border-2 mr-5 border-gray-700"
                        />
                        {data}
                      </th>

                      <td class="py-4 px-3"></td>
                      {/* <td class="py-4 px-3">
                        <div className="flex items-center gap-4 justify-center">
                          <button>
                            <MdModeEdit size={16} />
                          </button>
                          <button>
                            <AiFillDelete size={15} color="red" />
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
