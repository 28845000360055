import { BsTrashFill } from "react-icons/bs";
import { BsPencil } from "react-icons/bs";
import React, { useEffect } from "react";

function TableRows({ rowsData, deleteTableRows, handleChange }) {
  return rowsData.map((data, index) => {
    const { community } = data;

    return (
      <div  key={index} className="w-full mb-2">
        <tr key={index} className="w-full">
          <td className="w-full">
            <input
              type="text"
              value={community}
              onChange={(evnt) => handleChange(index, evnt)}
              name="community"
              className="border-2 capitalize border-gray-300 rounded-lg p-1.5 w-full lg:w-72"
            />
          </td>

          <td>
            <button
              className="btn btn-outline-danger font-loader px-2"
              onClick={(e) => {
                e.preventDefault();
                deleteTableRows(index);
              }}
            >
              {<BsTrashFill />}
            </button>
          </td>
        </tr>
      </div>
    );
  });
}
export default TableRows;
