import React from "react";

const ReportFIlter = ({ selectedItem, handleChange, filter }) => {

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const status = ["Available", "Pending", "Responded", "Ordered", "Inactive"];

  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <input
            type={"number"}
            onChange={(e) => handleChange(e)}
            name="no_of_records"
            placeholder="No. of records"
            className="text-slate-500 text-center border-white bg-white p-1 rounded-sm w-full outline-none "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full flex items-center">
          <label className="text-slate-500 text-center pr-2">From</label>
          <input
            type={"date"}
            onChange={(e) => handleChange(e)}
            name="start_date"
            placeholder="From - To"
            className="text-slate-500 text-center border-white bg-white p-1 rounded-sm w-full outline-none "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full flex items-center ">
          <label className="text-slate-500 text-center pr-2">To</label>
          <input
            type={"date"}
            name="end_date"
            onChange={(e) => handleChange(e)}
            placeholder="To"
            className="text-slate-500 text-center border-white bg-white p-1 rounded-sm w-full outline-none "
          />
        </div>

        {selectedItem !== "harvest" ? <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <select name="region" onChange={(e) => handleChange(e)} className="w-full rounded-sm border-2 text-slate-400 border-white p-1 outline-none">

            <option key={"1"} value={"Select region"}> Select region</option>
            {regions.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div> : null}


        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <select name="status" onChange={(e) => handleChange(e)}  className="w-full rounded-sm border-2 text-slate-400 border-white p-1 outline-none">
            {status.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mx-10"></div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full ">
          <button type="button" onClick={() => filter()} className="bg-yellowcolor text-black p-1 rounded-md w-full">
            <span className="text-base font-semibold capitalize">generate</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportFIlter;
