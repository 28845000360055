import React, { useState, useEffect } from "react";
import { bool, func, string } from "prop-types";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
const SidebarItem = (props) => {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);

  const {
    url,
    index,
    icon,
    title,
    submenu,
    submenuItems,
    openSubmenu,
    setOpenSubMenu,
    toggleDrawer,
  } = props;

  return (
    <div>
      <NavLink
        onClick={() => {
          submenu && setOpenSubMenu(!openSubmenu);
          if (window.innerWidth <= 668 && title !== "Campaign") {
            toggleDrawer();
          }
        }}
        to={!submenu && url}
        key={index}
        activeClassName={
          "border-black text-black border-l-4  bg-yellowcolor px-2 pl-4 pt-4 hover:tex-black capitalize"
        }
        className={`
        flex
        p-2 cursor-pointer
        hover:text-slate-400
        w-full
         items-stretch px-6 space-x-4 mt-4 capitalize`}
      >
        <img alt="" src={icon} className={"h-[18px]"} />
        <span className="relative bottom-1 text-base"> {title} </span>
        {submenu && (
          <BsChevronDown
            className="absolute right-4"
            onClick={() => setOpenSubMenu(!openSubmenu)}
          />
        )}
      </NavLink>
      {submenu && openSubmenu && (
        <ul>
          {submenuItems.map((submenu, index) => (
            <NavLink
            onClick={() => {
              if (window.innerWidth <= 668) {
                toggleDrawer();
              }
            }}
              key={index}
              to={submenu.href}
              activeClassName={
                "border-black text-black border-l-4 bg-yellowcolor px-2 pl-4 pt-2 capitalize"
              }
              className={`
            flex
            cursor-pointer
            hover:text-slate-400
            w-full
            items-center
            px-8
            p-2
            mt-2
            space-x-2 first-line 
            capitalize
            `}
            >
              <img alt="" src={submenu.icon} className={"h-[16px]"} />
              <span className="relative  text-sm"> {submenu.title} </span>
            </NavLink>
          ))}
        </ul>
      )}
    </div>
  );
};

SidebarItem.propTypes = {
  active: bool,
  icon: func,
  title: string,
};

export default SidebarItem;
