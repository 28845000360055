import React from "react";

const ContactUsForm = () => {
  return (
    <form className="w-full">
      <div className="lg:flex md:flex w-full md:space-x-8 lg:space-x-8">
      <div className="mb-6 w-full">
        <label htmlFor="name" className="block font-medium mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="w-full border border-gray-400 py-2 px-4 rounded-lg focus:outline-none focus:border-primary"
        />
      </div>
      <div className="mb-6 w-full">
        <label htmlFor="email" className="block font-medium mb-2">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="w-full border border-gray-400 py-2 px-4 rounded-lg focus:outline-none focus:border-primary"
        />
      </div>
      </div>
      <div className="mb-6">
        <label htmlFor="message" className="block font-medium mb-2">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          rows="6"
          className="w-full border border-gray-400 py-2 px-4 rounded-lg focus:outline-none focus:border-primary"
        ></textarea>
      </div>
      <div>
        <button
          type="submit"
          className="bg-yellowcolor black py-2 px-6 font-bold rounded-lg hover:bg-secondary"
        >
          Submit Issue
        </button>
      </div>
    </form>
  );
};

export default ContactUsForm;
