import React from "react";
import bell from "../assets/Alarm.png";
import useAuth from "../context/userContext";
import LogoComponent from "./sidebar/logocomponent";
import { VscThreeBars } from "react-icons/vsc";
import { BsToggle2Off, BsToggleOff, BsToggleOn } from "react-icons/bs";

const Navbar = ({
  setShowNotification,
  showNotification,
  messages,
  unreadmessages,
  setSidebar,
  showSidebar,
}) => {
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const user=JSON.parse(localStorage.getItem("user"))
  const fullname=user?.firstname+" "+user?.lastname

  const toggleDrawer = () => {
    setSidebar(!showSidebar);
  };

  return (
    <div className="bg-white w-full flex flex-row justify-between h-[56px] items-center py-4 px-5 md:px-10 lg:px-10 shadow-md fixed top-0 left-0 right-0 z-40">
      <div className="flex flex-row items-center">
        <button onClick={toggleDrawer} className="outline-none">
          {!showSidebar && (
            <div className="flex flex-row items-center">
              <LogoComponent />
              <button onClick={toggleDrawer} className="outline-none ml-1">
                <BsToggleOff size={24} />
              </button>
            </div>
          )}
        </button>
        {/* <div className="w-1"></div>
        <LogoComponent /> */}
      </div>
      <div className="flex items-center">
        <button>
          <img
            src={user?.profile_img}
            className="w-10 h-10 object-cover bg-blue-500 rounded-full"
            alt=""
          />
        </button>
        <p className="pl-2 font-semibold text-base capitalize lg:block md:block sm:block hidden">
          {user && truncate(fullname, 15)}
        </p>
        <button
          onClick={() => setShowNotification(!showNotification)}
          className="text-black ml-4 relative"
        >
          {unreadmessages?.length > 0 && (
            <p className="font-bold absolute -top-3 -right-2 bg-yellowcolor rounded-full text-black text-xs px-2 py-1">
              {unreadmessages?.length > 0 && unreadmessages?.length}
            </p>
          )}
          <img src={bell} alt={bell} />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
