import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { formatDateFull } from "../../utils/Formatter";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import nosms from "../../assets/sms.svg";

const AllSentSMS = () => {
  const history = useHistory();
  const [sms, setSms] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const getSentSms = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",

      url: `${BASE_URL}miller/get-sent-sms/?page=${currentPage}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: "",
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.results.sms)
        setSms(response.data.results.sms);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handleNext = () => {
    if (sms.next) {
      setCurrentPage(currentPage + 1);
      getSentSms();
    }
  };



  const handlePrev = () => {
    if (sms.previous) {
      setCurrentPage(currentPage - 1);
      getSentSms();
    }
  };

  useEffect(() => {
    getSentSms();
  }, []);

  const handleRowClick = (row) => {
    history.push("/admin/SMSDetail", { state: row });
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      {loading ? (
        <div className="h-96 grid items-center justify-center">
          <Spinner />
        </div>
      ) : sms?.length > 0 && !loading ? (
        <>
          <div className="w-full bg-white shadow-md sm:rounded-lg">
            {/* table */}
            <p className="px-6 pt-6 text-base capitalize font-bold">Sent SMS</p>
            <div className="my-5">
              <div className="overflow-x-auto relative">
                <table className="w-full text-sm text-left bg-white">
                  <thead className="text-xs text-gray-700 capitalize bg-white">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        <input
                          type={"checkbox"}
                          className="border-2 mr-5 border-gray-700"
                        />
                        ID
                      </th>
                      <th scope="col" className="py-3 px-6">
                        to
                      </th>

                      <th scope="col" className="py-3 px-6">
                        Message Type
                      </th>

                      <th scope="col" className="py-3 px-6">
                        message
                      </th>
                      <th scope="col" className="py-3 px-6">
                        send date
                      </th>
                      <th scope="col" className="py-3 px-6">
                        actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sms && sms.map((data, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0
                            ? "bg-white border-b dark:border-gray-700 hover:bg-gray-400 cursor-pointer"
                            : "bg-slate-100 border-b hover:bg-gray-100 cursor-pointer"
                        }`}
                      >
                        <th
                          scope="row"
                          className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                        >
                          <input
                            type={"checkbox"}
                            className="capitalize border-2 mr-5 border-gray-700"
                          />
                          {data.id}
                        </th>
                        <td className="capitalize py-4 px-6">
                          {data.recipientGroup}
                        </td>

                        <td className="capitalize py-4 px-6">
                          {data.campaign_type}
                        </td>

                        <td className="capitalize py-4 px-6">
                          {truncate(data.message, 30)}
                        </td>
                        <td className="capitalize py-4 px-6">
                          {formatDateFull(data.sent_date)}
                        </td>
                        <td className="capitalize py-4 px-6">
                          <div className="flex items-center gap-4 justify-center">
                            <button
                              className="outline-none"
                              onClick={() => handleRowClick(data)}
                            >
                              <AiFillEye color="black" size={16} />
                            </button>
                            <button className="outline-none">
                              <AiFillDelete size={16} color="red" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {sms?.length > 0 && (
            <div className="mt-5">
              <div class="flex flex-col items-center">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                  Showing{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {sms?.length}
                  </span>{" "}
                  to{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {sms?.length}
                  </span>{" "}
                  of{" "}
                  <span class="font-semibold text-gray-900 dark:text-white">
                    {sms?.count}
                  </span>{" "}
                  Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button
                    onClick={() => handlePrev()}
                    disabled={sms?.previous === null}
                    class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      class="mr-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Prev
                  </button>
                  <button
                    onClick={() => handleNext()}
                    disabled={sms?.next === null}
                    class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                    <svg
                      aria-hidden="true"
                      class="ml-2 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <EmptySms />
      )}
    </div>
  );
};

const EmptySms = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={nosms} alt="nosms" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No Data Found</h1>
      </div>
    </div>
  );
};

export default AllSentSMS;
