import React, { Component, useState, useEffect } from "react";
import {} from "chart.js/auto";

import { Bar } from "react-chartjs-2";

const BarChart = ({ data }) => {
  const config = {
    chartData: {
      labels: data?.labels,
      datasets: [
        {
          label: "",
          data: data?.datasets[0]?.data,
          backgroundColor: [data?.datasets[0].backgroundColor],
        },  
      ],
    },
  };

  return (
    <div className="chart">
      <Bar
        data={config.chartData}
        options={{
          title: {
            display: false,
            text: "Chart.js Bar Chart",
          },
          legend: {
            display: true,
            position: "right",
          },
          scales: {
            xAxes: [
              {
                barThickness: 2,
              },
            ],
          },
        }}
        height={100}
      />
    </div>
  );
};

export default BarChart;
