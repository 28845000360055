import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Spinner from "../../components/Spinner";
import api, { BASE_URL } from "../../utils/api";
import { IoMdAddCircleOutline, IoMdEye } from "react-icons/io";
import { BiFilter } from "react-icons/bi";
import SearchFIlter from "../../components/SearchFIlter";
import noharvest from "../../assets/noharvest.png";
import { GrPowerReset } from "react-icons/gr";
import UpdateModalMiller from '../../components/UpdateModalMiller'
import { Redirect, useLocation } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const [farms, setFarms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [search, setSearch] = useState("");
  const [district, setDistrict] = useState();
  const [community, setCommunity] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [selectedFarmer, setSelectedFarmer] = useState(null);
  const [selecteduser, setSelectedUser] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isFiltered, setIsfiltered] = useState(false);
  const [q, setQ] = useState("")


  const searchFarm = () => {
    let masterData = farms;
    let query = q.toLowerCase();
    let filteredData = masterData.filter((item) => {
      return Object.values(item.farms[0])
        .join(" ")
        .toLowerCase()
        .includes(query);
    });
    setIsfiltered(true);
    console.log(filteredData);
    setFilteredResults(filteredData);
  };


  const initialState = {
    region: "",
    district: "",
    community: "",
  };
  const FarmData = {
    title: "Update farm",
    dataset: [
      {
        id: 1,
        label: "name",
        inputType: "text",
      },
      {
        id: 2,
        label: "acres",
        inputType: "text",
      },
      {
        id: 3,
        label: "region",
        inputType: "Custom1",
      },
      {
        id: 4,
        label: "distrcit",
        inputType: "Custom2",
      },
      {
        id: 5,
        label: "community",
        inputType: "Custom3",
      },
      {
        id: 6,
        label: "longitude",
        inputType: "text",
      },
      {
        id: 7,
        label: "latitude",
        inputType: "text",
      },
    ],
  };

  const payload = {
    id: selectedFarm,
    item: "farm",
    farmid: selectedFarmer,
  };

  const reset = () => {
    setIsfiltered(false);
    setQ("");
    setFilteredResults([]);
    getAllFarms();
  };

  const [states, setState] = useState(initialState);

  const regions = [
    "Western North Region",
    "Savannah Region",
    "Oti Region",
    "Western Region",
    "Upper West Region",
    "Upper East Region",
    "Northern Region",
    "Greater Accra Region",
    "Eastern Region",
    "Central Region",
    "Volta Region",
    "North East Region",
    "Ahafo Region",
    "Brong-Ahafo Region",
    "Ashanti Region",
    "Bono East Region",
  ];

  const getDistrict = async (name) => {
    try {
      let p = {
        region: name,
      };
      const data = await api.post(`auth/get-districts/`, JSON.stringify(p));
      console.log(data);

      setDistrict(data.data.districts);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCommunity = async (name) => {
    try {
      let p = {
        district: name,
      };
      const data = await api.post(
        `auth/get-district-community/`,
        JSON.stringify(p)
      );
      console.log(data);

      setCommunity(data.data.communities);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllFarms = () => {
    let token = localStorage.getItem("token");
    let millerID = JSON.parse(localStorage.getItem("user")).id;
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-farmer-farms/?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setFarms(response.data);
        setCount(response.data.count);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshFilter = () => {
    setState(initialState);
    getAllFarms();
  };


  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const handleNext = () => {
    if (farms.next) {
      setCurrentPage(currentPage + 1);
      getAllFarms();
    }
  };

  const handlePrev = () => {
    if (farms.previous) {
      setCurrentPage(currentPage - 1);
      getAllFarms();
    }
  };

  useEffect(() => {
    getAllFarms();
  }, []);

  const handleRowClick = (row) => {
    history.push("/admin/farmDetail", { state: row });
  };

  const handleDelete = (id) => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "delete",
      url: `${BASE_URL}farmer/delete-farm/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        getAllFarms(response.data);

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("user"))
  if (user.isfirstTime) {
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <>
      <UpdateModalMiller
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
        data={FarmData}
        modalData={selecteduser}
        payload={payload}
        setLoading={getAllFarms}
      />

      <div
        id="popup-modal"
        tabindex="-1"
        aria-hidden="true"
        className={`
        ${showConfirmModal ? "block" : "hidden"}
        bg-black bg-opacity-80 h-screen grid items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 transition duration-300 ease-in-out`}
      >
        <div class="relative p-4 w-96 max-w-md h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow">
            <button
              onClick={() => setShowConfirmModal(false)}
              type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="popup-modal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-6">
              <h3 class="mb-5 text-lg font-normal text-gray-700">Confirm</h3>
              <div class="my-4">
                <h4 class="mb-5 text-base text-center font-normal text-gray-700">
                  Are you sure you want to delete this farm?
                </h4>

                <div className="flex flex-row items-center gap-4">
                  <button
                    onClick={() => {
                      handleDelete(selectedFarm);
                      setShowConfirmModal(false);
                    }}
                    className="capitalize bg-[#234092] text-white w-full px-4 py-2 rounded-md"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => setShowConfirmModal(false)}
                    className="capitalize bg-[#e41d21] text-white w-full px-4 py-2 rounded-md"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="h-96 grid items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="w-full lg:px-6 px-2 h-full py-10">
          <div className="w-full">
            <div className="flex items-center mb-1">
              <p className="font-bold text-base">FIlter: </p>
              <BiFilter size={30} />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2 mt-2">
              <div className="lg:mb-0 mb-2">
                <SearchFarmFilter
                 setQuery={setQ}
                 searchFarm={searchFarm}
                 value={q}
                 reset={reset}
                />
              </div>
              <FarmFilter
                regions={regions}
                district={district}
                handleChange={handleChange}
                community={community}
                getCommunity={getCommunity}
                getDistrict={getDistrict}
                filterFarm
                setRun={refreshFilter}
              />
            </div>
            <div className="my-5">
              {farms && farms?.length > 0 ? (
                <>
                  <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table className="w-full text-xs  text-left bg-white">
                      <thead className=" text-gray-700  capitalize bg-white">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            <input
                              type={"checkbox"}
                              className="border-2 mr-5 border-gray-700"
                            />
                            farmer
                          </th>
                          <th scope="col" className="py-3 px-6">
                            farm name
                          </th>
                          <th scope="col" className="py-3 px-6">
                            crop
                          </th>
                          <th scope="col" className="py-3 px-6">
                            acres
                          </th>
                          <th scope="col" className="py-3 px-6">
                            region
                          </th>

                          <th scope="col" className="py-3 px-6">
                            community
                          </th>
                          <th
                            scope="col"
                            className="py-3 px-6 grid items-center justify-center"
                          >
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {farms?.map((data, index) =>
                          data.farms.map((farm, index) => (
                            <tr
                              key={data.id}
                              className={`${index % 2 === 0
                                ? "bg-white border-b dark:border-gray-200"
                                : "bg-slate-100 border-b"
                                } hover:bg-gray-200`}
                            >
                              <th
                                scope="row"
                                className="capitalize py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input
                                  type={"checkbox"}
                                  className="capitalize border-2 mr-5 border-gray-700"
                                />
                                {`${data?.farmer.firstname} ${data?.farmer.lastname}`}
                              </th>
                              <td className="capitalize py-4 px-6">
                                {farm?.name}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {farm?.crop.name}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {farm?.acres}
                              </td>
                              <td className="capitalize py-4 px-6">
                                {farm?.region}
                              </td>

                              <td className="capitalize py-4 px-6">
                                {farm?.community}
                              </td>
                              <td className="capitalize py-4 px-3">
                                <div className="flex items-center gap-4 justify-center">
                                  <button
                                    onClick={() =>
                                      handleRowClick({
                                        ...data.farmer,
                                        ...farm,
                                      })
                                    }
                                    className="outline-none"
                                  >
                                    <IoMdEye size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedFarm(data.farmer.id);
                                      setSelectedFarmer(farm.id);
                                      setSelectedUser(farm);
                                      setShowUpdateModal(true);
                                    }}
                                    className="outline-none"
                                  >
                                    <MdModeEdit size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedFarm(data.id);
                                      setShowConfirmModal(true);
                                    }}
                                    className="outline-none"
                                  >
                                    <AiFillDelete size={16} color="red" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-5">
                    <div class="flex flex-col items-center">
                      <div class="inline-flex mt-2 xs:mt-0">
                        <button
                          onClick={() => handlePrev()}
                          disabled={farms?.previous === null}
                          class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          <svg
                            aria-hidden="true"
                            class="mr-2 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          Prev
                        </button>
                        <button
                          onClick={() => handleNext()}
                          disabled={farms?.next === null}
                          class="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                          Next
                          <svg
                            aria-hidden="true"
                            class="ml-2 w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <EmptyFarm />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SearchFarmFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchFarm,
  reset,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by name, crop, acres..."
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white p-1 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
           onClick={() => searchFarm()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            reset();
          }}
          size={20}
        />
      </div>
    </div>
  );
};

const FarmFilter = ({
  regions,
  district,
  community,
  getCommunity,
  getDistrict,
  handleChange,
  filterFarm,
  setRun,
}) => {
  return (
    <div className="bg-slate-200 p-4 rounded-md shadow-sm flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex items-center gap-3 flex-col lg:flex-row w-full ">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="region"
            onChange={handleChange}
            onInput={(e) => getDistrict(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {regions?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full  ">
          <select
            name="district"
            onChange={handleChange}
            onInput={(e) => getCommunity(e.target.value)}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {district?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <select
            name="community"
            onChange={handleChange}
            className="w-full rounded-md border-2 text-slate-400 border-slate-400 p-1 outline-none"
          >
            {community?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full   ">
          <button
            onClick={() => filterFarm()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full p-1 rounded-md "
          >
            filter
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => setRun("run")}
          size={20}
        />
      </div>
    </div>
  );
};

const EmptyFarm = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noharvest} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No farm record found</h1>
      </div>
    </div>
  );
};

export default Index;
