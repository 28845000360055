import React, { useEffect, useState } from "react";
import farmer from "../../assets/farmern.png";
import nodata from "../../assets/no-data.svg";
import noNews from "../../assets/noNews.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import { Redirect,useLocation} from "react-router-dom";

function Index() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState();
  

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const getAllNews = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}miller/get-all-news/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    console.log("token", token);

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setNews(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllNews();
  }, []);

  const handleRowClick = (row) => {
    history.push("/admin/newsDetail", { state: row });
  };

   const { state } = useLocation();

  const user=JSON.parse(localStorage.getItem("user"))
  if(user.isfirstTime){
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }



  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : news?.results.length > 0 ? (
        <div className="mb-4 lg:mb-0">
          <div className="w-full flex flex-col gap-6 lg:flex-row">
            <div className="lg:flex-auto lg:w-64 h-72  mb-5">
              {news?.results.slice(0, 1).map((data, index) => {
                return (
                  <div
                    key={data.id}
                    style={{
                      backgroundImage: `url(${
                        data.image
                      })`,
                    }}
                    className={`object-cover relative h-full bg-opacity-0 w-full bg-black`}
                  >
                    <div className="absolute bottom-4 left-5">
                      <div
                        onClick={() => handleRowClick(data)}
                        className="font-extrabold text-xl leading-tight text-white opacity-100 "
                      >
                        {data?.title}
                      </div>
                      <p className="mt-3 capitalize text-xs text-gray-400 font-bold">
                        {moment(data?.date_created).format("LLL")}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="lg:flex-auto lg:w-32">
              <p className="text-lg font-bold capitalize pb-1 mb-2 border-b border-red-500">
                Related news
              </p>
              {news?.results.slice(1, 2).map((data, index) => {
                return (
                  <div
                    key={data.id}
                    className="border-b border-red-500 pt-2 pb-2 mb-2"
                  >
                    <div href="" onClick={() => handleRowClick(data)}>
                      <p className="my-1 text-base font-bold text-black mb-2">
                        {data?.title}
                      </p>
                      <p className="capitalize text-xs text-gray-500 font-bold">
                        {moment(data?.date_created).format("LLL")}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-10">
            <p className="text-lg font-bold w-64 capitalize pb-1 mb-5 border-b border-red-500">
              latest news
            </p>
            <div className="my-3">
              {news?.results.slice(0, 3).map((data, index) => {
                return (
                  <div
                    key={data.id}
                    className="lg:grid lg:grid-cols-3 gap-4 mb-6 pb-3 border-b border-red-500"
                    onClick={() => handleRowClick(data)}
                  >
                    <div className="w-full h-40">
                      <img
                        src={
                          data.image
                        }
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="lg:col-span-2 mb-2 lg:mb-0">
                      <div href="">
                        <p className="my-1 text-base font-bold text-black mb-2">
                          {data?.title}
                        </p>
                        <p className="capitalize text-sm text-gray-500 font-bold">
                          {moment(data?.date_created).format("LLL")}
                        </p>
                        <p className="text-xs text-gray-500 py-3 ">
                          {truncate(data?.content, 150)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {news?.next !==null && <div className="my-10 w-full flex items-center justify-center">
              <button className="bg-yellowcolor w-64 text-black capitalize font-bold py-2 px-4 rounded-md">
                <p>Load more</p>
              </button>
            </div>}
          </div>
        </div>
      ) : (
        <EmpytNews />
      )}
    </div>
  );
}


const EmpytNews = () => {
  return (
    <div className="text-center h-screen grid content-center justify-center">
      <div className="h-96">
        <img src={noNews} alt="" className="w-36" />
        <h1 className="my-10 font-bold text-lg">No news found</h1>
      </div>
    </div>
  );
};

export default Index;
