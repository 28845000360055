import React from "react";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import useAuth from "../../context/userContext";

const ResetPassword = () => {
  const { id } = useParams();
  const { resetPassword, resetpasswordloading } = useAuth();
  return (
    <div className="w-screen  h-screen grid content-center justify-center background">
      <div className="p-5 rounded-md">
        <div className="flex content-center justify-center mb-4 ">
          <img src={logo} alt="" className="" />
        </div>
        <h1 className="text-center font-semibold text-3xl mb-3 capitalize from-neutral-900">
          reset your password
        </h1>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            let payload = {
              code: id,
              password: values.password,
            };
            resetPassword(payload);
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, "Password must be at least 6 characters")
              .required("Password is required"),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required"),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div className="w-full lg:w-96 md:w-2/4 mx-auto">
              <div className="mb-3">
                <label className="font-semibold text-base capitalize">
                  New Password
                </label>
                <input
                  type={"password"}
                  className="mt-3 w-full rounded-md border-2 border-slate-400 p-1 outline-none"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="********"
                />
                {touched.password && errors.password && (
                  <div className="text-red-500 text-sm">{errors.password}</div>
                )}
              </div>
              <div className="">
                <label className="font-semibold text-base capitalize">
                  Confirm Password
                </label>
                <input
                  type={"password"}
                  className="mt-3 w-full rounded-md border-2 border-slate-400 p-1 outline-none"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="********"
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="text-red-500 text-sm">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
              <div className="my-6">
                <button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                  className="w-full bg-yellowcolor capitalize hover:bg-primary  text-white font-medium py-2 px-10 rounded-md"
                >
                  <p>
                    {resetpasswordloading ? "Submitting..." : "Reset password"}
                  </p>
                </button>
                <div className="my-1 lg:my-0 md:my-0 lg:mx-2 md:mx-2" />
              </div>
              <NavLink
                to="/"
                className=" capitalize text-primary underline  font-medium text-center"
              >
                <p>return to login</p>
              </NavLink>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
