import React from "react";
import { useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import farmer from "../../assets/farmern.png";
import tree from "../../assets/tree.png";
import fruits from "../../assets/fruits.png";
import moment from "moment";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const NewsDetail = (props) => {
  const history = useHistory();
  const request = props.location.state;

  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState();

  const truncate = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };

  const getAllNews = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: "http://localhost:8000/api/v1/miller/get-all-news/",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    console.log("token", token);

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setNews(response.data.news);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllNews();
  }, []);

  const handleRowClick = (row) => {
    history.push("/admin/newsDetail", { state: row });
  };

  return (
    <div className="w-full lg:px-20 px-5 h-full py-10">
      <button onClick={() => history.goBack()} className="mb-5">
        <IoMdArrowRoundBack size={25} />
      </button>
      <div className="my-1">
        <img
          alt=""
          src={request.state.image}
          className="w-full h-96 object-cover mb-2"
        />
        <p className="my-1 text-base font-bold text-black mb-2">
          {request.state.title}
        </p>
        <p className="capitalize text-sm text-gray-500 font-bold">
          {moment(request.state.date_created).format("MMM Do YY")}
        </p>
        <p className="text-sm font-semibold text-gray-800 py-3 ">
          {request.state.content}
        </p>
      </div>
      <div className="mt-5">
        <p className="text-lg font-bold w-64 capitalize pb-1 mb-5 border-b border-red-500">
          Related news
        </p>
        <div className="my-3">
          {news?.length > 0 ? (
            news?.slice(0, 3).map((data, index) => {
              return (
                <div
                  key={data.id}
                  className="lg:grid lg:grid-cols-3 gap-4 mb-6 pb-3 border-b border-red-500"
                  onClick={() => handleRowClick(data)}
                >
                  <div className="w-full h-40">
                    <img
                      src={data.image}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="lg:col-span-2 mb-2 lg:mb-0">
                    <a href="">
                      <p className="my-1 text-base font-bold text-black mb-2">
                        {data.title}
                      </p>
                      <p className="capitalize text-sm text-gray-500 font-bold">
                        {moment(data.date_created).format("MMM Do YY")}
                      </p>
                      <p className="text-xs text-gray-500 py-3 ">
                        {truncate(data.content, 150)}
                      </p>
                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <h1 className="text-center text-xl font-bold">
              No News data found
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
