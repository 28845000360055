import React, { useState } from "react";
import logo from "./auth/logo.png";
import svgImage from "../assets/Lock.png";
import { NavLink } from "react-router-dom";

const PhoneNumberSuccessPage = () => {
  return (
    <div className="container">
      <div className="w-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen">
        <div className="bg-[url('/src/background.png')] w-full bg-amber-400 hidden md:block lg:block">
          <div className="bg-[url('/src/Rectangle.png')] h-screen bg-opacity-0 w-full bg-amber-400">
            <h1 className="px-20 pb-6 font-extrabold text-5xl leading-tight text-white opacity-100 absolute inset-x-0 bottom-0">
              Welcome <br />
              to Solidaridad <br />
              Harvest Alert
            </h1>
          </div>
        </div>
        <div className="w-full bg-white grid content-center">
          <div className="text-center grid content-center ">
            <div className="flex content-center justify-center">
              <img src={logo} alt="" className="" />
            </div>
          </div>
          <div className="w-9/12 my-10  grid content-center items-center justify-center mx-auto ">
            <div className="w-full text-center items-center justify-center">
              <div className="text-center grid content-center mb-5 ">
                <div className="flex content-center justify-center">
                  <img src={svgImage} alt="" className="" />
                </div>
              </div>

              <h1 className="font-bold text-2xl capitalize text-black">
                Success!
              </h1>
              <p className="text-sm text-gray-400 mt-2">
                Your phone number has been confirmed. Please login to continue.
              </p>
              <div className="w-full mt-6">
                <NavLink
                  to="/"
                  className=" capitalize text-primary font-medium py-2 px-10"
                >
                  <p>login</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberSuccessPage;
