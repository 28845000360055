import React, { useEffect, useState } from "react";
import BarChart from "./Chart";
import { CustomModal } from "../../components";
import useAuth from "../../context/userContext";
import { OTP } from "../../components";
import axios from "axios";
import { BASE_URL } from "../../utils/api";
import Spinner from "../../components/Spinner";
import moment from "moment";


const Dashboard = () => {
  const { createSms, VerifySms } = useAuth();
  const [closeModal, setcloseModal] = useState(true);
  const [analyticData, setAnalyticData] = useState();
  const [loading, setLoading] = useState(true);


  const user=JSON.parse(localStorage.getItem("user"))
  


  const getDashboardAnalytics = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `${BASE_URL}miller/getDashboardAnalytics/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: "",
    };

    axios(config)
      .then(function (response) {
        setAnalyticData(response.data);
        console.log(response.data)
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDashboardAnalytics();
    console.log("analyticData", analyticData);
  }, []);

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      {user?.isfirstTime ? (
        <CustomModal
          title={"Please enter the One-Time OTP to verify your phone number"}
          body={
            <OTP
              phone_number={user?.phone}
              createSms={createSms}
              VerifySms={VerifySms}
            />
          }
          onClose={true}
        />
      ) : null}
      <div className="grid grid-cols-1 md:grid-cols-2 justify-between lg:grid-cols-4 pt-2 gap-2">
        <Cards title={"total orders"} count={analyticData?.totalOrders} />
        <Cards title={"fulfilled"} count={analyticData?.totalFullfilled} />
        {/* <Cards title={"Available"} count={analyticData?.totalAvailable} /> */}
        <Cards title={"pending"} count={analyticData?.totalPending} />
        <Cards title={"rejected"} count={analyticData?.totalRejected} />
      </div>
      {/* chats */}
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="bg-white py-3 my-10 rounded-md border border-gray-300">
            <p className="font-bold py-1 px-10 text-sm capitalize mb-3">
              orders
            </p>
            <div className="h-0.5 w-full bg-gray-300" />
            <div className="py-1 px-10">
              {/* Bar chat here */}
              <BarChart data={analyticData && analyticData.chartData} />
            </div>
          </div>
          {/* orders */}
          <div className="">
            <div className="my-10">
              <div class="overflow-x-auto relative bg-white shadow-md sm:rounded-lg">
                <p className="font-bold py-5 px-10 text-sm capitalize mb-3">
                  recent order
                </p>
                <div className="h-0.5 w-full bg-gray-300" />
                <table class="w-full text-sm text-left bg-white">
                  <thead class="text-xs text-gray-700 uppercase bg-white">
                    <tr>
                      <th scope="col" class="py-3 px-6">
                        order id
                      </th>
                      <th scope="col" class="py-3 px-6">
                        aggregator
                      </th>
                      <th scope="col" class="py-3 px-6">
                        phone number
                      </th>
                      <th scope="col" class="py-3 px-6">
                        price(GHS)
                      </th>
                      <th scope="col" class="py-3 px-6">
                        quantity
                      </th>
                      <th scope="col" class="py-3 px-6">
                        order date
                      </th>
                      <th scope="col" class="py-3 px-6">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {analyticData?.recentOrders.map((m) => (
                      <tr
                        key={m.id}
                        class="bg-white border-b dark:border-gray-700"
                      >
                        <th
                          scope="row"
                          class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {m.order_id}
                        </th>
                        <td class="py-4 px-6">
                          {m.order_user.firstname + " " + m.order_user.lastname}
                        </td>
                        <td class="py-4 px-6">{m.order_user.phone}</td>
                        <td class="py-4 px-6">{m.order_price}</td>
                        <td class="py-4 px-6">{m.order_quantity}</td>
                        <td class="py-4 px-6">{
                          moment(m.order_date).format("MMM Do YY")
                        }</td>
                        <td class="py-4 px-6">
                          <span className="bg-green-400 p-1 rounded-md font-semibold text-sm">
                            {m.order_status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Cards = ({ title, count }) => {
  return (
    <div className="bg-white my-2 lg:mb-0 p-4 w-full lg:w-48 border border-gray-300 rounded-md justify-center text-center shadow-md">
      <p className="font-bold text-sm capitalize mb-3">{title}</p>
      <p className="font-bold text-base capitalize ">{count}</p>
    </div>
  );
};

export default Dashboard;
