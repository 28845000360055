import React, { useState, useEffect } from "react";
import { FormInput } from "../../components";
import FormOptions from "../../components/FormOptions";
import { BiTimeFive } from "react-icons/bi";
import { Form } from "react-bootstrap";
import axios from "axios";
import { SuccessNotification, ErrorNotification } from "../../utils";
import {
  createID,
  formatMydate,
  formatdateForSchedule,
} from "../../utils/Formatter";
import { BASE_URL } from "../../utils/api";
import { useLocation,Redirect } from "react-router-dom";

export default function Index() {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [messageType, setMessageType] = useState("daily");
  const [groups, setGroups] = useState();
  const [Loading, setLoading] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [mydate, setdate] = useState();
  const [selectedWeek, setSelectedWeek] = useState();

  const msgTypes = [
    { id: "daily", type: "daily" },
    { id: "once", type: "never" },
    { id: "day_of_week", type: "day of week" },
    { id: "monthly", type: "monthly" },
  ];

  const dayofWeek = [
    { id: 1, type: "Monday" },
    { id: 2, type: "Tuesday" },
    { id: 3, type: "Wednesday" },
    { id: 4, type: "Thursday" },
    { id: 5, type: "Friday" },
    { id: 6, type: "Saturday" },
    { id: 7, type: "Sunday" },
  ];

  useEffect(() => {
    getAllgroups();
    let c = formatMydate();
    console.log(c);
    setdate(c);
  }, []);

  const addSms = (id) => {
    var data = JSON.stringify({
      sms_id: id,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}sms/aggregator-create-sms/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        SuccessNotification("You have successfully created the sms");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addSms2 = (id) => {
    var data = JSON.stringify({
      sms_id: id,
    });

    var config = {
      method: "post",
      url: `${BASE_URL}sms/aggregator-schedule-sms/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        SuccessNotification("You have successfully scheduled an sms");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAllgroups = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",

      url: `${BASE_URL}aggregator/get-all-group/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setGroups(response.data.groups);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const [states, setState] = useState({
    sms_id: createID(4),
    message: "",
    scheduled: showDropdown,
    sent: !showDropdown,
    scheduled_date: null,
    scheduled_time: null,
    sent_date: null,
    recipients: "",
    message_type: "",
    campaign_type: "Sms",
  });

  function handleChange(evt) {
    const value = evt.target.value;

    setState({
      ...states,
      [evt.target.name]: value,
    });
  }

  const createSms = () => {
    let token = localStorage.getItem("token");
    let c = formatMydate();

    var payload = {
      sms_id: createID(4),
      message: states.message,
      scheduled: showDropdown,
      sent: !showDropdown,
      scheduled_date: scheduledDate,
      scheduled_time: null,
      sent_date: c,
      recipient_group: states.recipients,
      campaign_type: states.campaign_type,
    };

   
    setLoading(true);
    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/create-sms/`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
       
        addSms(response.data.sms_id);

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const createSms2 = () => {
    let token = localStorage.getItem("token");
    let c = "";
    if (states.scheduled_date !== null && states.message_type === "once") {
      c = formatdateForSchedule(states.scheduled_date);
    } else if (
      states.scheduled_date !== null &&
      states.smessage_type === "monthly"
    ) {
      c = formatdateForSchedule(states.scheduled_date);
    } else {
      c = states.scheduled_date;
    }

    var payload = {
      sms_id: createID(4),
      message_type: messageType,
      message: states.message,
      scheduled_date: states.scheduled_date,
      scheduled_time: states.scheduled_time,
      recipient_group: states.recipients,
      campaign_type: states.campaign_type,
      start_time: states.scheduled_date + " " + states.scheduled_time,
    };

    console.log(payload)

    setLoading(true);
    var config = {
      method: "post",
      url: `${BASE_URL}aggregator/schedule-sms/`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        addSms2(response.data.sms_id);
        console.log(response.data.sms_id);

        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const campaign_types = ["sms", "voice sms"];
  const { state } = useLocation();

  const user=JSON.parse(localStorage.getItem("user"))
  if(user.isfirstTime){
    return <Redirect to={state?.from || "/admin/dashboard"} />;
  }

  return (
    <div className="w-full lg:px-6 px-2 h-full py-6">
      <div className="w-full bg-white shadow-md lg:px-7 px-3 py-5 rounded-md">
        <h1 className="capitalize text-sm font-bold">compose Campaign</h1>
        <>
          <div className="mt-5 lg:gap-9 md:gap-4 flex flex-col-reverse  lg:flex-row md:flex-row">
            <div className="lg:flex-auto lg:w-64 md:flex-auto md:w-64 w-full">
              <div className="flex flex-col gap-2 mt-4">
                <p className=" lg:basis-1/4 capitalize text-base ">
                  Message Type:
                </p>
                <div className="lg:basis-1/2">
                  <div className="w-full">
                    <Form.Control
                      required
                      name="campaign_type"
                      as="select"
                      type="select"
                      onChange={handleChange}
                      style={{
                        backgroundColor: "white",

                        border: "1px solid #94a3b8",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      className="capitalize"
                    >
                      {campaign_types.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <p className=" lg:basis-1/4 capitalize text-base ">send to:</p>
                <div className="lg:basis-1/2">
                  <div className="w-full">
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      name="recipients"
                      onChange={handleChange}
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #94a3b8",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                    >
                      <option value="">
                        Choose a group to send message to
                      </option>
                      {groups?.map((m) => (
                        <option value={m.id}>{m.groupname}</option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <p className=" lg:basis-1/4 capitalize text-base ">message:</p>
                <div className="lg:basis-1/2">
                  <div className="w-full">
                    <textarea
                      name="message"
                      onChange={handleChange}
                      rows={6}
                      className="w-full rounded-md border-2 border-slate-400 p-1 outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col gap-2">
              <div className=" lg:basis-1/4 capitalize text-base" />
              <div className="flex  w-1/2 justify-between">
                <div className="mt-5 justify-end flex">
                  <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="text-black bg-gray-50 font-bold text-sm py-1 flex px-2  items-center capitalize"
                  >
                    <BiTimeFive size={18} color="#FBBC05" />
                    <p className="text-sm ml-2 text-yellowcolor">
                      {showDropdown ? "Cancel scheduling" : "schedule Campaign"}
                    </p>
                  </button>
                </div>

                <div className="mt-5 justify-end flex">
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      {
                        showDropdown ? createSms2() : createSms();
                      }
                    }}
                    className="rounded-sm shadow-sm text-black font-bold text-sm bg-yellowcolor  py-1 flex px-4  items-center capitalize"
                  >
                    {Loading
                      ? "Creating"
                      : showDropdown
                      ? "Schedule sms"
                      : "Send Message"}
                  </button>
                </div>
              </div>
            </div>
            </div>
            {showDropdown && ( 
               <div className="lg:flex-auto lg:w-32 md:flex-auto md:w-32 w-full ">
               <div className="flex flex-col gap-2 mt-4">
                 <p className=" lg:basis-1/4 capitalize text-base ">Schedule</p>
                 <div className="flex lg:flex-row flex-col gap-2">
                   {messageType === "once" && (
                     <div className="w-full">
                       <input
                         type="date"
                         name="scheduled_date"
                         onChange={handleChange}
                         className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                       />
                     </div>
                   )}
                   {messageType === "day_of_week" && (
                     <div className="w-full">
                       <Form.Control
                         required
                         as="select"
                         type="select"
                         name="scheduled_date"
                         value={selectedWeek}
                         onChange={handleChange}
                         style={{
                           backgroundColor: "white",
                           border: "1px solid #94a3b8",
                           borderRadius: "5px",
                           width: "100%",
                         }}
                       >
                         {dayofWeek?.map((m) => (
                           <option value={m.type}>{m.type}</option>
                         ))}
                       </Form.Control>
                     </div>
                   )}
                   {messageType === "monthly" && (
                     <div className="w-full">
                       <Form.Control
                         required
                       
                         type="date"
                         name="scheduled_date"
                         value={selectedWeek}
                         onChange={handleChange}
                         style={{
                           backgroundColor: "white",
                           border: "1px solid #94a3b8",
                           borderRadius: "5px",
                           width: "100%",
                         }}
                       >
                        
                       </Form.Control>
                     </div>
                   )}
                   <div className="w-full">
                     <Form.Control
                       required
                       name="message_type"
                       as="select"
                       type="select"
                       onChange={handleChange}
                       onInput={(e) => setMessageType(e.target.value)}
                       style={{
                         backgroundColor: "white",
                         border: "1px solid #94a3b8",
                         borderRadius: "5px",
                         width: "100%",
                       }}
                       className="capitalize"
                     >
                       {msgTypes?.map((m) => (
                         <option value={m.id}>{m.type}</option>
                       ))}
                     </Form.Control>
                   </div>
                 </div>
               </div>
               {messageType === "daily" || messageType === "once" ? (
                 <div className="flex flex-col gap-2 mt-4">
                   <div className="w-full">
                     <input
                       type="time"
                       name="scheduled_time"
                       onChange={handleChange}
                       className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                     />
                   </div>
                 </div>
               ) : messageType === "day_of_week" ? (
                 <div className="flex flex-col gap-2 mt-4">
                   <div className="w-full">
                     <input
                       type="time"
                       name="scheduled_time"
                       onChange={handleChange}
                       className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                     />
                   </div>
                 </div>
               ) : messageType === "monthly" ? (
                 <div className="flex flex-col gap-2 mt-4">
                   <div className="w-full">
                     <input
                       type="time"
                       name="scheduled_time"
                       onChange={handleChange}
                       className="w-full rounded-md border-2 border-slate-400 px-2 py-2 outline-none"
                     />
                   </div>
                 </div>
               ) : null}
             </div>

            ) }
           
          </div>
        </>
      </div>
    </div>
  );
}
