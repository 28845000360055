
import React, { useState, useEffect } from "react";
import { GrPowerReset } from "react-icons/gr";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { createID, formatDateFull } from "../../utils/Formatter";
import { BASE_URL } from "../../utils/api";
import axios from "axios";
import { ErrorNotification, SuccessNotification } from "../../utils";
import Spinner from "../../components/Spinner";


const HarvestRequestDetail = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [farmersid, setFarmersId] = React.useState([]);
  const [detail, setDetail] = useState();
  const location = useLocation();
  const [q, setQ] = useState("");
  const [isFiltered, setIsfiltered] = useState(false);
  const history = useHistory();
  const request = props.location.state.state;

  const [state, setState] = useState({
    appointment_date: "",
    harvests: [request.id.toString()],
    order_id: createID(5),
    appointment_time: "",
  });

  const searchResponse = () => {
    let masterData = detail?.response
    let query = q.toLowerCase();
    const filteredData = masterData.filter(
      (el) =>

        el.farmer.firstname
          .toLowerCase().includes(query) ||
        el.farmer.lastname
          .toLowerCase().includes(query) ||
        el.status
          .toLowerCase().includes(query) ||
        el.quantity == query ||
        el.weight == query

    )
    setIsfiltered(true)
    console.log(filteredData)
    setFilteredResults(filteredData);
  };


  console.log(selectedItems)





  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function handleChange(evt) {
    const value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const getHarvestDetail = () => {
    let token = localStorage.getItem("token");
    setLoading(true);
    var config = {
      method: "get",
      url: `${BASE_URL}aggregator/get-harvestsrequest-details/${request.id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setDetail(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => getHarvestDetail(), [location.state]);
  const reset = () => {
    setIsfiltered(false)
    setQ("");
    setFilteredResults([]);
    getHarvestDetail();
  };


  const updateHarvestData = (action) => {


    var data = JSON.stringify({
      id: selectedItems,
      farmersid: farmersid,
      action: action,
      appointment_date: state.appointment_date + " " + state.appointment_time,
      appointment_time: state.appointment_time,
      harvests: state.harvests,
      order_id: state.order_id,
    });



    let token = localStorage.getItem("token");

    setLoading(true);
    var config = {
      method: "post",

      url: `${BASE_URL}aggregator/update-harvest/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setLoading(false);
        onCloseModal()
        SuccessNotification(
          "You have responded successfully to the harvest request"
        );
        setSelectedItems([])
        setFarmersId([])
        getHarvestDetail();
      })
      .catch(function (error) {
        console.log(error);
        ErrorNotification("An error occurred when updating response")
        setLoading(false);
      });
  };

  return (
    <>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="w-96">
          <h1 className="capitalize font-bold text-base">
            Book Evacuation Date
          </h1>
          <div className="my-7" />
          <div className="">
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Date</label>
              <input
                type="date"
                name="appointment_date"
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="flex items-center justify-between my-3">
              <label className="text-base flex-initial mr-2">Time</label>
              <input
                name="appointment_time"
                onChange={handleChange}
                type="time"
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          </div>
          <div className="my-7" />
          <div className="flex items-end justify-end">
            <button
              onClick={(e) => {
                e.preventDefault();
                updateHarvestData("supply");
              }}
              className="bg-yellowcolor text-sm px-4 py-2 rounded-md hover:bg-yellow-300"
            >
              {loading ? "updating..." : "Book Appointment"}
            </button>
          </div>
        </div>
      </Modal>

      <div
        id="popup-modal"
        tabindex="-1"
        aria-hidden="true"
        className={`
        ${showConfirmModal ? "block" : "hidden"}
        bg-black bg-opacity-80 h-screen grid items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 transition duration-300 ease-in-out`}
      >
        <div class="relative p-4 w-96 max-w-md h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow">
            <button
              onClick={() => setShowConfirmModal(false)}
              type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="popup-modal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-6">
              <h3 class="mb-5 text-lg font-normal text-gray-700">Confirm</h3>
              <div class="my-4">
                <h4 class="mb-5 text-base text-center font-normal text-gray-700">
                  Are you sure you want to reject these?
                </h4>

                <div className="flex flex-row items-center gap-4">
                  <button
                    onClick={() => {
                      updateHarvestData("reject");
                      setShowConfirmModal(false);
                    }}
                    className="capitalize bg-[#234092] text-white w-full px-4 py-2 rounded-md"
                  >
                    {loading ? "updating..." : "Yes"}
                  </button>
                  <button
                    onClick={() => setShowConfirmModal(false)}
                    className="capitalize bg-[#e41d21] text-white w-full px-4 py-2 rounded-md"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:px-6 px-2 h-full py-6">
        <button onClick={() => history.goBack()} className="mb-5">
          <IoMdArrowRoundBack size={25} />
        </button>
        <div className="bg-white sm:rounded-sm shadow-sm py-5">
          <div className="flex flex-row items-center justify-between border-b border-gray-300 mb-2 px-4">
            <h1 className="capitalize font-bold text-base mb-2">
              harvest Request
            </h1>
            <div className="flex flex-col items-center space-y-1 justify-center ">
              {detail?.delivered ? <span className="bg-green-400 p-1 rounded-md font-semibold text-sm items-center">
                Delivered
              </span> : <span className="bg-blue-400 p-1 rounded-md font-semibold text-sm">
                Pending delivery
              </span>}
              <p className="text-xs text-slate-400">This indicate whether request has been sent out</p>

            </div>

          </div>
          <div className="px-4">
            <h1 className="capitalize font-bold text-base my-2">
              Request No.: {detail?.harvest_id}
            </h1>
            <div className="my-5 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 md:grid-cols-3 ">
              <div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Sent to:
                  </h3>
                  <p className="text-base text-gray-500 capitalize">
                    {detail?.group_name}
                  </p>
                </div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Date Sent:
                  </h3>
                  <p className="text-base text-gray-500">
                    {formatDateFull(detail?.datecreated)}
                  </p>
                </div>
              </div>

              <div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Weight Requested:
                  </h3>
                  <p className="text-base text-gray-500">
                    {detail?.weight} tonnes
                  </p>
                </div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Price Offered:
                  </h3>
                  <p className="text-base text-gray-500">
                    Ghs {detail?.price} per Tonne
                  </p>
                </div>
              </div>

              <div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Total responses:
                  </h3>
                  <p className="text-base text-gray-500">
                    {detail?.total_responses} / {detail?.group_size}
                  </p>
                </div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Schedule Date:
                  </h3>
                  <p className="text-base text-gray-500">
                    {formatDateFull(detail?.harvest_date)}
                  </p>
                </div>
              </div>
              <div>
                <div className="mb-2">
                  <h3 className="capitalize font-semibold text-base">
                    Schedule type
                  </h3>
                  <p className="text-base text-gray-500">
                    {detail?.schedule_type === "daily" ? "daily" : detail?.schedule_type === "once" ? "once" : detail?.schedule_type === "day_of_week" ? "day of week" : detail?.schedule_type === "monthly" ? "monthly" : "daily"}

                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="capitalize font-bold text-base my-4">
          <h1 className="capitalize font-bold text-base">responses</h1>
        </div>

        <div className="bg-white sm:rounded-sm shadow-sm py-2">
          <div className="flex flex-col gap-3 md:flex-col lg:flex-row lg:items-center lg:justify-between border-b border-gray-300 px-4 py-3">
            <div>
              <SearchFilter setQuery={setQ}
                searchFarm={searchResponse}
                value={q}
                reset={reset} />
            </div>

            <div className=" flex flex-row items-center gap-2">
              <button
                onClick={onOpenModal}
                className="bg-yellowcolor px-2 py-2 rounded-md w-36 hover:bg-yellow-300"
              >
                Supply selected
              </button>
              <button
                onClick={() => setShowConfirmModal(true)}
                className="bg-red-600 text-white px-4 py-2 rounded-md w-36 hover:bg-red-700"
              >
                Reject selected
              </button>
            </div>
          </div>
          <div className="px-3 py-4">
            <div class="overflow-x-auto relative">
              <table class="w-5/6 text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 capitalize">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      <input
                        type={"checkbox"}
                        className="border-2 mr-5 border-gray-700"
                      />
                      name
                    </th>
                    <th scope="col" class="py-3 px-6">
                      available weight
                    </th>
                    <th scope="col" class="py-3 px-6">
                      quantity
                    </th>
                    <th scope="col" class="py-3 px-6">
                      date created
                    </th>
                    <th scope="col" class="py-3 px-6">
                      status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <div className="h-96 grid items-center justify-center">
                      <Spinner />
                    </div>
                  ) : (
                    filteredResults.length > 0
                      ? filteredResults &&
                      filteredResults?.map((data, index) => (
                        <>
                          <tr class="" key={index}>
                            <th
                              scope="col"
                              class="py-3 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                value={data}
                                disabled={
                                  data?.status === "Supply" ||
                                  data?.status === "Rejected"
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedItems((selectedItems) => [...selectedItems, data.id]);
                                    setFarmersId((farmersid) => [...farmersid, data.farmer.id]);
                                  } else {
                                    setSelectedItems((selectedItems) =>
                                      selectedItems.filter((id) => id !== data.id)
                                    );
                                    setFarmersId((farmersid) =>
                                      farmersid.filter((id) => id !== data.farmer.id)
                                    );
                                  }

                                }

                                }
                                className="border-2 mr-5 border-gray-700 "
                              />

                              {data?.farmer.firstname + " " + data?.farmer.lastname}
                            </th>
                            <td class="py-4 px-6">{data?.weight}</td>
                            <td class="py-4 px-6">{data?.quantity}</td>
                            <td class="py-4 px-6">
                              {formatDateFull(data?.date_created)}
                            </td>

                            <td class="py-4 px-6">
                              {data.status === "Supply" ? (
                                <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                                  {data.status}
                                </span>
                              ) : data.status === "Rejected" ? (
                                <span className="bg-red-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                                  {data.status}
                                </span>
                              ) : data.status === "Pending" ? (
                                <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                                  {data.status}
                                </span>
                              ) : null}
                            </td>
                          </tr>
                        </>

                      ))
                      : isFiltered === true && filteredResults.length < 1 ?
                        <tr>
                          <td
                            colSpan="9"
                            className="py-4 px-3  border-t dark:border-gray-700 text-lg text-center text-gray-700"
                          >
                            No data available
                          </td>
                        </tr> :

                        detail?.response.map((data, index) => (
                          <tr class="" key={index}>
                            <th
                              scope="col"
                              class="py-3 px-6 font-medium text-gray-900 whitespace-nowrap"
                            >
                              <input
                                type={"checkbox"}
                                value={data}
                                disabled={
                                  data?.status === "Supply" ||
                                  data?.status === "Rejected"
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedItems((selectedItems) => [...selectedItems, data.id]);
                                    setFarmersId((farmersid) => [...farmersid, data.farmer.id]);
                                  } else {
                                    setSelectedItems((selectedItems) =>
                                      selectedItems.filter((id) => id !== data.id)
                                    );
                                    setFarmersId((farmersid) =>
                                      farmersid.filter((id) => id !== data.farmer.id)
                                    );
                                  }

                                }
                                }
                                className="border-2 mr-5 border-gray-700 "
                              />

                              {data?.farmer.firstname + " " + data?.farmer.lastname}
                            </th>
                            <td class="py-4 px-6">{data?.weight}</td>
                            <td class="py-4 px-6">{data?.quantity}</td>
                            <td class="py-4 px-6">
                              {formatDateFull(data?.date_created)}
                            </td>

                            <td class="py-4 px-6">
                              {data.status === "Supply" ? (
                                <span className="bg-green-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                                  {data.status}
                                </span>
                              ) : data.status === "Rejected" ? (
                                <span className="bg-red-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                                  {data.status}
                                </span>
                              ) : data.status === "Pending" ? (
                                <span className="bg-blue-400 p-1 px-4 rounded-md font-semibold text-sm text-black">
                                  {data.status}
                                </span>
                              ) : null}
                            </td>
                          </tr>

                        )))}





                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="h-4" />
      </div>
    </>
  );
};

const SearchFilter = ({
  onChange,
  value,
  onClick,
  setQuery,
  searchFarm,
  reset,
  setRun,
}) => {
  return (
    <div className="flex space-x-2 items-center w-full">
      <div className="lg:flex md:flex lg:items-center gap-3 flex-col lg:flex-row w-full">
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-[300px] ">
          <input
            type={"text"}
            placeholder="Search by name, Weight..."
            value={value}
            onChange={(e) => setQuery(e.target.value)}
            className="text-slate-500 bg-white px-2 py-2 rounded-md w-full "
          />
        </div>
        <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-40   ">
          <button
            onClick={() => searchFarm()}
            className="text-base capitalize font-semibold bg-yellowcolor w-full px-4 py-2 rounded-md "
          >
            search
          </button>
        </div>
      </div>
      <div>
        <GrPowerReset
          className="cursor-pointer"
          onClick={() => {
            reset()
          }}
          size={20}
        />
      </div>
    </div>
  );
};

export default HarvestRequestDetail;
